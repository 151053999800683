import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const customerContext = createContext({
    customerID: 'test',
    setCustomerID: () => {}
});

export const customerUpdateContext = createContext();

export const CustomerProvider = ({ children }) => {
    const [customerID, setCustomerID] = React.useState('');
    function updateCustomerID(id) {
        setCustomerID(id);
    }

    return (
        <customerContext.Provider value={{ customerID, setCustomerID }}>
            <customerUpdateContext.Provider value={updateCustomerID}>
                {children}
            </customerUpdateContext.Provider>
        </customerContext.Provider>
    );
};

CustomerProvider.propTypes = {
    children: PropTypes.any
};
