/**
 * Finds addon in addons array by ID
 */
export default function getAddonFromID(id, addons) {
    if (!id || !addons) {
        return null;
    }
    const addonObj = addons.find(addon => addon.AddonID === id);
    return addonObj;
}
