import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import cartTotal from '../utils/cartTotal';
import CheckoutForm from '../components/checkout-form';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Checkout() {
    const [clientSecret, setClientSecret] = useState('');
    const [total, setTotal] = useState(0);

    useEffect(() => {
        // Get shopping cart items
        const cartItems = JSON.parse(localStorage.getItem('cart'));
        setTotal(cartTotal(cartItems));

        // If cart is empty redirect to empty cart page
        if (!cartItems || cartItems.length === 0) {
            window.location.href = '/store/cart';
        }

        const cart = cartItems.map(item => ({
            ProductID: item.ProductID,
            Quantity: item.QuantitySelected
        }));

        // Create PaymentIntent as soon as the page loads
        const createPaymentIntent = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json'
                },
                body: {
                    cart: cart
                }
            };
            API.post('MyAMZPrepStoreAPI', '/store/checkout', requestInfo)
                .then(response => {
                    localStorage.removeItem('cart');
                    setClientSecret(response.client_secret);
                })
                .catch(error => {
                    // if payment intent creation fails, empty cart and redirect to empty cart page
                    // this is to prevent the user from trying to pay for a product that doesn't exist
                    localStorage.removeItem('cart');
                    window.location.href = '/store/cart';
                    console.error(error);
                });
        };
        createPaymentIntent();
    }, []);

    const appearance = {
        theme: 'stripe'
    };
    const options = {
        clientSecret,
        appearance
    };
    const stripePromise = loadStripe(
        'pk_live_51LUcZfBcStMYyygYEmyxpfTW4WazBZbHYD1ljTV2Z0XsmA6EDIE4Ti9Z7Uo4iDeOiBaEBuUrraMzQgPOfgPy5Ko70091aUIwoj'
    );

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm cartTotal={total} />
                </Elements>
            )}
        </div>
    );
}

export default Checkout;
