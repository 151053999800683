import React, { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';

function TaxView({ user, setTaxView }) {
    const [s3URLs, setS3URLs] = useState([]);
    const [loading, setLoading] = useState(false);

    const getTextFromURL = url => {
        let splitURL = url.split('/')[6].split('?')[0];
        splitURL = splitURL.split('_')[0] + ' ' + splitURL.split('_')[1];
        return splitURL;
    };

    useEffect(() => {
        setLoading(true);
        const getS3Keys = async () => {
            const s3KeyList = await Storage.list(`forms/${user.Username}/`);
            return s3KeyList;
        };
        getS3Keys().then(list => {
            if (list.length > 0) {
                const tempURLs = [];
                list.forEach(async item => {
                    const tempObj = {};
                    await Storage.get(item.key)
                        .then(link => {
                            tempObj.key = item.key;
                            tempObj.url = link;
                            tempURLs.push(tempObj);
                        })
                        .then(() => {
                            console.log('tempURLs', tempURLs);
                            setS3URLs(tempURLs);
                            setLoading(false);
                        })
                        .catch(err => console.log('err', err));
                });
            } else {
                setLoading(false);
            }
        });
    }, []);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-72 my-6 mx-auto max-w-3xl">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-[0_20px_60px_20px_rgba(0,0,0,0.3)] relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/* header */}
                        <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <button
                                onClick={() => {
                                    setTaxView(false);
                                }}>
                                <IconX className="absolute top-2 right-2" />
                            </button>
                            <h3 className="text-xl font-semibold">
                                {
                                    user.Attributes.filter(
                                        item => item.Name === 'name'
                                    )[0].Value
                                }
                                {"'s Tax Docs"}
                            </h3>
                            <p className="text-gray-600 text-s"></p>
                        </div>
                        {/* body */}
                        {loading ? (
                            <div className="flex justify-center items-center p-5 border-b border-solid border-slate-200 rounded-t">
                                <div className="loading-spinner ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mb-4"></div>
                            </div>
                        ) : (
                            <div className="flex flex-col justify-between border-solid border-slate-200 text-center rounded-t">
                                {s3URLs.length > 0 ? (
                                    s3URLs.map(item => (
                                        <div
                                            key={item.index}
                                            className="p-3 border-t">
                                            <a
                                                className="hover:underline text-blue-700 text-lg"
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {getTextFromURL(item.url)}
                                            </a>
                                            <span></span>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-600 text-s">
                                        No tax documents found.
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-45 bg-black"></div>
        </>
    );
}
export default TaxView;

TaxView.propTypes = {
    user: PropTypes.object,
    setTaxView: PropTypes.func
};
