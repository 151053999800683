import {
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';

function PaymentUpdateForm({ setUpdatePaymentModal }) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            'pk_live_51LUcZfBcStMYyygYEmyxpfTW4WazBZbHYD1ljTV2Z0XsmA6EDIE4Ti9Z7Uo4iDeOiBaEBuUrraMzQgPOfgPy5Ko70091aUIwoj'
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            switch (setupIntent.status) {
                case 'succeeded':
                    setMessage('Setup succeeded!');
                    break;
                case 'processing':
                    setMessage('Your subscription is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage(
                        'Your payment method was not successful, please try again.'
                    );
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async e => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: 'https://app.myamzprep.com/account'
            }
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setIsLoading(false);
    };

    return (
        // Back button, total, and payment button, protected route with order id or check if previously on checkout page then redirect
        // If cart is empty show empty cart if type /payment, redirect to store
        <>
            <form
                id="payment-form"
                className="z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 container bg-white shadow-md lg:w-[30vw] lg:max-w-[500px] md:w-[80vw] md:min-w-[500px] p-[40px] align-middle rounded-md mx-auto"
                onSubmit={handleSubmit}>
                <button
                    type="button"
                    className="float-right"
                    onClick={() => setUpdatePaymentModal(false)}>
                    <IconX size={32} color="gray" />
                </button>
                <div className="text-2xl font-bold mb-6">
                    Add your payment method
                </div>
                <PaymentElement id="payment-element" />
                <button
                    disabled={isLoading || !stripe || !elements}
                    type="submit"
                    id="submit"
                    className="w-full bg-green-500 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-200 focus:ring-green-200">
                    <span id="button-text" className="">
                        {isLoading ? (
                            <div className="flex justify-center">
                                <div
                                    className="loading-spinner"
                                    id="spinner"></div>
                            </div>
                        ) : (
                            'Confirm payment method'
                        )}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default PaymentUpdateForm;

PaymentUpdateForm.propTypes = {
    setUpdatePaymentModal: PropTypes.func
};
