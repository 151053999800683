// Get a deterministic color hex value from an id value
export default function getColorFromID(id) {
    const colors = [
        '#F56565', // red
        '#ED8936', // orange
        '#ECC94B', // yellow
        '#48BB78', // green
        '#38B2AC', // teal
        '#4299E1', // blue
        '#667EEA', // indigo
        '#9F7AEA', // purple
        '#ED64A6' // pink
    ];

    // Get a deterministic index from the id
    const index =
        id.split('').reduce((acc, val) => acc + val.charCodeAt(0), 0) %
        colors.length;

    return colors[index];
}
