import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// create a loading skeleton of the payments page
const PaymentsSkeleton = () => (
    <>
        <div className="flex flex-wrap items-start justify-evenly">
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
            <>
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr className="border-2 rounded-lg mx-[10%]" />
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="p-2">
                        <Skeleton className="p-2 h-[96px]" />
                    </div>
                </div>
            </>
        </div>
    </>
);

export default PaymentsSkeleton;
