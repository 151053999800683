import { API, Auth } from 'aws-amplify';
import { React, useContext, useEffect, useState } from 'react';
import ChatsSkeleton from '../components/skeletons/chats-skeleton';
import { customerContext } from '../contexts/customer-context';
import formatDateChat from '../utils/formatDateChat';
import { NotificationContext } from '../contexts/notification-context';
import { useOutletContext } from 'react-router-dom';

function Chats() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [isChatOpen, setChatOpen] = useOutletContext();
    const openChat = () => setChatOpen(() => true);
    const customerIdContext = useContext(customerContext);

    const { setChatNotificationCount } = useContext(NotificationContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                }
            };
            API.get('myamzprepfixapi', '/chats', requestInfo)
                .then(response => {
                    console.log(response);

                    // sort response by date
                    const sortedResponse = response.Items.sort((a, b) =>
                        a.Author.localeCompare(b.Author)
                    ).reverse();

                    // Sets the last chat date to the current date for notifications
                    localStorage.setItem(
                        'lastChatDate',
                        sortedResponse[0].Author
                    );

                    setRows(sortedResponse);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error.response);
                    setLoading(false);
                });
        };

        setChatNotificationCount(0);
        fetchData();
    }, []);

    const ChatList = () =>
        rows.map(chat => (
            <div
                key={chat.author}
                onClick={() => {
                    customerIdContext.setCustomerID(chat.UserID);
                    if (!isChatOpen) {
                        openChat();
                    }
                }}
                className="container flex flex-col w-full items-center justify-center lg:mx-9">
                <ul className="flex flex-col w-full">
                    <li className="border-gray-400 flex lg:flex-row mb-2 w-full">
                        <div className="relative shadow border select-none cursor-pointer bg-white rounded-md flex flex-col lg:flex-row flex-1 lg:items-center p-4">
                            <div className="pl-1 min-w-[240px]">
                                <div className="font-medium ">
                                    {chat.StoreName}
                                </div>
                                <div className="text-gray-600  text-sm">
                                    {formatDateChat(chat.Author)}
                                </div>
                            </div>
                            <div className="text-gray-600 text-md flex-1">
                                &quot;{chat.Body}&quot;
                            </div>
                            <button className="w-auto lg:w-24 text-right lg:flex lg:justify-end mr-4 absolute right-0 top-[45%]">
                                <svg
                                    width="12"
                                    fill="currentColor"
                                    height="12"
                                    className="hover:text-gray-800  text-gray-500"
                                    viewBox="0 0 1792 1792"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                </svg>
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        ));

    return (
        <div className="w-full px-4 lg:px-0">
            <div className="lg:mt-10 mb-4 lg:mb-6 lg:ml-9">
                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                    Your Chats
                </h1>
                <p className="mt-1.5 text-sm text-gray-500">
                    View and manage your latest chats.
                </p>
            </div>
            <div>
                {loading ? (
                    <ChatsSkeleton />
                ) : (
                    <>
                        <ChatList />
                    </>
                )}
            </div>
        </div>
    );
}

export default Chats;
