// Convert a string to a numberic 8 digit ID
export default function getNumericID(str) {
    let hash = 0;

    if (str.length === 0) {
        return hash;
    }

    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }

    const num = Math.abs(hash).toString().slice(0, 8);

    const hypenated = num.slice(0, 4) + '-' + num.slice(4, 8);

    return hypenated;
}
