import { API, Auth } from 'aws-amplify';
import {
    IconFileText,
    IconMessageCircle,
    IconPlus,
    IconSearch,
    IconX
} from '@tabler/icons-react';
import { React, useContext, useEffect, useState } from 'react';
import { customerContext } from '../contexts/customer-context';
import getTierFromUnits from '../utils/getTierFromUnits';
import InviteModal from '../components/inviteModal';
import listUsers from '../utils/listUsers';
import OrderForm from '../components/order-form';
import RowSkeleton from '../components/skeletons/user-skeleton-row';
import TaxApproval from '../components/tax-approval';
import TaxView from '../components/tax-view';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

// const userTaxForms = ['examples3key1', 'examples3key2', 'examples3key3'];

const activeStyle =
    'inline-block p-2 rounded-t-lg w-full text-white bg-theme-color-400 hover:text-white hover:bg-theme-color-400 focus:ring-4 focus:ring-blue-300 focus:outline-none';
const inactiveStyle =
    'inline-block p-2 rounded-t-lg w-full text-white bg-theme-color-700 hover:text-white hover:bg-theme-color-400 focus:ring-4 focus:ring-blue-300 focus:outline-none';

function Users() {
    const [adminList, setAdminList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [createOrder, setCreateOrder] = useState(false);
    const customerIdContext = useContext(customerContext);
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [currentInviteType, setCurrentInviteType] = useState('');
    const [isChatOpen, setChatOpen] = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [userFocus, setUserFocus] = useState({});
    const [userModal, setUserModal] = useState(false);
    const [userData, setUserData] = useState(null);
    const [taxView, setTaxView] = useState(false);
    const [userType, setUserType] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [pendingW9Users, setPendingW9Users] = useState([]);
    const [pendingResaleUsers, setPendingResaleUsers] = useState([]);
    const [taxApprovalModal, setTaxApprovalModal] = useState(false);

    const openInviteModal = type => {
        setCurrentInviteType(type);
        setInviteModalOpen(true);
    };

    const closeInviteModal = () => {
        setInviteModalOpen(false);
        setInviteEmail('');
    };

    const openChat = () => setChatOpen(() => true);
    const handleChatOpen = () => {
        setUserModal(false);
        setUserFocus(null);
        if (!isChatOpen) {
            openChat();
        }
    };
    const handleSearch = event => {
        setSearch(event.target.value);
    };

    const handleCloseCreateOrder = () => setCreateOrder(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const user = await Auth.currentSession();
            const token = user.getAccessToken().getJwtToken();
            listUsers('admin', token)
                .then(response => {
                    response.sort((a, b) => {
                        let nameA = a.Attributes.filter(
                            x => x.Name === 'name'
                        )[0];
                        let nameB = b.Attributes.filter(
                            x => x.Name === 'name'
                        )[0];
                        nameA
                            ? (nameA = nameA.Value.toLowerCase())
                            : (nameA = '');
                        nameB
                            ? (nameB = nameB.Value.toLowerCase())
                            : (nameB = '');
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    setAdminList(response);
                })
                .catch(error => {
                    console.log(error);
                    toast.error('Error getting admins.');
                });

            const tempPendingW9Users = [];
            const tempPendingResaleUsers = [];

            listUsers('client', token)
                .then(response => {
                    // sort by name
                    response.sort((a, b) => {
                        const nameA = a.Attributes.filter(
                            x => x.Name === 'name'
                        )[0].Value.toLowerCase();
                        const nameB = b.Attributes.filter(
                            x => x.Name === 'name'
                        )[0].Value.toLowerCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });

                    setClientList(response);
                    let count = 0;
                    response.forEach(u => {
                        const validW9 = u.Attributes.filter(
                            a => a.Name === 'custom:validW9'
                        );
                        const validResale = u.Attributes.filter(
                            a => a.Name === 'custom:validResale'
                        );
                        if (validW9[0] && validW9[0].Value === 'pending') {
                            tempPendingW9Users.push(u);
                            count++;
                        }
                        if (
                            validResale[0] &&
                            validResale[0].Value === 'pending'
                        ) {
                            tempPendingResaleUsers.push(u);
                            count++;
                        }
                    });
                    if (count > 0) {
                        setShowBanner(true);
                    }
                    setPendingW9Users(tempPendingW9Users);
                    setPendingResaleUsers(tempPendingResaleUsers);
                    setFileCount(count);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    toast.error('Error getting clients.');
                });
        };
        fetchData();
    }, []);

    const fetchUnits = async customerId => {
        setUserLoading(true);
        setUserData(null);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json'
            }
        };
        const pathString = '/store/subscription/' + customerId;
        API.get('MyAMZPrepStoreAPI', pathString, requestInfo)
            .then(response => {
                if (response === 'Hello from the store!') {
                    setUserLoading(false);
                } else {
                    setUserData(response);
                    setUserLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                setUserLoading(false);
            });
    };

    async function deleteUser(username) {
        setSubLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        if (username === null || username === '') {
            toast.error('Username must be provided.');
            return;
        }
        const deleteRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                username: username
            }
        };

        API.del('myamzprepfixapi', '/admin', deleteRequestInfo)
            .then(() => {
                clientList.forEach(client => {
                    if (client.Username === username) {
                        setClientList(
                            clientList.filter(a => a.Username !== username)
                        );
                    }
                });
                adminList.forEach(admin => {
                    if (admin.Username === username) {
                        setAdminList(
                            adminList.filter(a => a.Username !== username)
                        );
                    }
                });
                toast.success('User deleted successfully');
                setSubLoading(false);
                setVerifyModal(false);
                setUserModal(!userModal);
            })
            .catch(error => {
                console.log(error);
                toast.error('Error deleting user.');
                setSubLoading(false);
                setVerifyModal(false);
                setUserModal(!userModal);
            });
    }

    function formatPhone(phone) {
        if (phone === null || phone === '') {
            return '';
        }

        // If phone number's country code is +1 and there are 10 digits use +1 (xxx) xxx-xxxx format
        // Otherwise return the unformatted number
        if (phone.startsWith('+1')) {
            const formattedPhone = phone.replace(/\D/g, '').slice(-10);
            if (formattedPhone.length === 10) {
                return `+1 (${formattedPhone.slice(
                    0,
                    3
                )}) ${formattedPhone.slice(3, 6)}-${formattedPhone.slice(
                    6,
                    10
                )}`;
            } else {
                return `+1 ${formattedPhone.slice(1)}`;
            }
        }
        return phone;
    }

    function generateList(userBool, admin, client) {
        let groupList = [];
        if (userBool === false) {
            groupList = client;
        } else {
            groupList = admin;
        }

        // Go through the list and add default attributes to users missing them (for invited users)
        groupList.forEach(item => {
            let hasName = false;
            let hasPhone = false;
            item.Attributes.forEach(attr => {
                if (attr.Name === 'name') {
                    hasName = true;
                }
                if (attr.Name === 'phone_number') {
                    hasPhone = true;
                }
            });
            if (!hasName) {
                item.Attributes.push({
                    Name: 'name',
                    Value: 'Invited User'
                });
            }
            if (!hasPhone) {
                item.Attributes.push({
                    Name: 'phone_number',
                    Value: 'N/A'
                });
            }
            if (userBool) {
                item.Group = 'admin';
            } else {
                item.Group = 'client';
            }
        });

        groupList = groupList.filter(
            item =>
                item.Attributes.filter(index => index.Name === 'name')[0]
                    .Value.toLowerCase()
                    .includes(search.toLowerCase()) ||
                item.Attributes.filter(index => index.Name === 'email')[0]
                    .Value.toLowerCase()
                    .includes(search.toLowerCase())
        );

        return groupList.length > 0 || loading ? (
            groupList.map(userRow => (
                <li
                    className="flex flex-row m-2"
                    key={userRow.Username}
                    onClick={() => {
                        let customerId = '';
                        userRow.Attributes.filter(
                            item => item.Name === 'custom:stripeCustomerId'
                        ).length > 0 &&
                        userRow.Attributes.filter(
                            item => item.Name === 'custom:stripeCustomerId'
                        )[0]
                            ? (customerId = userRow.Attributes.filter(
                                  item =>
                                      item.Name === 'custom:stripeCustomerId'
                              )[0].Value)
                            : (customerId = '');
                        if (userRow.Group === 'client') {
                            fetchUnits(customerId);
                        }
                        setUserModal(!userModal);
                        setUserFocus(userRow);
                        customerIdContext.setCustomerID(userRow.Username);
                    }}>
                    <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                        <div className="flex-1 pl-1 lg:mr-16">
                            <div className="font-semibold text-lg">
                                {
                                    userRow.Attributes.filter(
                                        item => item.Name === 'name'
                                    )[0].Value
                                }
                            </div>
                            <div className="text-gray-600">
                                {'Email: ' +
                                    userRow.Attributes.filter(
                                        item => item.Name === 'email'
                                    )[0].Value}
                            </div>
                            <div className="text-gray-600">
                                {'Phone: ' +
                                    formatPhone(
                                        userRow.Attributes.filter(
                                            item => item.Name === 'phone_number'
                                        )[0].Value
                                    )}
                            </div>
                        </div>
                        <button
                            className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end"
                            onClick={() => {
                                let customerId = '';
                                userRow.Attributes.filter(
                                    item =>
                                        item.Name === 'custom:stripeCustomerId'
                                ).length > 0 &&
                                userRow.Attributes.filter(
                                    item =>
                                        item.Name === 'custom:stripeCustomerId'
                                )[0]
                                    ? (customerId = userRow.Attributes.filter(
                                          item =>
                                              item.Name ===
                                              'custom:stripeCustomerId'
                                      )[0].Value)
                                    : (customerId = '');
                                if (userRow.Group === 'client') {
                                    fetchUnits(customerId);
                                }
                                setUserModal(!userModal);
                                setUserFocus(userRow);
                                customerIdContext.setCustomerID(
                                    userRow.Username
                                );
                            }}>
                            <svg
                                width="20"
                                fill="currentColor"
                                ight="20"
                                className="hover:text-gray-800 text-gray-500"
                                viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                            </svg>
                        </button>
                    </div>
                </li>
            ))
        ) : (
            <div className="flex flex-row m-2 h-28"></div>
        );
    }

    const handleInvite = async adminTypeTemp => {
        setSubLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        // Combine emails from both clientList and adminList for checking
        const allEmails = [...clientList, ...adminList].flatMap(u =>
            u.Attributes.filter(attr => attr.Name === 'email').map(
                attr => attr.Value
            )
        );

        // Check if the email is already in use
        if (allEmails.includes(inviteEmail)) {
            toast.error('This email is already in use.');
            setSubLoading(false);
            return;
        }

        // Mapping adminTypeTemp to adminType
        const adminTypeMapping = {
            Client: 'client',
            Employee: 'admin',
            Manager: 'superadmin'
        };

        const adminType = adminTypeMapping[adminTypeTemp];

        // Validate adminType
        if (!adminType) {
            toast.error('Invalid admin type.');
            setSubLoading(false);
            return;
        }

        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                username: inviteEmail,
                adminType: adminType
            }
        };
        API.post('myamzprepfixapi', '/admin', requestInfo)
            .then(() => {
                toast('Temporary password sent!', {
                    type: 'success',
                    position: 'top-right'
                });
                setSubLoading(false);
                closeInviteModal();
                setInviteEmail('');
            })
            .catch(error => {
                toast('There was an error sending the invitation email.', {
                    type: 'error',
                    position: 'top-right'
                });
                console.log(error.response);
                setSubLoading(false);
                closeInviteModal();
                setInviteEmail('');
            });
    };

    async function exportUserOrders() {
        const username = customerIdContext.customerID;
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };
        API.get('myamzprepfixapi', '/orders?username=' + username, requestInfo)
            .then(response => {
                const orders = response.Items;
                let csvContent = 'data:text/csv;charset=utf-8,';
                csvContent +=
                    'Distributor,OrderID,Status,ProductName,StoreName,CreatedAt,ASIN,Quantity,QuantityReceived,MultiPack\n';
                orders.sort(
                    (a, b) =>
                        new Date(b.nodes[0].CreatedAt) -
                        new Date(a.nodes[0].CreatedAt)
                );

                orders.forEach(order => {
                    order.Distributor = order.Distributor.replace(/,/g, '');
                    order.Distributor = order.Distributor.replace(/#/g, '');
                    order.StoreName = order.StoreName.replace(/,/g, '');
                    order.StoreName = order.StoreName.replace(/#/g, '');
                    order.nodes.forEach(node => {
                        node.Status = order.Status;
                        node.MultiPack = node.MultiPack ? node.MultiPack : '';
                        node.ProductName = node.ProductName.replace(/,/g, '');
                        node.ProductName = node.ProductName.replace(/#/g, '');
                        csvContent += `${order.Distributor},${node.OrderID},${node.Status},${node.ProductName},${order.StoreName},${node.CreatedAt},${node.ASIN},${node.Quantity},${node.QuantityReceived},${node.MultiPack}\n`;
                    });
                });

                const encodedUri = encodeURI(csvContent);
                const link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', 'orders.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.log(error);
                toast.error('Error exporting orders.');
            });
    }

    return (
        <customerContext.Provider value={customerIdContext.customerID}>
            <div className="w-full px-4 lg:p-0">
                <div className="max-w-2xl mx-auto md:w-3/4">
                    <div className="lg:mt-10 mb-6">
                        <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                            Your Users
                        </h1>
                        <p className="mt-1.5 text-sm text-gray-500">
                            View and manage your admin and client users.
                        </p>
                    </div>
                    {showBanner && (
                        <div className="w-full mx-auto mb-7 lg:mb-9 h-10">
                            <div className="py-1 px-2 rounded-lg bg-orange-500 shadow-lg sm:p-3">
                                <div className="flex items-center justify-between flex-wrap">
                                    <div
                                        className="w-0 flex-1 flex items-center cursor-pointer hover:text-underline "
                                        onClick={() =>
                                            setTaxApprovalModal(true)
                                        }>
                                        <span className="flex p-2 rounded-lg">
                                            <IconFileText className="text-white" />
                                        </span>
                                        <p className="text-underline ml-3 font-medium text-white truncate">
                                            <span className="hover:underline inline">
                                                There are {fileCount} tax
                                                documents waiting to be reviewed
                                            </span>
                                        </p>
                                    </div>
                                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                        <button
                                            type="button"
                                            className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() =>
                                                setShowBanner(false)
                                            }>
                                            <span className="sr-only">
                                                Dismiss
                                            </span>
                                            <IconX color={'white'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="relative mx-auto mt-4 flex flex-col lg:flex-row lg:w-full">
                        <div className="flex basis-1/2 mb-1 lg:mb-0">
                            <button
                                type="button"
                                onClick={() => openInviteModal('Client')}
                                className="w-full mt-0 mr-2 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                <IconPlus className="-ml-1 mr-1" />
                                Client
                            </button>
                            <button
                                type="button"
                                onClick={() => openInviteModal('Employee')}
                                className="w-full mr-2 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                <IconPlus className="-ml-1 mr-1" />
                                Employee
                            </button>
                            <button
                                type="button"
                                onClick={() => openInviteModal('Manager')}
                                className="w-full mt-0 mr-2 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                <IconPlus className="-ml-1 mr-1" />
                                Manager
                            </button>
                        </div>
                        <div className="mt-2 lg:mt-0 relative w-full basis-1/2">
                            <IconSearch
                                color={'#737373'}
                                className="absolute mt-2 left-2"
                            />
                            <input
                                type="text"
                                id="rounded-email"
                                value={search}
                                onChange={handleSearch}
                                className="w-full rounded-lg border-transparent flex-1 appearance-none border border-gray-300 py-2 px-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-200 focus:border-transparent"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <div className="pt-3">
                        <div className="pb-5 rounded-t-lg bg-theme-color-400">
                            <ul className="text-sm font-medium text-center text-gray-500 cursor-pointer rounded-lg flex flex-row bg-gray-100">
                                <li className="w-full">
                                    <a
                                        onClick={() => setUserType(false)}
                                        className={
                                            userType
                                                ? inactiveStyle
                                                : activeStyle
                                        }>
                                        Clients
                                    </a>
                                </li>
                                <li className="w-full">
                                    <a
                                        onClick={() => setUserType(true)}
                                        className={
                                            userType
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        Employees
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {loading ? (
                            <div className="container flex flex-col mx-auto w-full items-center justify-center bg-white rounded-b-lg shadow">
                                <RowSkeleton />
                            </div>
                        ) : (
                            <div className="container flex flex-col mx-auto w-full items-center justify-center bg-white rounded-b-lg shadow">
                                <ul
                                    className={
                                        showBanner
                                            ? 'w-full max-h-[65vh] flex flex-col divide divide-y overflow-y-auto'
                                            : 'w-full max-h-[73vh] flex flex-col divide divide-y overflow-y-auto'
                                    }>
                                    {generateList(
                                        userType,
                                        adminList,
                                        clientList
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>

                {userModal && (
                    <>
                        {userLoading ? (
                            <>
                                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                        {/* content */}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/* header */}
                                            <div className="flex flex-col justify-center p-5 border-b border-solid border-slate-200 rounded-t"></div>
                                            {/* footer */}
                                            <div className="flex justify-center h-64"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : (
                            <>
                                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                        {/* content */}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/* header */}
                                            <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <>
                                                    <button
                                                        className="ml-auto"
                                                        onClick={() => {
                                                            setUserModal(
                                                                !userModal
                                                            );
                                                        }}>
                                                        <IconX className="absolute right-3 top-3" />
                                                    </button>
                                                    <div className="flex flex-row items-center">
                                                        <h3 className="text-xl font-semibold">
                                                            {
                                                                userFocus.Attributes.filter(
                                                                    item =>
                                                                        item.Name ===
                                                                        'name'
                                                                )[0].Value
                                                            }
                                                        </h3>
                                                        <button
                                                            onClick={() => {
                                                                handleChatOpen();
                                                            }}>
                                                            <IconMessageCircle className="ml-3" />
                                                        </button>
                                                    </div>

                                                    <p className="text-gray-600 text-s">
                                                        {
                                                            userFocus.Attributes.filter(
                                                                item =>
                                                                    item.Name ===
                                                                    'email'
                                                            )[0].Value
                                                        }
                                                    </p>
                                                    <p className="text-gray-500 text-s">
                                                        {formatPhone(
                                                            userFocus.Attributes.filter(
                                                                item =>
                                                                    item.Name ===
                                                                    'phone_number'
                                                            )[0].Value
                                                        )}
                                                    </p>
                                                    {userFocus.Group ===
                                                        'client' && userData ? (
                                                        <>
                                                            <p className="text-gray-700 text-s">
                                                                {'Current tier: ' +
                                                                    getTierFromUnits(
                                                                        userData
                                                                            .usageRecords
                                                                            .data[0]
                                                                            .total_usage
                                                                    )}
                                                            </p>
                                                            <p className="text-gray-700 text-s">
                                                                {'Units this period: ' +
                                                                    userData.usageRecords.data[0].total_usage.toLocaleString(
                                                                        'en-US'
                                                                    )}
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <p>Current tier: N/A</p>
                                                    )}
                                                </>
                                            </div>
                                            {/* footer */}
                                            <div className="p-4 rounded-b gap-3">
                                                <div className="flex items-center justify-end pb-4 gap-3">
                                                    {!loading ? (
                                                        <button
                                                            type="button"
                                                            className="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-theme-color-400 hover:bg-theme-color-700 font-semibold"
                                                            onClick={() => {
                                                                setTaxView(
                                                                    !taxView
                                                                );
                                                            }}>
                                                            Tax Docs
                                                        </button>
                                                    ) : (
                                                        []
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-green-500 hover:bg-green-600 font-semibold"
                                                        onClick={() => {
                                                            setCreateOrder(
                                                                true
                                                            );
                                                        }}>
                                                        Create Order
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-blue-500 hover:bg-blue-600 font-semibold"
                                                        onClick={() => {
                                                            exportUserOrders();
                                                        }}>
                                                        Export Orders
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setVerifyModal(
                                                                true
                                                            );
                                                        }}
                                                        className="col-span-2 mx-auto w-max flex place-self-center items-center px-6 py-1 transition ease-in duration-200 rounded-full text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white border-2 border-red-500 focus:outline-none">
                                                        Delete user
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        )}
                    </>
                )}

                {taxView && (
                    <TaxView user={userFocus} setTaxView={setTaxView} />
                )}

                {taxApprovalModal && (
                    <TaxApproval
                        pendingW9Users={pendingW9Users}
                        setPendingW9Users={setPendingW9Users}
                        pendingResaleUsers={pendingResaleUsers}
                        setPendingResaleUsers={setPendingResaleUsers}
                        setTaxApprovalModal={setTaxApprovalModal}
                        fileCount={fileCount}
                        setFileCount={setFileCount}
                        setShowBanner={setShowBanner}
                    />
                )}

                <div>
                    {createOrder ? (
                        <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                    {/* content */}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        {/* header */}
                                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                            <h3 className="text-xl font-semibold">
                                                New Order
                                            </h3>
                                        </div>
                                        {/* body */}
                                        <div className="relative p-6 flex-auto">
                                            <OrderForm
                                                closeModal={
                                                    handleCloseCreateOrder
                                                }
                                                customerID={
                                                    customerIdContext.customerID
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div>

                <InviteModal
                    isOpen={inviteModalOpen}
                    onClose={closeInviteModal}
                    onInvite={handleInvite}
                    inviteType={currentInviteType}
                    inviteEmail={inviteEmail}
                    setInviteEmail={setInviteEmail}
                    loading={subLoading}
                />

                {verifyModal && (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            {
                                                userFocus.Attributes.filter(
                                                    item => item.Name === 'name'
                                                )[0].Value
                                            }
                                        </h3>
                                        <p className="text-gray-400 text-xs">
                                            {
                                                userFocus.Attributes.filter(
                                                    item =>
                                                        item.Name === 'email'
                                                )[0].Value
                                            }
                                        </p>
                                    </div>
                                    {/* body */}
                                    <div className="flex items-center justify-end p-4 rounded-b">
                                        <p>
                                            Are you sure you would like to
                                            permanently delete this user?
                                        </p>
                                    </div>
                                    {/* footer */}
                                    <div className="flex items-center justify-end p-4 rounded-b gap-3">
                                        <button
                                            type="button"
                                            className="w-1/2 px-4 py-2 text-base border rounded-lg text-grey-500 bg-white hover:bg-gray-200 "
                                            onClick={() => {
                                                setVerifyModal(false);
                                            }}>
                                            {' '}
                                            Cancel
                                        </button>
                                        <button
                                            className="w-1/2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                            type="button"
                                            onClick={() => {
                                                deleteUser(
                                                    customerIdContext.customerID
                                                );
                                                customerIdContext.setCustomerID(
                                                    ''
                                                );
                                            }}>
                                            {subLoading ? (
                                                <div className="flex items-center justify-center">
                                                    <div className="loading-spinner"></div>
                                                </div>
                                            ) : (
                                                'Confirm delete'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                )}
            </div>
        </customerContext.Provider>
    );
}

export default Users;
