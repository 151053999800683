import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { IconBuildingStore } from '@tabler/icons-react';
import listUsers from '../utils/listUsers';
import PaymentsSkeleton from './skeletons/payments-skeleton';
import PropTypes from 'prop-types';
import ReceiptCard from './payment-card-receipt';

function StoreReceipts({ setShowReceipts }) {
    const [loading, setLoading] = useState(true);
    const [receipts, setReceipts] = useState([]);
    const [storeProducts, setStoreProducts] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const callApi = async () => {
            const user = await Auth.currentAuthenticatedUser();

            // const username = user.signInUserSession.idToken.payload['cognito:username'];
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                }
            };

            const user2 = await Auth.currentSession();
            const token2 = user2.getAccessToken().getJwtToken();

            API.get('MyAMZPrepStoreAPI', '/store/products', requestInfo)
                .then(res => {
                    setStoreProducts(res.Items);
                })
                .catch(error => {
                    console.log(error);
                });

            API.get('MyAMZPrepStoreAPI', '/store/orderReceipts', requestInfo)
                .then(response => {
                    // sort by date
                    const sortedReceipts = response.Items.sort((a, b) => {
                        const dateA = new Date(a.CreatedAt);
                        const dateB = new Date(b.CreatedAt);
                        return dateB - dateA;
                    });
                    setReceipts(sortedReceipts);
                })
                .catch(error => {
                    console.log(error);
                });

            const usersArr = [];
            listUsers('client', token2).then(res => {
                res.forEach(userObj => {
                    userObj.Attributes.forEach(attr => {
                        if (attr.Name === 'name') {
                            usersArr.push({
                                name: attr.Value,
                                id: userObj.Username
                            });
                        }
                    });
                });
                setLoading(false);
            });

            setUsers(usersArr);
        };

        callApi();
    }, []);

    const ReceiptsList = () => {
        if (receipts.length > 0) {
            return receipts.map(receipt => (
                <ReceiptCard
                    key={receipt.OrderID}
                    receipt={receipt}
                    products={storeProducts}
                    users={users}
                />
            ));
        }
    };

    return (
        <div>
            <div className="flex flex-col justify-between items-start lg:items-center lg:flex-row mt-4 lg:mt-10 mb-6 lg:ml-9 lg:mr-8">
                <div className="flex flex-col">
                    <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                        Receipts
                    </h1>
                    <p className="mt-1.5 text-base sm:text-sm text-gray-500">
                        View receipts from your past orders.
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row w-full lg:w-auto">
                    <button
                        type="button"
                        onClick={() => setShowReceipts(false)}
                        className="mt-2 lg:mt-0 lg:ml-8 w-full lg:w-40 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-200 focus:ring-theme-color-100 focus:ring-offset-theme-color-100
                        text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                        <IconBuildingStore className="mr-2" />
                        View products
                    </button>
                </div>
            </div>
            <div className="flex flex-wrap items-start justify-evenly">
                {loading ? <PaymentsSkeleton /> : <ReceiptsList />}
            </div>
        </div>
    );
}

StoreReceipts.propTypes = {
    setShowReceipts: PropTypes.func
};

export default StoreReceipts;
