/**
 * Function that truncates a string to a certain length
 */
export default function getUnitsFromOrder(order) {
    let units = 0;
    order.nodes.forEach(product => {
        units += product?.Quantity || 0;
    });
    return units;
}
