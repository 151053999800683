import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentUpdateForm from './payment-update-form';
import PropTypes from 'prop-types';

function PaymentUpdate({ setUpdatePaymentModal, setUpdateLoading }) {
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        const updateStripeSubscription = async () => {
            setUpdateLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const email = user.attributes.email;
            const tier = user.attributes['custom:tier'];
            tier ? tier : '1';
            console.log(user.attributes);
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json'
                },
                body: {
                    tier: tier,
                    email: email
                }
            };
            API.post('MyAMZPrepStoreAPI', '/store/subscription', requestInfo)
                .then(response => {
                    setClientSecret(response.client_secret);
                    setUpdateLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setUpdateLoading(false);
                });
        };
        updateStripeSubscription();
    }, []);

    const appearance = {
        theme: 'stripe'
    };
    const options = {
        clientSecret,
        appearance
    };
    const stripePromise = loadStripe(
        'pk_live_51LUcZfBcStMYyygYEmyxpfTW4WazBZbHYD1ljTV2Z0XsmA6EDIE4Ti9Z7Uo4iDeOiBaEBuUrraMzQgPOfgPy5Ko70091aUIwoj'
    );

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <PaymentUpdateForm
                        setUpdatePaymentModal={setUpdatePaymentModal}
                    />
                </Elements>
            )}
        </div>
    );
}

export default PaymentUpdate;

PaymentUpdate.propTypes = {
    setUpdatePaymentModal: PropTypes.func,
    setUpdateLoading: PropTypes.func
};
