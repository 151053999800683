import { IconShoppingCartOff, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import cartTotal from '../utils/cartTotal.js';
import config from '../aws-exports';
import NoImage from '../images/no-image.jpg';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;

function Cart() {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        setTotal(0);
        const tempCart = JSON.parse(localStorage.getItem('cart'));
        // check if product.QuantitySelected is greater than product.QuantityAvailable
        if (tempCart) {
            tempCart.forEach(item => {
                if (item.QuantitySelected > item.QuantityAvailable) {
                    item.QuantitySelected = item.QuantityAvailable;
                }
            });
        }
        setTotal(cartTotal(tempCart));
        setCartItems(tempCart);
        setLoading(false);
    }, []);

    // If cart is empty, display empty cart page
    if ((!cartItems || cartItems.length === 0) && !loading) {
        return (
            <div className="mt-[10%] w-full min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                    <main className="sm:flex">
                        <IconShoppingCartOff size={70} color="#1965db" />
                        <div className="sm:ml-6">
                            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    Your cart is empty
                                </h1>
                                <p className="mt-2 text-base text-gray-500">
                                    Visit the store to add items to your cart.
                                </p>
                            </div>
                            <div className="mt-10 flex space-x-3 sm:pl-6">
                                <a
                                    href="/store"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-theme-color-400 hover:bg-theme-color-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color-700">
                                    View the store
                                </a>
                                <a
                                    href="/"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-theme-color-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-color-700">
                                    Go back home
                                </a>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    } else {
        return (
            <section className="w-full">
                <div className="mt-10 mb-6 ml-9">
                    <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                        Cart Overview
                    </h1>
                    <p className="mt-1.5 text-sm text-gray-500">
                        View and edit your cart.
                    </p>
                </div>

                <div className="container max-w-2xl mx-auto shadow-md md:w-3/4">
                    <div className="grid grid-cols-1">
                        {/* Order summary */}
                        <div className="mt-10 lg:mt-0">
                            <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                                <h3 className="sr-only">Items in your cart</h3>
                                <ul
                                    role="list"
                                    className="divide-y divide-gray-200">
                                    {cartItems.map(product => (
                                        <li
                                            key={product.ProductID}
                                            className="flex py-6 px-4 sm:px-6">
                                            <div className="flex-shrink-0">
                                                <img
                                                    src={
                                                        product.ImageURL
                                                            ? product.ImageURL
                                                            : product.ImageS3
                                                            ? `https://${bucket}.s3.${region}.amazonaws.com/public/${product.ImageS3}`
                                                            : NoImage
                                                    }
                                                    alt=""
                                                    className="w-20 h-20 object-cover rounded-md"
                                                />
                                            </div>

                                            <div className="ml-6 flex-1 flex flex-col">
                                                <div className="flex">
                                                    <div className="min-w-0 flex-1">
                                                        <h4 className="text-sm">
                                                            <a
                                                                href={
                                                                    product.href
                                                                }
                                                                className="font-medium text-gray-700 hover:text-gray-800">
                                                                {
                                                                    product.ProductName
                                                                }
                                                            </a>
                                                        </h4>
                                                        <a
                                                            href={`https://amazon.com/dp/${product.ASIN}`}>
                                                            <span className="mt-1 text-sm text-gray-500 underline hover:decoration-2 underline-offset-1">
                                                                {product.ASIN}
                                                            </span>
                                                        </a>
                                                    </div>

                                                    <div className="ml-4 flex-shrink-0 flow-root">
                                                        {/* Total calculated correctly on page load but not onchange */}
                                                        <button
                                                            type="button"
                                                            className="-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500"
                                                            onClick={() => {
                                                                const newCart =
                                                                    cartItems.filter(
                                                                        item =>
                                                                            item.ProductID !==
                                                                            product.ProductID
                                                                    );
                                                                setCartItems(
                                                                    newCart
                                                                );
                                                                localStorage.setItem(
                                                                    'cart',
                                                                    JSON.stringify(
                                                                        newCart
                                                                    )
                                                                );
                                                                // Update the total
                                                                setTotal(
                                                                    total -
                                                                        product.Price *
                                                                            product.QuantitySelected
                                                                );
                                                            }}>
                                                            <span className="sr-only">
                                                                Remove
                                                            </span>
                                                            <IconTrash
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="flex-1 pt-2 flex items-end justify-between">
                                                    <p className="mt-1 text-sm font-medium text-gray-900">
                                                        {'$' + product.Price}
                                                    </p>

                                                    <div className="ml-4">
                                                        <label
                                                            htmlFor="quantity"
                                                            className="sr-only">
                                                            Quantity
                                                        </label>
                                                        <input
                                                            id="quantity"
                                                            name="quantity"
                                                            type="number"
                                                            value={Number(
                                                                product.QuantitySelected
                                                            )}
                                                            onChange={e => {
                                                                if (
                                                                    e.target
                                                                        .value <
                                                                    1
                                                                ) {
                                                                    e.target.value = 1;
                                                                }
                                                                if (
                                                                    e.target
                                                                        .value >
                                                                    Number(
                                                                        product.QuantityAvailable
                                                                    )
                                                                ) {
                                                                    e.target.value =
                                                                        Number(
                                                                            product.QuantityAvailable
                                                                        );
                                                                }
                                                                setTotal(0);
                                                                let temp = 0;
                                                                const newCart =
                                                                    cartItems.map(
                                                                        item => {
                                                                            if (
                                                                                item.ProductID ===
                                                                                product.ProductID
                                                                            ) {
                                                                                item.QuantitySelected =
                                                                                    Number(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    );
                                                                            }
                                                                            return item;
                                                                        }
                                                                    );
                                                                localStorage.setItem(
                                                                    'cart',
                                                                    JSON.stringify(
                                                                        newCart
                                                                    )
                                                                );
                                                                newCart.forEach(
                                                                    item => {
                                                                        temp +=
                                                                            temp +
                                                                            item.Price *
                                                                                Number(
                                                                                    item.QuantitySelected
                                                                                );
                                                                    }
                                                                );
                                                                setTotal(temp);
                                                            }}
                                                            className="w-16 rounded-md border border-gray-300 text-base text-center font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-theme-color-400 focus:border-theme-color-400 sm:text-sm"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <dt className="text-base font-medium">
                                            Subtotal
                                        </dt>
                                        <dd className="text-base font-medium text-gray-900">
                                            {'$' +
                                                total
                                                    .toFixed(2)
                                                    .toLocaleString({
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ','
                                                    )}
                                        </dd>
                                    </div>
                                </dl>

                                <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                    {total < 1000000 ? (
                                        <a href="/checkout">
                                            <button className="w-full bg-blue-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-200 focus:ring-blue-500">
                                                Confirm order
                                            </button>
                                        </a>
                                    ) : (
                                        <>
                                            <div className="mb-2 text-center text-red-600">
                                                Order must be less than
                                                $1,000,000
                                            </div>
                                            <button
                                                disabled={true}
                                                className="w-full bg-gray-400 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white">
                                                Confirm order
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cart;
