import { Container } from './Container';
import MyAmzPrepLogo from '../../images/my-amz-prep.png';
import React from 'react';

export function Footer() {
    return (
        <Container as="footer" className="mt-6 w-full sm:mt-32 lg:mt-10">
            <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
            </div>
            <div className="mb-20 mt-24 flex align-center flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-neutral-900/10 pt-12">
                <a href="/" aria-label="Home">
                    <img src={MyAmzPrepLogo} alt="MyAMZPrep" className="w-20" />
                </a>
                <p className="text-sm text-neutral-700">
                    © MyAMZPrep {new Date().getFullYear()}
                </p>
            </div>
        </Container>
    );
}
