import PropTypes from 'prop-types';
import React from 'react';

const sentStyle =
    'bg-blue-400 rounded max-w-[240px] float-right right-1 px-2 py-1 text-white h-max';
const receivedStyle =
    'bg-gray-200 rounded max-w-[240px] left-0 float-left px-2 py-1 left-1 text-black';

function ChatBlob({ message, sent }) {
    const style = sent ? sentStyle : receivedStyle;
    return (
        <div className="relative my-2 h-max">
            <div className={style}>{message}</div>
        </div>
    );
}

ChatBlob.propTypes = {
    message: PropTypes.string.isRequired,
    sent: PropTypes.bool.isRequired
};

export default ChatBlob;
