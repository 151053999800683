import { IconCheck, IconMinus } from '@tabler/icons-react';
import React, { Fragment } from 'react';

const tiers = [
    {
        name: 'Tier 1',
        href: '/checkoutSubscription',
        priceMonthly: 0.94,
        description: 'For stores shipping 0-2000 units per month.',
        buttonText: 'Choose plan'
    },
    {
        name: 'Tier 2',
        href: '/checkoutSubscription',
        priceMonthly: 0.92,
        description: 'For stores shipping 2001-5000 units per month.',
        buttonText: 'Choose plan'
    },
    {
        name: 'Tier 3',
        href: '/checkoutSubscription',
        priceMonthly: 0.9,
        description: 'For stores shipping 5001-8000 units per month.',
        buttonText: 'Choose plan'
    },
    {
        name: 'Tier 4',
        href: '/checkoutSubscription',
        priceMonthly: 'Custom',
        description:
            'Contact us for custom pricing for stores shipping more than 8000 units per month.',
        buttonText: 'Contact for pricing'
    }
];
const sections = [
    {
        name: 'Units',
        features: [
            {
                name: 'Number of units per month',
                tiers: {
                    'Tier 1': '0-2000',
                    'Tier 2': '2001-5000',
                    'Tier 3': '5001-8000',
                    'Tier 4': '8000+'
                }
            },
            {
                name: 'Base price per unit',
                tiers: {
                    'Tier 1': '$0.94',
                    'Tier 2': '$0.92',
                    'Tier 3': '$0.90',
                    'Tier 4': 'Custom'
                }
            }
        ]
    },
    {
        name: 'Add ons',
        features: [
            {
                name: 'Shrink wrap or polly bag',
                tiers: {
                    'Tier 1': '$0.40',
                    'Tier 2': '$0.38',
                    'Tier 3': '$0.36',
                    'Tier 4': 'Custom'
                }
            },
            {
                name: 'Bundle (Includes Shrink wrap/Poly Bag)',
                tiers: {
                    'Tier 1': '$0.70',
                    'Tier 2': '$0.68',
                    'Tier 3': '$0.66',
                    'Tier 4': 'Custom'
                }
            },
            {
                name: 'Bubble wrap',
                tiers: {
                    'Tier 1': '$0.60',
                    'Tier 2': '$0.55',
                    'Tier 3': '$0.50',
                    'Tier 4': 'Custom'
                }
            },
            {
                name: 'Bubble wrap for items that do not fit in 6” x 11.5” bags',
                tiers: {
                    'Tier 1': '$1.76',
                    'Tier 2': '$1.75',
                    'Tier 3': '$1.73',
                    'Tier 4': 'Custom'
                }
            },
            {
                name: 'Shrink wrap for large items over 90oz',
                tiers: {
                    'Tier 1': '$0.80',
                    'Tier 2': '$0.78',
                    'Tier 3': '$0.75',
                    'Tier 4': 'Custom'
                }
            }
        ]
    }
];

export default function Pricing() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto bg-white pt-16 pb-4 sm:pb-8 sm:pt-24 sm:px-6 lg:px-8">
                <div className="mb-8 sm:flex sm:flex-col sm:align-center px-4 sm:px-0">
                    <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 sm:text-center">
                        Pricing Plans
                    </h1>
                    <p className="mt-5 sm:text-lg text-gray-500 sm:text-center">
                        We offer a simple pricing model with no hidden fees.
                        This applies for Wholesale/OA/RA (FBA/WFS only). Please
                        contact us for Merchant Fulfilled pricing. You will be
                        charged based on your usage after a period of 30 days.
                    </p>
                </div>
                <div
                    className={
                        'lg:w-64 px-6 mb-12 text-gray-500 text-center mx-auto'
                    }>
                    <a
                        href={tiers[0].href}
                        className={
                            'block bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 w-auto mx-auto'
                        }>
                        {'Subscribe now'}
                    </a>
                </div>
                <div className="my-12">
                    <div className="mt-4 flex items-center gap-x-4">
                        <div className="h-px flex-auto bg-gray-100" />
                        <h4 className="flex-none font-semibold leading-6 text-indigo-600">
                            Included in the base price:
                        </h4>
                        <div className="h-px flex-auto bg-gray-100" />
                    </div>
                    <ul
                        role="list"
                        className="grid grid-cols-1 leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6 items-center">
                        <div className="sm:mx-auto ml-4">
                            <li className="flex my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                Order Management Software
                            </li>
                            <li className="flex my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                Receiving
                            </li>
                            <li className="flex my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                QC Check
                            </li>
                        </div>
                        <div className="sm:mx-auto ml-4">
                            <li className="flex mb-2 sm:my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                FNSKU Labels
                            </li>
                            <li className="flex my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                Outgoing carton boxes
                            </li>
                            <li className="flex my-4">
                                <IconCheck color={'#4f46e5'} className="mr-1" />
                                Pallets for outgoing shipments
                            </li>
                        </div>
                    </ul>
                </div>
                {/* xs to lg */}
                <div className="max-w-2xl mx-auto space-y-16 lg:hidden">
                    {tiers.map(tier => (
                        <section key={tier.name}>
                            <div className="px-4 mb-8">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">
                                    {tier.name}
                                </h2>
                                <p className="mt-4">
                                    <span className="text-4xl font-bold text-gray-900">
                                        $
                                        {tier.priceMonthly.toLocaleString(
                                            'en-US',
                                            { minimumFractionDigits: 2 }
                                        )}
                                    </span>{' '}
                                    <span className="text-base font-medium text-gray-500">
                                        /mo
                                    </span>
                                </p>
                                <p className="mt-4 text-sm text-gray-500">
                                    {tier.description}
                                </p>
                            </div>

                            {sections.map(section => (
                                <table key={section.name} className="w-full">
                                    <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                                        {section.name}
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th className="sr-only" scope="col">
                                                Feature
                                            </th>
                                            <th className="sr-only" scope="col">
                                                Included
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {section.features.map(feature => (
                                            <tr
                                                key={feature.name}
                                                className="border-t border-gray-200">
                                                <th
                                                    className="py-5 px-4 text-sm font-normal text-gray-500 text-left"
                                                    scope="row">
                                                    {feature.name}
                                                </th>
                                                <td className="py-5 pr-4">
                                                    {typeof feature.tiers[
                                                        tier.name
                                                    ] === 'string' ? (
                                                        <span className="block text-sm text-gray-700 text-right">
                                                            {
                                                                feature.tiers[
                                                                    tier.name
                                                                ]
                                                            }
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[
                                                                tier.name
                                                            ] === true ? (
                                                                <IconCheck
                                                                    className="ml-auto h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <IconMinus
                                                                    className="ml-auto h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}

                                                            <span className="sr-only">
                                                                {feature.tiers[
                                                                    tier.name
                                                                ] === true
                                                                    ? 'Yes'
                                                                    : 'No'}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </section>
                    ))}
                </div>
                {/* lg+ */}
                <div className="hidden lg:block">
                    <table className="w-full h-px table-fixed">
                        <caption className="sr-only">
                            Pricing plan comparison
                        </caption>
                        <thead>
                            <tr>
                                <th
                                    className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                                    scope="col">
                                    <span className="sr-only">Feature by</span>
                                    <span>Plans</span>
                                </th>
                                {tiers.map(tier => (
                                    <th
                                        key={tier.name}
                                        className="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                                        scope="col">
                                        {tier.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="border-t border-gray-200 divide-y divide-gray-200">
                            {sections.map(section => (
                                <Fragment key={section.name}>
                                    <tr>
                                        <th
                                            className="bg-gray-50 py-3 pl-6 text-sm font-medium text-gray-900 text-left"
                                            colSpan={5}
                                            scope="colgroup">
                                            {section.name}
                                        </th>
                                    </tr>
                                    {section.features.map(feature => (
                                        <tr key={feature.name}>
                                            <th
                                                className="py-5 px-6 text-sm font-normal text-gray-500 text-left"
                                                scope="row">
                                                {feature.name}
                                            </th>
                                            {tiers.map(tier => (
                                                <td
                                                    key={tier.name}
                                                    className="py-5 px-6">
                                                    {typeof feature.tiers[
                                                        tier.name
                                                    ] === 'string' ? (
                                                        <span className="block text-sm text-gray-700">
                                                            {
                                                                feature.tiers[
                                                                    tier.name
                                                                ]
                                                            }
                                                        </span>
                                                    ) : (
                                                        <>
                                                            {feature.tiers[
                                                                tier.name
                                                            ] === true ? (
                                                                <IconCheck
                                                                    className="h-5 w-5 text-green-500"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <IconMinus
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}

                                                            <span className="sr-only">
                                                                {feature.tiers[
                                                                    tier.name
                                                                ] === true
                                                                    ? 'Included'
                                                                    : 'Not included'}{' '}
                                                                in {tier.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div className="lg:w-64 px-6 mt-8 text-gray-500 text-center mx-auto">
                        <a
                            href={tiers[0].href}
                            className={
                                'block bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900 w-auto mx-auto'
                            }>
                            {'Subscribe now'}
                        </a>
                    </div>
                    <div className="mt-8 mb-4 text-sm text-gray-500">
                        <p className="mb-4">
                            Bundles that weigh more than 25 pounds or are bigger
                            than 18 inches are subject to extra fees. This
                            requires more time to be spent on these products,
                            please ask for pricing as it varies depending on the
                            product.
                        </p>
                        <p>
                            Please keep in mind that shipping from our warehouse
                            to amazon&apos;s warehouse is not included. That
                            will be deducted from your seller central account if
                            using a partnered carrier. If not you will see an
                            additional shipping charge on your Invoice if you
                            use our carriers. We use 2D Workflow to set up
                            shipments on your account, we also use it to print
                            labels and buy shipping labels with your account.
                            The subscription fee for 2D Workflow is paid by us.
                            You do not pay for that.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
