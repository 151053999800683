/* eslint-disable no-undefined */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { motion, MotionConfig, useReducedMotion } from 'framer-motion';
import React, {
    createContext,
    useContext,
    useEffect,
    useId,
    useRef,
    useState
} from 'react';
import { ServiceIcons, Services } from '../components/homepage/Services';
import Benefits from '../components/homepage/Benefits';
import ContactForm from '../components/homepage/ContactForm';
import { Container } from '../components/homepage/Container';
import { Footer } from '../components/homepage/Footer';
import Gallery from '../components/homepage/Gallery';
import { GridPattern } from '../components/homepage/GridPattern';
import MyAmzPrepLogo from '../images/my-amz-prep.png';
import MyAmzPrepLogoHighlight from '../images/my-amz-prep-highlight.png';

import { Testimonial } from '../components/homepage/Testimonial';
import { ToastContainer } from 'react-toastify';

import '../styles/homepage/home.css';

export const metadata = {
    description:
        'We are a FBA prep and shipping company that provides expert services to Amazon sellers. We offer a comprehensive, stress-free FBA prep for sellers, by sellers.'
};

export function Home() {
    return (
        <>
            <Container className="mt-20 md:mt-24">
                <div className="flex flex-col lg:flex-row items-center lg:items-center">
                    <div className="flex-1">
                        <div className="max-w-xl">
                            <h1 className="font-display text-5xl font-medium tracking-tight text-theme-color-400 [text-wrap:balance] sm:text-7xl sm:text-left md:text-center lg:text-left">
                                Expert prep and shipping solutions
                            </h1>
                            <p className="mt-6 text-xl text-neutral-900 sm:text-left md:text-center lg:text-left">
                                Comprehensive, stress-free FBA prep for sellers,
                                by sellers.
                            </p>
                            <ServiceIcons />
                        </div>
                    </div>
                    <ContactForm />
                </div>
            </Container>
            <Testimonial
                className="mt-24 sm:mt-32 lg:mt-40 md:rounded-4xl"
                client={{ name: 'Sarah K – FBA Seller' }}>
                Working with My AMZ Prep has made a huge difference for my
                business. They really know their stuff when it comes to handling
                my products. I&apos;ve saved so much time thanks to them.
            </Testimonial>
            <Benefits />
            <Services />
            <Gallery />
            <ToastContainer />
        </>
    );
}

const RootLayoutContext = createContext(null);

function MenuIcon(props) {
    return (
        <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
            <path d="M2 6h20v2H2zM2 16h20v2H2z" />
        </svg>
    );
}

function Header() {
    const { logoHovered, setLogoHovered } = useContext(RootLayoutContext);

    return (
        <Container>
            <div className="flex items-center justify-between">
                <a
                    href="/"
                    aria-label="Home"
                    onMouseEnter={() => setLogoHovered(true)}
                    onMouseLeave={() => setLogoHovered(false)}>
                    {logoHovered ? (
                        <img
                            src={MyAmzPrepLogoHighlight}
                            alt="My Amz Prep"
                            className="h-16 md:h-24"
                        />
                    ) : (
                        <img
                            src={MyAmzPrepLogo}
                            alt="My Amz Prep"
                            className="h-16 md:h-24"
                        />
                    )}
                </a>
                <div className="flex items-center space-x-8">
                    <a
                        href="#testimonial"
                        className="hover:underline text-lg text-neutral-900 cursor-pointer hidden md:flex">
                        Testimonial
                    </a>
                    <a
                        href="#benefits"
                        className="hover:underline text-lg text-neutral-900 cursor-pointer hidden md:flex">
                        Benefits
                    </a>
                    <a
                        href="#services"
                        className="hover:underline text-lg text-neutral-900 cursor-pointer hidden md:flex">
                        Services
                    </a>
                    <a
                        href="#gallery"
                        className="hover:underline text-lg text-neutral-900 cursor-pointer hidden md:flex">
                        Gallery
                    </a>
                    <a
                        href="/orders"
                        className="w-full h-[38px] p-6 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-200 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-lg font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-2xl ">
                        Login
                    </a>
                </div>
            </div>
        </Container>
    );
}

function RootLayoutInner({ children }) {
    const panelId = useId();
    const [expanded, setExpanded] = useState(false);
    const openRef = useRef(null);
    const closeRef = useRef(null);
    const shouldReduceMotion = useReducedMotion();

    useEffect(() => {
        function onClick(event) {
            if (
                event.target instanceof HTMLElement &&
                event.target.closest('a')?.href === window.location.href
            ) {
                setExpanded(false);
            }
        }

        window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick);
        };
    }, []);

    return (
        <MotionConfig
            transition={shouldReduceMotion ? { duration: 0 } : undefined}>
            <header>
                <div
                    className="absolute left-0 right-0 z-40 pt-4 md:pt-14"
                    aria-hidden={expanded ? 'true' : undefined}
                    inert={expanded ? '' : undefined}>
                    <Header
                        panelId={panelId}
                        icon={MenuIcon}
                        toggleRef={openRef}
                        expanded={expanded}
                        onToggle={() => {
                            setExpanded(expanded => !expanded);
                            window.setTimeout(() =>
                                closeRef.current?.focus({ preventScroll: true })
                            );
                        }}
                    />
                </div>
            </header>

            <motion.div
                layout
                className="relative flex flex-auto overflow-hidden bg-white pt-14">
                <motion.div
                    layout
                    className="relative isolate flex w-full flex-col pt-9">
                    <GridPattern
                        className="absolute inset-x-0 -top-14 -z-10 h-[1000px] w-full fill-neutral-50 stroke-theme-color-400/5"
                        yOffset={-96}
                        interactive
                    />

                    <main className="w-full flex-auto">{children}</main>

                    <Footer />
                </motion.div>
            </motion.div>
        </MotionConfig>
    );
}

export default function RootLayout() {
    const [logoHovered, setLogoHovered] = useState(false);

    return (
        <html lang="en" className="h-full bg-neutral-900 text-base antialiased">
            <body className="flex min-h-full flex-col">
                <RootLayoutContext.Provider
                    value={{ logoHovered, setLogoHovered }}>
                    <RootLayoutInner>
                        <Home />
                    </RootLayoutInner>
                </RootLayoutContext.Provider>
            </body>
        </html>
    );
}
