/**
 * Function that truncates a string to a certain length
 */
export default function truncateString(str, len) {
    if (!str) {
        return '';
    }
    if (str.length <= len) {
        return str;
    }
    return str.slice(0, len) + '...';
}
