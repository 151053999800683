import { API, Auth } from 'aws-amplify';
import { IconPlus, IconX } from '@tabler/icons-react';
import React, { useContext, useRef, useState } from 'react';
import AddNoteForm from './add-note-form';
import NotesList from './NotesList';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useUser } from '../contexts/user-context';

NotesList.propTypes = {
    notes: PropTypes.array
};

function Notes({
    setNotesForm,
    refreshTable,
    rows,
    setRows,
    productID,
    setProductID
}) {
    const { user, userGroup } = useUser();
    const [addNoteForm, setAddNoteForm] = useState(false);
    const notesRef = useRef(null);
    const { order, setOrderInfo, products, setProducts } =
        useContext(orderContext);

    const isNewNoteFormat = true;

    const getOldNoteText = () => {
        let notes = 'This item has no notes.';
        if (productID) {
            order.nodes.forEach(product => {
                if (
                    product.ProductID === productID &&
                    product.Notes.length > 0
                ) {
                    notes = product.Notes;
                }
            });
        } else if (order.Notes.length > 0) {
            notes = order.Notes;
        }

        return notes;
    };

    const getNewNotes = () => {
        let notes = [];
        if (productID) {
            order.nodes.forEach(product => {
                if (
                    product.ProductID === productID &&
                    product.Notes.length > 0
                ) {
                    notes = product.Notes;
                }
            });
        } else if (order && order.Notes.length > 0) {
            notes = order.Notes;
        }

        return notes;
    };

    const handleAddNote = () => {
        setAddNoteForm(true);
    };

    const handleClose = () => {
        setOrderInfo({
            ...order,
            ProductName: '',
            ASIN: '',
            Quantity: '',
            QuantityReceived: '',
            COG$: ''
        });
        setProductID(false);
        setNotesForm(false);
        setOrderInfo(false);
        setProducts([]);
    };

    const handleCreateNote = async () => {
        const updateOrderReq = {
            CreatedAt: order ? order.CreatedAt : new Date().toISOString(),
            OrderID: order.OrderID,
            UserID: order.UserID,
            UserFullName: order.UserFullName,
            ASIN: '',
            COG$: '',
            Distributor: order.Distributor,
            Notes: '',
            ProductName: '',
            Quantity: '',
            QuantityReceived: '',
            Status: order ? order.Status : 'Inbound',
            StoreName: order.StoreName,
            nodes: products ?? []
        };

        if (productID) {
            updateOrderReq.Notes = order.Notes;
            const selectedProduct = products.find(
                product => product.ProductID === productID
            );

            selectedProduct.Notes = [
                {
                    note: notesRef.current.value,
                    timestamp: new Date(),
                    isAdmin: userGroup.includes('admin'),
                    userName: user.name
                },
                ...selectedProduct.Notes
            ];

            // Remove product with same id from products array
            const newProducts = products.filter(
                item => item.ProductID !== selectedProduct.ProductID
            );

            const reqProducts = [selectedProduct, ...newProducts];

            // Add product to products array
            setProducts(reqProducts);

            setOrderInfo({
                ...order,
                ProductName: '',
                ASIN: '',
                Quantity: '',
                QuantityReceived: '',
                COG$: ''
            });
            updateOrderReq.nodes = reqProducts;
        } else {
            console.log(user);
            updateOrderReq.Notes = [
                {
                    note: notesRef.current.value,
                    timestamp: new Date(),
                    isAdmin: userGroup.includes('admin'),
                    userName: user.name
                },
                ...order.Notes
            ];
        }

        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;

        const updateRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                order: updateOrderReq
            }
        };

        API.patch('myamzprepfixapi', '/orders', updateRequestInfo)
            .then(res => {
                toast('Note added successfully!', {
                    type: 'success',
                    position: 'top-right'
                });

                const tempArr = rows.filter(
                    row => row.OrderID !== order.OrderID
                );
                setRows([updateOrderReq, ...tempArr]);
                // setPLoading(false);
                setOrderInfo(false);
                handleClose();
                refreshTable();
                console.log(res);
            })
            .catch(err => {
                console.log(err);
                // setPLoading(false);
                handleClose();
            });
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none min-w-[436px]">
                        {/* header */}
                        <div className="flex items-center px-5 py-3 border-b border-solid border-slate-200 rounded-t ">
                            <h3 className="text-xl font-semibold">Notes</h3>
                            <div>
                                {!addNoteForm && (
                                    <button
                                        type="button"
                                        onClick={handleAddNote}
                                        className="w-32 h-[38px] ml-6 pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                        <IconPlus className="mr-1 -ml-1" />
                                        Add note
                                    </button>
                                )}
                            </div>

                            <button
                                onClick={handleClose}
                                className="absolute top-4 right-2 cursor-pointer">
                                <IconX />
                            </button>
                        </div>
                        {/* body */}
                        {isNewNoteFormat && (
                            <div className="relative pt-1 pb-2 flex-auto ">
                                {addNoteForm ? (
                                    <AddNoteForm
                                        handleCreateNote={handleCreateNote}
                                        notesRef={notesRef}
                                    />
                                ) : (
                                    <NotesList notes={getNewNotes()} />
                                )}
                            </div>
                        )}
                        {!isNewNoteFormat && (
                            <div className="relative pt-4 pb-5 flex-auto text-center">
                                {addNoteForm ? (
                                    <AddNoteForm
                                        handleCreateNote={handleCreateNote}
                                        notesRef={notesRef}
                                    />
                                ) : (
                                    <p>{getOldNoteText()}</p>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

Notes.propTypes = {
    notesForm: PropTypes.any,
    productID: PropTypes.any,
    refreshTable: PropTypes.func.isRequired,
    rows: PropTypes.any,
    setNotesForm: PropTypes.func.isRequired,
    setProductID: PropTypes.any,
    setProductNotes: PropTypes.any,
    setProducts: PropTypes.any,
    setRows: PropTypes.any
};

export default Notes;
