export default function cartTotal(cartItems) {
    if (!cartItems) {
        return 0;
    }
    const total = cartItems.reduce(
        (acc, item) => acc + item.QuantitySelected * item.Price,
        0
    );
    return total;
}
