import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import CheckoutFormSubscription from '../components/checkout-form-subscription';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function CheckoutSubscription() {
    const [clientSecret, setClientSecret] = useState('');
    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        const createStripeSubscription = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const email = user.attributes.email;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json'
                },
                body: {
                    email: email
                }
            };
            API.post('MyAMZPrepStoreAPI', '/store/subscription', requestInfo)
                .then(response => {
                    setClientSecret(response.client_secret);
                })
                .catch(error => {
                    console.error(error);
                });
        };
        createStripeSubscription();
    }, []);

    const appearance = {
        theme: 'stripe'
    };
    const options = {
        clientSecret,
        appearance
    };
    const stripePromise = loadStripe(
        'pk_live_51LUcZfBcStMYyygYEmyxpfTW4WazBZbHYD1ljTV2Z0XsmA6EDIE4Ti9Z7Uo4iDeOiBaEBuUrraMzQgPOfgPy5Ko70091aUIwoj'
    );

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutFormSubscription />
                </Elements>
            )}
        </div>
    );
}

export default CheckoutSubscription;
