import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import ProtectedApp from './ProtectedApp';
import PublicHomepage from './routes/publicHomepage';
import React from 'react';
import './styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsconfig);

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<PublicHomepage />} />
                <Route path="/*" element={<ProtectedApp />} />
            </Routes>
        </Router>
    );
}

export default App;
