import PropTypes from 'prop-types';
import React from 'react';

const InviteModal = ({
    isOpen,
    onClose,
    onInvite,
    inviteType,
    inviteEmail,
    setInviteEmail,
    loading
}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-96 my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                {inviteType} Invite
                            </h3>
                        </div>
                        <div className="relative px-6 pt-6 flex-auto">
                            <p className="text-gray-500">
                                A {inviteType.toLowerCase()} will be created and
                                a temporary password will be sent to the email
                                address.
                            </p>
                            <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                <input
                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    type="text"
                                    required
                                    placeholder={`${inviteType}'s Email`}
                                    value={inviteEmail}
                                    onChange={e =>
                                        setInviteEmail(e.target.value)
                                    }
                                />
                            </p>
                        </div>
                        <div className="flex items-center gap-4 justify-end p-6 rounded-b">
                            <button
                                className="py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                type="button"
                                onClick={onClose}>
                                Cancel
                            </button>
                            <button
                                className="py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                type="button"
                                onClick={() => onInvite(inviteType)}>
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    'Send Invite'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default InviteModal;

InviteModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onInvite: PropTypes.func,
    inviteType: PropTypes.string,
    inviteEmail: PropTypes.string,
    setInviteEmail: PropTypes.func,
    loading: PropTypes.bool
};
