/**
 * Accepts an Date object and returns a string of the date in MM-DD-YYYY format.
 */

export default function formatDate(date) {
    if (!date) {
        return '';
    }
    const dateObj = new Date(date);
    const result = dateObj.toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
    });
    return result;
}
