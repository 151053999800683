import React, { useEffect } from 'react';
import formatDate from '../utils/formatDate';
import getAddonFromID from '../utils/getAddonFromID';
import getColorFromID from '../utils/getColorFromID';
import getNumericID from '../utils/getReadableID';
import getProudctNames from '../utils/getProductNames';
import getTierFromUnits from '../utils/getTierFromUnits';
import getUnitsFromStripe from '../utils/getUnitsFromStripe';
import PaymentsBadge from './payments-badge';
import PropTypes from 'prop-types';
import truncateString from '../utils/truncateString';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/user-context';

function PaymentCardUnpaid({
    order,
    addons,
    users,
    userUnits,
    unitsCache,
    setAddonsPaid
}) {
    const navigate = useNavigate();
    const [adminPaidModalOpen, setAdminPaidModalOpen] = React.useState(false);
    const [userTier, setUserTier] = React.useState(1);
    const { userGroup } = useUser();

    const getUserTier = () => {
        if (userGroup.includes('admin')) {
            users?.forEach(async user => {
                if (user.Username === order.UserID) {
                    for (const attribute of user.Attributes) {
                        if (attribute.Name === 'custom:stripeCustomerId') {
                            if (unitsCache.has(attribute.Value)) {
                                setUserTier(
                                    getTierFromUnits(
                                        unitsCache.get(attribute.Value)
                                    )
                                );
                            } else {
                                const units = await getUnitsFromStripe(
                                    attribute.Value
                                );
                                unitsCache.set(attribute.Value, units);
                                setUserTier(getTierFromUnits(units));
                            }
                        }
                    }
                }
            });
        } else {
            setUserTier(getTierFromUnits(userUnits));
        }
    };

    const getAllAddonsFromOrder = orderVal => {
        const addonsArr = [];
        orderVal.nodes.forEach(product => {
            product.addons?.forEach(addon => {
                addonsArr.push({ addon, product });
            });
        });
        return addonsArr;
    };

    const calculateAddonCost = () => {
        let cost = 0;

        getAllAddonsFromOrder(order).forEach(item => {
            const addon = getAddonFromID(item.addon.id, addons);
            const price = addon?.Price?.[`tier${userTier}`] || 0;
            cost += price * item.addon.quantity * item.product.QuantityReceived;
        });

        return cost;
    };

    const handlePayment = () => {
        const addonOrder = order.id;
        const addonCart = [];

        getAllAddonsFromOrder(order).forEach(item => {
            const addon = getAddonFromID(item.addon.id, addons);
            addonCart.push({
                AddonID: item.addon.id,
                Price: addon?.Price[`tier${userTier}`] * item.addon.quantity,
                QuantitySelected: item.product.QuantityReceived
            });
        });
        localStorage.setItem('addonCart', JSON.stringify(addonCart));
        localStorage.setItem('addonOrder', JSON.stringify(addonOrder));

        navigate('/checkoutAddons');
    };

    const AddonsList = () =>
        getAllAddonsFromOrder(order).map((item, index) => (
            <div
                key={item.addon.id}
                className={
                    index % 2 === 0
                        ? 'flex justify-between items-center bg-gray-200 py-2'
                        : 'flex justify-between items-center py-2'
                }>
                <div>
                    <span className="font-bold">
                        {item.addon.quantity > 1
                            ? `(${item.addon.quantity})`
                            : ''}
                    </span>
                    <span> {getAddonFromID(item.addon.id, addons)?.Name}</span>
                </div>
                <span className="ml-2 min-w-max">
                    {`${item.product.QuantityReceived} @ ${getAddonFromID(
                        item.addon.id,
                        addons
                    )?.Price?.[`tier${userTier}`]?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    })} ea`}
                </span>
            </div>
        ));

    useEffect(() => {
        getUserTier();
    }, [userGroup, userUnits]);

    return (
        <>
            <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                <hr
                    className="border-2 rounded-lg mx-[10%]"
                    style={{ borderColor: getColorFromID(order.id) }}
                />
                {/* Header with order number, date, and status badge */}
                <div className="flex justify-between items-center px-2 py-4 border-b border-gray-200">
                    <div>
                        <span className="text-sm text-gray-500">
                            {formatDate(order.CreatedAt)}
                        </span>
                    </div>
                    <div>
                        <span className="text-sm font-medium text-gray-900">
                            {`Order #${getNumericID(order.id)}`}
                        </span>
                    </div>

                    <div>
                        <PaymentsBadge
                            status={
                                order.AddonsPaid ? 'Order paid' : 'Payment due'
                            }
                        />
                    </div>
                </div>
                <div className="flex flex-col ml-2 mr-2">
                    <div>
                        <span className="text-sm mr-3">
                            <span className="font-bold">Distributor: </span>
                            {truncateString(order.Distributor, 21)}
                        </span>
                        <span className="text-sm">
                            <span className="font-bold">Store: </span>
                            {truncateString(order.StoreName, 21)}
                        </span>
                    </div>
                    <div>
                        <span className="text-sm">
                            <span className="font-bold">Products: </span>
                            {getProudctNames(order)}
                        </span>
                    </div>
                </div>
                <div className="bg-gray-100 m-2 space-y-2 rounded">
                    <span className="font-bold">ADD-ONS:</span>
                    <div className="flex flex-col">
                        <AddonsList />
                        <div className="text-center text font-medium">
                            <span>
                                {`Total: ${calculateAddonCost().toLocaleString(
                                    'en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD'
                                    }
                                )}`}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="px-1">
                    {!order.AddonsPaid && !userGroup.includes('admin') && (
                        <button
                            type="button"
                            onClick={() => {
                                handlePayment();
                            }}
                            className="w-full mx-auto h-[36px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            Pay
                        </button>
                    )}
                    {userGroup.includes('admin') && !order.AddonsPaid && (
                        <button
                            type="button"
                            onClick={() => {
                                setAdminPaidModalOpen(true);
                            }}
                            className="w-full mx-auto h-[36px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            Mark as Paid
                        </button>
                    )}
                </div>
                {adminPaidModalOpen && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div
                                className="fixed inset-0 transition-opacity"
                                aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span
                                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                                aria-hidden="true">
                                &#8203;
                            </span>
                            <div
                                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                                role="dialog"
                                aria-modal="true"
                                aria-labelledby="modal-headline">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3
                                                className="text-lg leading-6 font-medium text-gray-900"
                                                id="modal-headline">
                                                Mark Add-ons as Paid
                                            </h3>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to
                                                    mark the add-ons in this
                                                    order as paid? This cannot
                                                    be undone and a payment
                                                    receipt will not be made.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAddonsPaid(order);
                                            setAdminPaidModalOpen(false);
                                        }}
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 sm:ml-3 sm:w-auto sm:text-sm">
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAdminPaidModalOpen(false);
                                        }}
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

PaymentCardUnpaid.propTypes = {
    addons: PropTypes.array,
    order: PropTypes.object.isRequired,
    unitsCache: PropTypes.shape({
        get: PropTypes.func,
        has: PropTypes.func,
        set: PropTypes.func
    }),
    userUnits: PropTypes.number,
    users: PropTypes.array,
    setAddonsPaid: PropTypes.func
};

export default PaymentCardUnpaid;
