import { API, Auth, Storage } from 'aws-amplify';
import { IconCircleCheck, IconCircleX, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

function TaxApproval({
    pendingW9Users,
    setPendingW9Users,
    pendingResaleUsers,
    setPendingResaleUsers,
    setTaxApprovalModal,
    fileCount,
    setFileCount,
    setShowBanner
}) {
    const [loading, setLoading] = useState(false);
    const [usersS3, setUsersS3] = useState({});

    useEffect(() => {
        const getS3 = async key => {
            const s3 = await Storage.get(key);
            return s3;
        };
        const getLatestPendingDocs = async () => {
            setLoading(true);
            const list = await Storage.list('forms/');
            const newUsersS3 = {};
            pendingW9Users.forEach(user => {
                const tempObj = usersS3[user.Username]
                    ? usersS3[user.Username]
                    : {};
                const latestW9 = list.filter(
                    file =>
                        file.key.includes(user.Username) &&
                        file.key.includes('w9')
                );
                if (latestW9.length > 0) {
                    // remove all but the latest file
                    latestW9.reduce((a, b) =>
                        a.lastModified > b.lastModified ? a : b
                    );
                    getS3(latestW9[0].key)
                        .then(url => {
                            tempObj.w9_key = latestW9[0].key;
                            tempObj.w9_url = url;
                            newUsersS3[user.Username] = tempObj;
                            setUsersS3(newUsersS3);
                            setLoading(false);
                        })
                        .catch(err => {
                            console.log('err', err);
                            setLoading(false);
                        });
                }
            });
            pendingResaleUsers.forEach(user => {
                const tempObj = usersS3[user.Username]
                    ? usersS3[user.Username]
                    : {};
                usersS3[user.Username] = {};
                const latestResale = list.filter(
                    file =>
                        file.key.includes(user.Username) &&
                        file.key.includes('resale')
                );
                if (latestResale.length > 0) {
                    // sort by last modified
                    latestResale.reduce((a, b) =>
                        a.lastModified > b.lastModified ? a : b
                    );
                    getS3(latestResale[0].key)
                        .then(url => {
                            tempObj.resale_key = latestResale[0].key;
                            tempObj.resale_url = url;
                            // maybe not the best way to do this
                            if (newUsersS3[user.Username]) {
                                newUsersS3[user.Username] = {
                                    ...newUsersS3[user.Username],
                                    ...tempObj
                                };
                            } else {
                                newUsersS3[user.Username] = tempObj;
                            }
                            setUsersS3(newUsersS3);
                            setLoading(false);
                        })
                        .catch(err => {
                            console.log('err', err);
                            setLoading(false);
                        });
                }
            });
            setLoading(false);
        };
        getLatestPendingDocs();
    }, []);

    const deleteS3 = async key => {
        await Storage.remove(key);
    };

    const fileCountHandler = () => {
        if (fileCount - 1 === 0) {
            setTaxApprovalModal(false);
            setShowBanner(false);
        }
        setFileCount(fileCount - 1);
    };

    async function updateUserAttribute(username, attribute, value) {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                username: username,
                attribute: attribute,
                value: value
            }
        };
        API.patch('myamzprepfixapi', '/admin', requestInfo)
            .then(response => {
                console.log(response);
                toast.success('User updated successfully');
                fileCountHandler();
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                toast.error('Error updating user');
                setLoading(false);
            });
    }

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-1/3 my-6 mx-auto max-w-3xl">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/* header */}
                        <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <button
                                onClick={() => {
                                    setTaxApprovalModal(false);
                                }}>
                                <IconX className="float-right" />
                            </button>
                            <h3 className="text-xl font-semibold">
                                Review Client Documents
                            </h3>
                            <p className="text-gray-600 text-s"></p>
                        </div>
                        {/* body */}
                        <div className="px-6 relative flex-auto">
                            {loading ? (
                                <div className="flex justify-center items-center p-5 border-b border-solid border-slate-200 rounded-t">
                                    <div className="loading-spinner ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6 mb-4"></div>
                                </div>
                            ) : (
                                <>
                                    {usersS3 && pendingW9Users.length > 0 && (
                                        <>
                                            <div className="bg-gray-100 mx-[-1.5rem] h-14">
                                                <p className="text-gray-600 pt-[1rem] text-center text-lg leading-relaxed">
                                                    Pending W-9s
                                                </p>
                                            </div>
                                            {pendingW9Users.map(user => (
                                                <div
                                                    key={user.Username}
                                                    className="grid grid-cols-8 border-t-2 h-14 content-center">
                                                    <div className="flex flex-col col-span-3 gap-1 pl-4 justify-center place-content-center">
                                                        {
                                                            user.Attributes.find(
                                                                attr =>
                                                                    attr.Name ===
                                                                    'name'
                                                            ).Value
                                                        }
                                                    </div>
                                                    <div className="flex flex-col col-span-3 gap-1 pl-9 justify-center place-content-center text-lg hover:underline cursor-pointer text-blue-700">
                                                        {usersS3[
                                                            user.Username
                                                        ] && (
                                                            <a
                                                                href={
                                                                    usersS3[
                                                                        user
                                                                            .Username
                                                                    ].w9_url
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                View W-9
                                                            </a>
                                                        )}
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <button
                                                            onClick={() => {
                                                                updateUserAttribute(
                                                                    user.Username,
                                                                    'custom:validW9',
                                                                    'approved'
                                                                ).then(() => {
                                                                    setPendingW9Users(
                                                                        pendingW9Users.filter(
                                                                            u =>
                                                                                u.Username !==
                                                                                user.Username
                                                                        )
                                                                    );
                                                                });
                                                            }}
                                                            className="self-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                            <IconCircleCheck />
                                                        </button>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <button
                                                            onClick={() => {
                                                                updateUserAttribute(
                                                                    user.Username,
                                                                    'custom:validW9',
                                                                    'denied'
                                                                ).then(() => {
                                                                    deleteS3(
                                                                        usersS3[
                                                                            user
                                                                                .Username
                                                                        ].w9_key
                                                                    ).then(
                                                                        () => {
                                                                            setPendingW9Users(
                                                                                pendingW9Users.filter(
                                                                                    u =>
                                                                                        u.Username !==
                                                                                        user.Username
                                                                                )
                                                                            );
                                                                        }
                                                                    );
                                                                });
                                                            }}
                                                            className="self-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                            <IconCircleX />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {usersS3 &&
                                        pendingResaleUsers.length > 0 && (
                                            <>
                                                <div className="bg-gray-100 mx-[-1.5rem] h-14 text-center">
                                                    <p className="text-gray-600 pt-[1rem] text-lg leading-relaxed">
                                                        Pending Resale
                                                        Certificates
                                                    </p>
                                                </div>
                                                {pendingResaleUsers.map(
                                                    user => (
                                                        <div
                                                            key={user.Username}
                                                            className="grid grid-cols-8 border-t-2 h-14 content-center">
                                                            <div className="flex flex-col col-span-3 pl-4 gap-1 justify-center place-content-center">
                                                                {
                                                                    user.Attributes.find(
                                                                        attr =>
                                                                            attr.Name ===
                                                                            'name'
                                                                    ).Value
                                                                }
                                                            </div>
                                                            <div className="flex flex-col col-span-3 gap-1 pl-8 justify-center place-content-center text-lg hover:underline cursor-pointer text-blue-700">
                                                                {usersS3 &&
                                                                    usersS3[
                                                                        user
                                                                            .Username
                                                                    ] && (
                                                                        <a
                                                                            href={
                                                                                usersS3[
                                                                                    user
                                                                                        .Username
                                                                                ]
                                                                                    .resale_url
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">
                                                                            View
                                                                            Resale
                                                                        </a>
                                                                    )}
                                                            </div>
                                                            <div className="flex flex-col gap-1 col-span-1">
                                                                <button
                                                                    onClick={() =>
                                                                        updateUserAttribute(
                                                                            user.Username,
                                                                            'custom:validResale',
                                                                            'approved'
                                                                        ).then(
                                                                            () => {
                                                                                setPendingResaleUsers(
                                                                                    pendingResaleUsers.filter(
                                                                                        u =>
                                                                                            u.Username !==
                                                                                            user.Username
                                                                                    )
                                                                                );
                                                                            }
                                                                        )
                                                                    }
                                                                    className="self-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                                                                    <IconCircleCheck />
                                                                </button>
                                                            </div>
                                                            <div className="flex flex-col gap-1 col-span-1">
                                                                <button
                                                                    onClick={() => {
                                                                        updateUserAttribute(
                                                                            user.Username,
                                                                            'custom:validResale',
                                                                            'denied'
                                                                        ).then(
                                                                            () => {
                                                                                deleteS3(
                                                                                    usersS3[
                                                                                        user
                                                                                            .Username
                                                                                    ]
                                                                                        .resale_key
                                                                                ).then(
                                                                                    () => {
                                                                                        setPendingResaleUsers(
                                                                                            pendingResaleUsers.filter(
                                                                                                u =>
                                                                                                    u.Username !==
                                                                                                    user.Username
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                );
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="self-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                                                    <IconCircleX />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}
TaxApproval.propTypes = {
    pendingW9Users: PropTypes.array,
    setPendingW9Users: PropTypes.func,
    pendingResaleUsers: PropTypes.array,
    setPendingResaleUsers: PropTypes.func,
    taxApprovalModal: PropTypes.bool,
    setTaxApprovalModal: PropTypes.func,
    fileCount: PropTypes.number,
    setFileCount: PropTypes.func,
    setShowBanner: PropTypes.func
};

export default TaxApproval;
