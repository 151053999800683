import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

export function Container({ as, className, children }) {
    const Component = as ?? 'div';

    return (
        <Component
            className={clsx('mx-auto max-w-[100rem] px-6 lg:px-20', className)}>
            <div className="mx-auto max-w-2xl lg:max-w-none">{children}</div>
        </Component>
    );
}

Container.propTypes = {
    as: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.any
};
