/**
 * Finds store product in products array by ID
 */
export default function getStoreProductFromID(id, products) {
    if (!id || !products || products.length <= 0) {
        return null;
    }

    const productObj = products.find(product => product.ProductID === id);
    return productObj;
}
