import { API, Auth } from 'aws-amplify';
// Get a user's usage from their stripe customer id
export default async function getUsageFromStripeId(stripeId) {
    const amplifyUser = await Auth.currentAuthenticatedUser();
    const token = amplifyUser.signInUserSession.idToken.jwtToken;
    const requestInfo = {
        headers: {
            Authorization: token,
            Accept: 'application/json'
        }
    };
    const pathString = '/store/subscription/' + stripeId;
    const response = await API.get(
        'MyAMZPrepStoreAPI',
        pathString,
        requestInfo
    );

    if (response !== 'Hello from the store!') {
        return response.usageRecords.data[0].total_usage;
    } else {
        return 0;
    }
}
