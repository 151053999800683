import PropTypes from 'prop-types';
import React from 'react';

const getStyles = status => {
    switch (status) {
        case 'Client':
            return 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800';
        case 'Admin':
            return 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-700';
        default:
            return '';
    }
};

function UserBadge({ userType }) {
    return <div className={`${getStyles(userType)}`}>{userType}</div>;
}

UserBadge.propTypes = {
    userType: PropTypes.string.isRequired
};

export default UserBadge;
