import clsx from 'clsx';
import { Container } from '../homepage/Container';
import { FadeIn } from './FadeIn';
import PropTypes from 'prop-types';
import React from 'react';

export function Testimonial({ children, client, className }) {
    const fiveStars = '★★★★★';

    return (
        <div
            id="testimonial"
            className={clsx(
                'relative isolate bg-theme-color-400 py-16 sm:py-28 md:py-28',
                className
            )}>
            <Container>
                <figure className="mx-auto max-w-4xl">
                    <blockquote className="relative font-display text-3xl font-medium tracking-tight text-white sm:text-4xl">
                        <p className="before:content-['“'] after:content-['”'] sm:before:absolute sm:before:right-full italic">
                            {children}
                        </p>
                    </blockquote>
                    <FadeIn>
                        <figcaption className="text-2xl font-normal text-white">
                            <div className="text-yellow-400 text-4xl">
                                {fiveStars}
                            </div>
                            <div className="mt-6">{client.name}</div>
                        </figcaption>
                    </FadeIn>
                </figure>
            </Container>
        </div>
    );
}

Testimonial.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    client: PropTypes.shape({
        logo: PropTypes.any,
        name: PropTypes.any
    })
};
