import { API, Auth } from 'aws-amplify';
import { IconMessageDots, IconSend, IconX } from '@tabler/icons-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ChatBlob from './chat-blob';
import { customerContext } from '../contexts/customer-context';
import { NotificationContext } from '../contexts/notification-context';
import PropTypes from 'prop-types';
import { useUser } from '../contexts/user-context';

function ChatWidget({ isChatOpen, setChatOpen }) {
    const { customerID } = useContext(customerContext);
    const { chatNotificationCount, setChatNotificationCount } =
        useContext(NotificationContext);
    const { user, userGroup } = useUser();
    const [messages, setMessages] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const chatInput = useRef(null);

    const handleNotifications = messagesArr => {
        let tempCount = chatNotificationCount;

        // Sets notif date to current date
        if (!localStorage.getItem('lastChatDate')) {
            const tempDate = new Date().toISOString();
            localStorage.setItem('lastChatDate', tempDate);
        }
        messagesArr.forEach(message => {
            if (
                message.notifTime.localeCompare(
                    localStorage.getItem('lastChatDate')
                ) === 1
            ) {
                tempCount++;
            }
        });
        setChatNotificationCount(tempCount);
    };

    const getMessageData = async () => {
        console.log('Getting messages...');
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;

        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };

        const messagesArray = [];

        if (userGroup.includes('admin') && customerID !== '') {
            API.get('myamzprepfixapi', `/chats/${customerID}`, requestInfo)
                .then(res => {
                    console.log(res);
                    res.Items.forEach(item => {
                        messagesArray.push({
                            isAdmin: !item.Admin,
                            time: item.ChatDateTime,
                            message: item.Body
                        });
                    });
                    setMessages(
                        messagesArray
                            .filter(item => item.time !== 'LATEST')
                            .sort((a, b) => a.time.localeCompare(b.time))
                            .reverse()
                    );
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            API.get('myamzprepfixapi', '/chats', requestInfo)
                .then(res => {
                    console.log(res);
                    res.Items.forEach(item => {
                        messagesArray.push({
                            isAdmin: item.Admin,
                            time: item.ChatDateTime,
                            notifTime: item.Author,
                            message: item.Body
                        });
                    });
                    handleNotifications(
                        messagesArray.sort((a, b) =>
                            a.notifTime.localeCompare(b.notifTime)
                        )
                    );

                    setMessages(
                        messagesArray
                            .filter(item => item.time !== 'LATEST')
                            .sort((a, b) => a.time.localeCompare(b.time))
                            .reverse()
                    );
                    console.log('Got messages!');
                })
                .catch(err => {
                    console.error(err);
                });
        }
    };

    const startRefresh = () => {
        const interval = setInterval(getMessageData, 60000);
        setRefresh(interval);
    };

    const stopRefresh = () => {
        clearInterval(refresh);
    };

    useEffect(() => {
        if (!userGroup) {
            return;
        }

        getMessageData();
    }, [userGroup, chatInput, isChatOpen]);

    const sendChatMessage = async () => {
        if (chatInput.current.value === '') {
            return false;
        }
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;

        if (userGroup.includes('admin')) {
            if (customerID === false || customerID === '') {
                console.error('Customer ID must be provided.');
                return false;
            }
        }
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                UserID: customerID || '',
                chat: chatInput.current.value,
                StoreName: user.name
            }
        };

        API.post('myamzprepfixapi', '/chats', requestInfo)
            .then(res => {
                console.log(res);
                console.log('Message sent');
            })
            .catch(err => {
                console.error(err);
            });

        setMessages([
            {
                isAdmin: false,
                time: `${new Date()}`,
                message: chatInput.current.value
            },
            ...messages
        ]);
        chatInput.current.value = '';
    };

    const ChatInput = () => {
        const handleKeyDown = event => {
            if (event.key === 'Enter') {
                sendChatMessage();
            }
        };

        return (
            <input
                ref={chatInput}
                type="text"
                id="chat-input"
                name="chat-input"
                className="bg-gray-100 w-4/5 focus:outline-none px-2"
                onKeyDown={handleKeyDown}
            />
        );
    };

    return (
        <>
            {isChatOpen && (
                <div className="fixed right-2 sm:right-4 bottom-20 w-[90%] h-[85%] lg:w-[300px] lg:h-[380px] outline outline-1 outline-gray-200 bg-white rounded-lg z-40 shadow">
                    <div className="w-full h-10 bg-theme-color-400 rounded-t-lg">
                        <p className="text-white text-xl text-center py-1">
                            Chats
                        </p>
                    </div>
                    <div className="relative h-[85%] lg:h-[300px] flex flex-col-reverse overflow-auto">
                        {messages.map((message, index) => (
                            <ChatBlob
                                key={index}
                                message={message.message}
                                sent={!message.isAdmin}
                            />
                        ))}
                    </div>
                    <div className="absolute flex justify-around w-full bg-gray-100 rounded-b-lg py-2 bottom-0">
                        <ChatInput />
                        <button onClick={sendChatMessage}>
                            <IconSend />
                        </button>
                    </div>
                </div>
            )}
            <div className="fixed right-2 sm:right-4 bottom-4 z-40">
                <button
                    onClick={() => {
                        setChatOpen(!isChatOpen);
                        isChatOpen ? stopRefresh() : startRefresh();
                    }}
                    className="flex items-center p-4 transition ease-in duration-200 rounded-full bg-theme-color-400 hover:bg-theme-color-200 text-white">
                    {!isChatOpen && <IconMessageDots />}
                    {isChatOpen && <IconX />}
                </button>
            </div>
        </>
    );
}

ChatWidget.propTypes = {
    customerID: PropTypes.string,
    isChatOpen: PropTypes.bool.isRequired,
    messages: PropTypes.array,
    setChatOpen: PropTypes.func.isRequired
};

export default ChatWidget;
