import { API, Auth } from 'aws-amplify';
import { IconPlus, IconX } from '@tabler/icons-react';
import React, { useContext, useRef, useState } from 'react';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

export default function QuickAddForm({
    type,
    productID,
    handleClose,
    setRows,
    rows,
    setQuickAddForm,
    setSelectedRows
}) {
    const { order, products, setOrderInfo, setProducts } =
        useContext(orderContext);
    const quantityRef = useRef(null);
    const [pLoading, setPLoading] = useState(false);

    const handleCancel = () => {
        handleClose();
        setOrderInfo(false);
        setProducts([]);
    };

    const handleQuickAdd = async () => {
        setPLoading(true);
        const editedProduct = products.find(
            product => product.ProductID === productID
        );

        if (type === 'quantity') {
            editedProduct.Quantity =
                Number(editedProduct.Quantity) +
                Number(quantityRef.current.value);
        } else {
            editedProduct.QuantityReceived =
                Number(editedProduct.QuantityReceived) +
                Number(quantityRef.current.value);
        }

        const tempProducts = products.filter(
            product => product.ProductID !== productID
        );
        tempProducts.push(editedProduct);
        setProducts(tempProducts);

        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;

        const updateOrderReq = order;
        delete updateOrderReq.ancestors;
        delete updateOrderReq.parentNode;
        delete updateOrderReq.treeXLevel;
        delete updateOrderReq.treeYLevel;

        updateOrderReq.nodes = tempProducts;

        setSelectedRows([updateOrderReq]);

        const updateRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                order: updateOrderReq
            }
        };

        API.patch('myamzprepfixapi', '/orders', updateRequestInfo)
            .then(res => {
                toast('Order updated successfully!', {
                    type: 'success',
                    position: 'top-right'
                });

                const tempArr = rows.filter(
                    row => row.OrderID !== order.OrderID
                );
                setRows([updateOrderReq, ...tempArr]);

                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setOrderInfo(false);
                setProducts([]);
                setQuickAddForm(false);
                setPLoading(false);
            });
    };
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto">
                    {/* content */}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none min-w-[316px]">
                        {/* header */}
                        <div className="flex items-center px-5 py-3 border-b border-solid border-slate-200 rounded-t ">
                            <h3 className="text-xl font-semibold">Add units</h3>
                            <button
                                onClick={handleClose}
                                className="absolute top-4 right-2 cursor-pointer">
                                <IconX />
                            </button>
                        </div>
                        {/* body */}
                        <div className="px-4 py-4 w-full">
                            <input
                                type="number"
                                id="quantity"
                                name="quantity"
                                ref={quantityRef}
                                required
                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="(#) Quantity"
                            />
                        </div>
                        {/* footer */}
                        <div className="flex items-center mb-4">
                            <button
                                type="button"
                                onClick={handleQuickAdd}
                                className="w-full lg:w-32 h-[42px] mx-auto pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                {pLoading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    <>
                                        <IconPlus className="-ml-1 mr-1" />
                                        Add
                                    </>
                                )}
                            </button>
                            <button
                                onClick={handleCancel}
                                type="button"
                                className="py-2 px-4 lg:w-32 h-[42px] mx-auto flex justify-center items-center bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center
                                                    text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

QuickAddForm.propTypes = {
    handleClose: PropTypes.func.isRequired,
    productID: PropTypes.string.isRequired,
    rows: PropTypes.shape({
        filter: PropTypes.func
    }),
    setQuickAddForm: PropTypes.func,
    setRows: PropTypes.func,
    setSelectedRows: PropTypes.func,
    type: PropTypes.string.isRequired
};
