import {
    IconArrowBarLeft,
    IconArrowBarRight,
    IconBasket,
    IconBuildingStore,
    IconLogout,
    IconMessageCircle,
    IconPackage,
    IconUser,
    IconUsers,
    IconWallet
} from '@tabler/icons-react';
import React, { useContext, useEffect } from 'react';
import Logo from '../images/logo-nobg.png';
import { NavLink } from 'react-router-dom';
import { NotificationContext } from '../contexts/notification-context';
import PropTypes from 'prop-types';
import { useUser } from '../contexts/user-context';

const activeStyle =
    'hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors  duration-200  text-gray-800 rounded-lg bg-gray-100';
const inactiveStyle =
    'hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors  duration-200  text-gray-800 rounded-lg';

function SideNav({ signOut }) {
    const { chatNotificationCount, storeNotificationCount } =
        useContext(NotificationContext);
    const { userGroup } = useUser();
    const [isNavHidden, setIsNavHidden] = React.useState(false);

    const handleOpenMenu = () => {
        setIsNavHidden(false);
        localStorage.setItem('menuPreference', 'open');
    };

    const handleCloseMenu = () => {
        setIsNavHidden(true);
        localStorage.setItem('menuPreference', 'closed');
    };

    useEffect(() => {
        if (!localStorage.getItem('menuPreference')) {
            localStorage.setItem('menuPreference', 'open');
        }
        setIsNavHidden(localStorage.getItem('menuPreference') !== 'open');
    }, []);

    return (
        <>
            {!isNavHidden && (
                <div className="relative w-72 bg-white z-30">
                    <div className="relative w-72">
                        <div className="fixed w-72 bg-white flex flex-col sm:flex-row sm:justify-around-">
                            <span className="absolute top-4 right-3">
                                <button
                                    onClick={() => {
                                        handleCloseMenu();
                                    }}>
                                    <IconArrowBarLeft />
                                </button>
                            </span>
                            <div className="w-72 h-screen">
                                <div className="flex items-center justify-start mx-6 mt-10">
                                    <a
                                        href="/"
                                        aria-label="Company"
                                        title="Company"
                                        className="inline-flex items-center">
                                        <img src={Logo} alt="logo" />
                                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                                            My AMZ Prep
                                        </span>
                                    </a>
                                </div>
                                <nav className="mt-10 px-6 ">
                                    <NavLink
                                        to="/orders"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <IconPackage stroke={2} />
                                        <span className="mx-4 text-lg font-normal">
                                            Orders
                                        </span>
                                        <span className="flex-grow text-right"></span>
                                    </NavLink>
                                    <NavLink
                                        to="/leftovers"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <IconBasket stroke={2} />
                                        <span className="mx-4 text-lg font-normal">
                                            Leftovers
                                        </span>
                                        <span className="flex-grow text-right"></span>
                                    </NavLink>
                                    {userGroup &&
                                        userGroup.includes('superadmin') && (
                                            <NavLink
                                                to="/users"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <IconUsers />
                                                <span className="mx-4 text-lg font-normal">
                                                    Users
                                                </span>
                                                <span className="flex-grow text-right"></span>
                                            </NavLink>
                                        )}
                                    {userGroup &&
                                        userGroup.includes('admin') && (
                                            <NavLink
                                                to="/chats"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <IconMessageCircle />
                                                <span className="mx-4 text-lg font-normal">
                                                    Chat
                                                </span>
                                                <span className="flex-grow text-right">
                                                    {chatNotificationCount >
                                                        0 && (
                                                        <button
                                                            type="button"
                                                            className="w-6 h-6 text-xs  rounded-full text-white bg-red-500">
                                                            <span className="p-1">
                                                                {
                                                                    chatNotificationCount
                                                                }
                                                            </span>
                                                        </button>
                                                    )}
                                                </span>
                                            </NavLink>
                                        )}
                                    {userGroup &&
                                        (userGroup.includes('superadmin') ||
                                            userGroup.includes('client')) && (
                                            <NavLink
                                                to="/payments"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <IconWallet />
                                                <span className="mx-4 text-lg font-normal">
                                                    Payments
                                                </span>
                                                <span className="flex-grow text-right"></span>
                                            </NavLink>
                                        )}
                                    {userGroup &&
                                        (userGroup.includes('superadmin') ||
                                            userGroup.includes('client')) && (
                                            <NavLink
                                                to="/store"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <IconBuildingStore />
                                                <span className="mx-4 text-lg font-normal">
                                                    Store
                                                </span>
                                                <span className="flex-grow text-right">
                                                    {storeNotificationCount >
                                                        0 && (
                                                        <button
                                                            type="button"
                                                            className="w-6 h-6 text-xs  rounded-full text-white bg-red-500">
                                                            <span className="p-1">
                                                                {
                                                                    storeNotificationCount
                                                                }
                                                            </span>
                                                        </button>
                                                    )}
                                                </span>
                                            </NavLink>
                                        )}
                                    <NavLink
                                        to="/account"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <IconUser />
                                        <span className="mx-4 text-lg font-normal">
                                            Account
                                        </span>
                                        <span className="flex-grow text-right"></span>
                                    </NavLink>
                                </nav>
                                <div
                                    className="absolute bottom-0 left-10 my-10 cursor-pointer"
                                    onClick={signOut}>
                                    <a className="text-gray-600  hover:text-gray-800  transition-colors duration-200 flex items-center py-2">
                                        <IconLogout />
                                        <span className="mx-3 font-medium">
                                            Sign out
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isNavHidden && (
                <div className="relative w-[90px] z-30">
                    <div className="fixed w-[90px] bg-white">
                        <div className="flex flex-col sm:flex-row sm:justify-around">
                            <span className="absolute top-4 right-3">
                                <button
                                    onClick={() => {
                                        handleOpenMenu();
                                    }}>
                                    <span className="pt-1">
                                        <IconArrowBarRight />
                                    </span>
                                </button>
                            </span>
                            <div className="w-[90px] h-screen">
                                <div className="flex items-center justify-start mt-[41px]">
                                    <a
                                        href="/"
                                        aria-label="Company"
                                        title="Company"
                                        className="inline-flex items-center">
                                        <img src={Logo} alt="logo" />
                                    </a>
                                </div>
                                <nav className="mt-10 px-6 ">
                                    <NavLink
                                        to="/orders"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <span className="pt-1">
                                            <IconPackage stroke={2} />
                                        </span>
                                    </NavLink>
                                    <NavLink
                                        to="/leftovers"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <span className="pt-1">
                                            <IconBasket stroke={2} />
                                        </span>
                                    </NavLink>
                                    {userGroup &&
                                        userGroup.includes('admin') && (
                                            <NavLink
                                                to="/users"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <span className="pt-1">
                                                    <IconUsers />
                                                </span>
                                            </NavLink>
                                        )}
                                    {userGroup &&
                                        userGroup.includes('admin') && (
                                            <NavLink
                                                to="/chats"
                                                end
                                                className={({ isActive }) =>
                                                    isActive
                                                        ? activeStyle
                                                        : inactiveStyle
                                                }>
                                                <span className="pt-1">
                                                    <IconMessageCircle />
                                                </span>

                                                <span className="absolute ml-4 mb-6">
                                                    {chatNotificationCount >
                                                        0 && (
                                                        <button
                                                            type="button"
                                                            className="w-6 h-6 text-xs  rounded-full text-white bg-red-500">
                                                            <span className="p-1">
                                                                {
                                                                    chatNotificationCount
                                                                }
                                                            </span>
                                                        </button>
                                                    )}
                                                </span>
                                            </NavLink>
                                        )}
                                    <NavLink
                                        to="/payments"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <IconWallet />
                                        <span className="flex-grow text-right"></span>
                                    </NavLink>
                                    <NavLink
                                        to="/store"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <IconBuildingStore />
                                        <span className="flex-grow text-right"></span>
                                    </NavLink>
                                    <NavLink
                                        to="/account"
                                        end
                                        className={({ isActive }) =>
                                            isActive
                                                ? activeStyle
                                                : inactiveStyle
                                        }>
                                        <span className="pt-1">
                                            <IconUser />
                                        </span>
                                    </NavLink>
                                    <div className="absolute bottom-0 left-9 my-10 cursor-pointer">
                                        <a className="text-gray-600  hover:text-gray-800  transition-colors duration-200 flex items-center py-2">
                                            <IconLogout />
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

SideNav.propTypes = {
    signOut: PropTypes.func
};

export default SideNav;
