import { read, utils } from 'xlsx';
import { toast } from 'react-toastify';

export default function importOrdersExcel(file, setProducts) {
    const reader = new FileReader();
    reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const importData = utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
        );

        const columnNameMap = {
            Name: 'ProductName',
            Asin: 'ASIN',
            Price: 'COG$',
            Quantity: 'Quantity',
            Multipack: 'MultiPack',
            QuantityReceived: 'QuantityReceived'
        };

        const newProducts = [];

        let error = false;
        let printedError = false;

        // Iterate through each imported row
        for (const row of importData) {
            // Ensure that the required properties are present
            if (!('Name' in row) && !('Asin' in row)) {
                // Skip the row if name and asin are not provided
                console.error('Skipped row:', row);
                if (error === false) {
                    toast.error('Skipped row with missing name and asin');
                }
                error = true;
                continue;
            }
            // Make sure name and asin are strings
            row.Name ? (row.Name = row.Name.toString()) : null;
            row.Asin ? (row.Asin = row.Asin.toString()) : null;

            // If no quantity is provided, set it to 1
            if (!row.Quantity) {
                row.Quantity = 1;
            }

            // Create a product object with the required properties
            const newProduct = {};
            for (const columnName in columnNameMap) {
                if (columnName in row) {
                    newProduct[columnNameMap[columnName]] = row[columnName];
                }
            }

            console.log('New product:', newProduct);

            if (!newProduct.ASIN) {
                // Skip the row if asin is not provided
                console.error('Skipped row:', row);
                if (printedError === false) {
                    toast.error('Skipped row with missing asin');
                    printedError = true;
                }

                error = true;
            }
            if (!newProduct.ProductName) {
                // Skip the row if name is not provided
                console.error('Skipped row:', row);
                if (printedError === false) {
                    toast.error('Skipped row with missing name');
                    printedError = true;
                }

                error = true;
            }

            // If no error, add the new product to the array
            if (!error) {
                newProducts.push(newProduct);
            }
            error = false;
        }

        // Set the products and finish importing
        setProducts(newProducts);
    };

    reader.readAsArrayBuffer(file);
}
