import { utils } from 'xlsx';

export default function createExcelTemplate() {
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet([
        ['Name', 'Asin', 'Price', 'Quantity', 'Multipack']
    ]);
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const csv = utils.sheet_to_csv(worksheet);
    return csv;
}
