import LoadingImage from './../../images/loading-image.jpg';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// create a loading skeleton of the store
const StoreSkeleton = () => (
    <>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
        <div className="group relative">
            <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                <img
                    src={LoadingImage}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                />
            </div>
            <div className="mt-4 w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                <h3 className="w-full text-lg font-medium text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
                <h3 className="w-full text-m text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0" />
                    <Skeleton />
                </h3>
            </div>
        </div>
    </>
);
export default StoreSkeleton;
