import { API, Auth, Storage } from 'aws-amplify';
import {
    IconAlertTriangle,
    IconBook,
    IconPlus,
    IconReceipt,
    IconShoppingCart,
    IconTruckOff
} from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import Banner from '../components/banner';
import config from '../aws-exports';
import NoImage from '../images/no-image.jpg';
import { NotificationContext } from '../contexts/notification-context';
import ProductForm from '../components/product-form';
import ProductModal from '../components/product-modal';
import StoreReceipts from '../components/store-receipts';
import StoreSkeleton from '../components/skeletons/store-skeleton';
import { toast } from 'react-toastify';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;

function Store() {
    const [cartNotificationCount, setCartNotificationCount] = useState(0);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resaleLoading, setResaleLoading] = useState(false);
    const [resaleError, setResaleError] = useState(false);
    const [resaleFile, setResaleFile] = useState('');
    const [products, setProducts] = useState([]);
    const [showBanner, setShowBanner] = useState(false);
    const [showReceipts, setShowReceipts] = useState(false);
    const [validResale, setValidResale] = useState(null);
    const [userGroup, setUserGroup] = useState('');
    const [userObj, setUserObj] = useState(null);
    const [newProductForm, setNewProductForm] = useState(false);
    const [viewProduct, setViewProduct] = useState(false);
    const { setStoreNotificationCount } = useContext(NotificationContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            setUserObj(user);
            const group =
                user.signInUserSession.accessToken.payload['cognito:groups'][0];
            setUserGroup(group);
            const resale = user.attributes['custom:validResale'];
            // const expireDateResale = user.attributes['custom:expireDateResale'];
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json'
                }
            };
            setValidResale(resale ? resale : '');
            if (group.includes('admin') || (resale && resale === 'approved')) {
                API.get('MyAMZPrepStoreAPI', '/store/products', requestInfo)
                    .then(response => {
                        console.log(response);
                        // store notifications
                        const sortedResponse = response.Items.sort((a, b) =>
                            a.CreatedAt.localeCompare(b.CreatedAt)
                        ).reverse();
                        localStorage.setItem(
                            'lastStoreDate',
                            sortedResponse[0].CreatedAt
                        );
                        setStoreNotificationCount(0);
                        setValidResale('approved');
                        setLoading(false);
                        // filter out archived products
                        const filteredResponse = sortedResponse.filter(
                            item => item.isArchived === false
                        );
                        setProducts(filteredResponse);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.log(error);
                    });
            } else {
                setValidResale(resale);
                if (resale === 'denied') {
                    setShowBanner(true);
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [userGroup]);

    async function updateUserValidResale() {
        try {
            await Auth.updateUserAttributes(userObj, {
                'custom:validResale': 'pending'
            });
        } catch (error) {
            console.log('error updating user:', error);
        }
        setLoading(false);
    }

    const handleSubmit = e => {
        setResaleLoading(true);
        e.preventDefault();

        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1;
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const hour = dateObj.getHours();
        const minute = dateObj.getMinutes();
        const newDate =
            month + '-' + day + '-' + year + '_' + hour + '-' + minute;

        Storage.put(
            `forms/${userObj.username}/resale_${newDate}.pdf`,
            resaleFile,
            {
                contentType: 'application/pdf'
            }
        )
            .then(result => {
                console.log(result);
                setShowBanner(false);
                updateUserValidResale();
                setValidResale('pending');
                setResaleLoading(false);
            })
            .catch(err => {
                console.log(err);
                toast.error('File upload failed.', {
                    position: 'top-right',
                    type: 'error'
                });
            });
    };

    const resaleHandler = e => {
        setResaleError(false);
        if (
            e.target.files[0] &&
            e.target.files[0].type === 'application/pdf' &&
            e.target.files[0].size < 4000000
        ) {
            setResaleFile(e.target.files[0]);
        } else {
            setResaleError(true);
        }
    };

    return (
        <>
            {viewProduct && (
                <>
                    <div className="justify-center w-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full lg:w-4/5 my-6 mx-auto max-w-[1000px]">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="relative p-6 flex-auto">
                                    <ProductModal
                                        setCartNotificationCount={
                                            setCartNotificationCount
                                        }
                                        setViewProduct={setViewProduct}
                                        product={currentProduct}
                                        setCurrentProduct={setCurrentProduct}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}

            {userGroup && userGroup.includes('admin') && newProductForm && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        {currentProduct
                                            ? 'Update Product'
                                            : 'New Product'}
                                    </h3>
                                </div>
                                {/* body */}
                                <div className="relative p-6 flex-auto">
                                    <ProductForm
                                        product={currentProduct}
                                        products={products}
                                        setProducts={setProducts}
                                        setNewProductForm={setNewProductForm}
                                        setCurrentProduct={setCurrentProduct}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}

            {validResale !== 'approved' &&
            userGroup &&
            userGroup.includes('client') &&
            !loading ? (
                <div className="px-4 lg:px-0">
                    {showBanner && (
                        <Banner
                            type="error"
                            text="Your resale certificate has been denied or is expired. Please upload your latest document for review. Contact us at cristian@myamzprep.com for more information."
                            setShowBanner={setShowBanner}
                            icon={<IconAlertTriangle color={'white'} />}
                        />
                    )}
                    {validResale === 'pending' ? (
                        <div className="grid place-items-center h-screen">
                            <div className=" container bg-white shadow-md lg:w-[30vw] lg:max-w-[500px] md:w-[80vw] md:min-w-[500px] p-[40px] align-middle rounded-md mx-auto">
                                <div className="flex flex-col items-center">
                                    <IconBook
                                        color={'#1965DB'}
                                        strokeWidth={1.5}
                                        size={64}
                                    />
                                    <h1 className="text-2xl font-bold text-center mb-6 mt-2">
                                        Review in Progress
                                    </h1>
                                    <p className="text-center">
                                        Your tax documents are currently under
                                        review. You will be notified when your
                                        account is approved. If you have any
                                        questions, please contact us at{' '}
                                        <a
                                            href="mailto:cristian@myamzprep.com"
                                            className="text-blue-500 underline">
                                            cristian@myamzprep.com
                                        </a>
                                    </p>
                                    <a
                                        href="mailto:
                                "
                                        className="text-blue-500"></a>
                                    <p className="text-center">
                                        for more information.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="grid place-items-center h-screen">
                            <form
                                onSubmit={handleSubmit}
                                className="container bg-white shadow-md lg:w-[30vw] lg:max-w-[500px] md:w-[80vw] md:min-w-[500px] p-[40px] align-middle rounded-md mx-auto">
                                <h1 className="text-2xl font-bold text-center mb-6">
                                    Resale Certificate Upload
                                </h1>
                                <div className="flex flex-col">
                                    <div className="text-gray-500 mb-4">
                                        Your state resale certificate is
                                        required, this document will be reviewed
                                        by the MyAMZPrep team before you are
                                        able to begin using the store. If you
                                        have any questions, contact us at{' '}
                                        <a
                                            href="mailto:cristian@myamzprep.com"
                                            className="text-blue-500 underline">
                                            cristian@myamzprep.com
                                        </a>
                                        .
                                    </div>
                                    <label
                                        className="font-semibold pl-2 mb-2"
                                        htmlFor="taxes">
                                        Resale Certificate
                                    </label>
                                    <input
                                        className="border border-gray-300 rounded-md p-2 mb-4"
                                        type="file"
                                        name="taxes"
                                        id="taxes"
                                        required
                                        onChange={resaleHandler}
                                    />
                                    {resaleError ? (
                                        <>
                                            <p className="text-center text-red-500">
                                                File must be a .pdf and smaller
                                                than 4MB
                                            </p>
                                            <button
                                                type="submit"
                                                disabled
                                                className="bg-gray-400 text-white rounded-md p-2 my-2">
                                                Submit tax info
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="p-2 my-2 bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-200 focus:ring-blue-500 text-white rounded-md">
                                            {resaleLoading ? (
                                                <div className="flex items-center justify-center">
                                                    <div className="loading-spinner"></div>
                                                </div>
                                            ) : (
                                                'Submit tax info'
                                            )}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-full px-4 lg:px-0">
                    {!showReceipts && (
                        <>
                            <div className="flex flex-col justify-between items-start lg:items-center lg:flex-row mt-4 lg:mt-10 mb-6 lg:ml-9 lg:mr-8">
                                <div className="flex flex-col">
                                    <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                                        Store
                                    </h1>
                                    <p className="mt-1.5 text-base sm:text-sm text-gray-500">
                                        Buy products to be added to your orders.
                                    </p>
                                </div>
                                <div className="flex flex-col lg:flex-row w-full lg:w-auto">
                                    <button
                                        type="button"
                                        onClick={() => setShowReceipts(true)}
                                        className="mt-2 lg:mt-0 lg:ml-8 w-full lg:w-40 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-200 focus:ring-theme-color-100 focus:ring-offset-theme-color-100
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                        <IconReceipt className="mr-2" />
                                        View receipts
                                    </button>
                                    {userGroup &&
                                        userGroup.includes('admin') && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setNewProductForm(true);
                                                }}
                                                className="mt-2 lg:mt-0 lg:ml-8 w-full lg:w-40 h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                                <IconPlus className="mr-2" />
                                                Add product
                                            </button>
                                        )}
                                </div>
                            </div>
                            {userGroup &&
                                userGroup.includes('client') &&
                                products &&
                                products.length > 0 && (
                                    <div className="fixed right-4 lg:top-4 md:top-4 sm:top-4 z-40">
                                        <a href="/store/cart">
                                            <button className="flex items-center p-4 transition ease-in duration-200 rounded-full bg-green-500 hover:bg-green-600 text-white">
                                                <IconShoppingCart />
                                            </button>
                                            {cartNotificationCount > 0 && (
                                                <div className="inline-flex absolute -top-2 -right-2 justify-center items-center w-7 h-7 text-xs font-bold text-white bg-red-500 rounded-full">
                                                    {cartNotificationCount}
                                                </div>
                                            )}
                                        </a>
                                    </div>
                                )}
                            <div className="w-full mx-auto py-0 px-4 lg:py-0 md:py-0 sm:py-16 sm:px-6 lg:px-8">
                                {(products && products.length > 0) ||
                                loading ? (
                                    <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                        {loading && <StoreSkeleton />}
                                        {products.map(product => (
                                            <div
                                                key={product.ProductID}
                                                className="group relative cursor-pointer"
                                                onClick={() => {
                                                    if (
                                                        userGroup &&
                                                        userGroup.includes(
                                                            'admin'
                                                        )
                                                    ) {
                                                        setCurrentProduct(
                                                            product
                                                        );
                                                        setNewProductForm(true);
                                                    } else {
                                                        setViewProduct(true);
                                                        setCurrentProduct(
                                                            product
                                                        );
                                                    }
                                                }}>
                                                <div className="w-full relative h-80 aspect-none bg-white rounded-md overflow-hidden group-hover:opacity-75">
                                                    <img
                                                        src={
                                                            product.ImageURL
                                                                ? product.ImageURL
                                                                : product.ImageS3
                                                                ? `https://${bucket}.s3.${region}.amazonaws.com/public/${product.ImageS3}`
                                                                : NoImage
                                                        }
                                                        alt={
                                                            product.ProductName
                                                        }
                                                        className="w-full h-full object-center object-contain"
                                                    />
                                                    <span className="max-w-fit h-8 lg:w-auto px-2 py-1 absolute bottom-0 left-0 text-base rounded-md bg-theme-color-400 text-white">
                                                        {/*  */}
                                                        {product &&
                                                            product.Price.toLocaleString(
                                                                'en-US',
                                                                {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'USD'
                                                                }
                                                            )}
                                                    </span>
                                                </div>
                                                <div className="mt-4 flex justify-between">
                                                    <div>
                                                        <h3 className="text-xl font-medium text-gray-700">
                                                            <span
                                                                aria-hidden="true"
                                                                className="absolute inset-0"
                                                            />
                                                            {
                                                                product.ProductName
                                                            }
                                                        </h3>
                                                        <h3 className="text-lg text-gray-700">
                                                            <span
                                                                aria-hidden="true"
                                                                className="absolute inset-0"
                                                            />
                                                            {product.Quantity.toLocaleString(
                                                                {
                                                                    maximumFractionDigits: 2
                                                                }
                                                            ).replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ','
                                                            ) + ' available'}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mt-[10%] min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                                        <div className="max-w-max mx-auto">
                                            <main className="sm:flex">
                                                <IconTruckOff
                                                    size={70}
                                                    color="#1965db"
                                                />
                                                <div className="sm:ml-6">
                                                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                                                        <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                                            No products
                                                            available
                                                        </h1>
                                                        <p className="mt-2 text-base text-gray-500">
                                                            {userGroup &&
                                                            userGroup.includes(
                                                                'admin'
                                                            )
                                                                ? 'Add a product to get started'
                                                                : 'Check back later'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {showReceipts && (
                        <StoreReceipts setShowReceipts={setShowReceipts} />
                    )}
                </div>
            )}
        </>
    );
}

export default Store;
