import { IconShoppingCart, IconX } from '@tabler/icons-react';
import React, { useRef, useState } from 'react';
import config from '../aws-exports';
import NoImage from '../images/no-image.jpg';
import PropTypes from 'prop-types';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;
export default function ProductModal({
    setCartNotificationCount,
    product,
    setCurrentProduct,
    setViewProduct
}) {
    const numberRef = useRef(null);
    const [productCount, setProductCount] = useState(1);
    const incrementInput = () => {
        if (
            numberRef.current.value &&
            numberRef.current.value < product.Quantity
        ) {
            numberRef.current.value = Number(numberRef.current.value) + 1;
        } else {
            numberRef.current.value = 1;
        }
        setProductCount(numberRef.current.value);
    };
    const decrementInput = () => {
        if (numberRef.current.value > 1) {
            numberRef.current.value = Number(numberRef.current.value) - 1;
        } else {
            numberRef.current.value = 1;
        }
        setProductCount(numberRef.current.value);
    };

    const handleSubmit = () => {
        const cart = JSON.parse(localStorage.getItem('cart'));

        let exists = false;
        if (cart) {
            cart.forEach(item => {
                if (item.ProductID === product.ProductID) {
                    item.QuantitySelected =
                        Number(numberRef.current.value) +
                        Number(item.QuantitySelected);
                    console.log(item.QuantitySelected);
                    exists = true;
                }
            });
            if (!exists) {
                cart.push({
                    ASIN: product.ASIN,
                    ProductID: product.ProductID,
                    ProductName: product.ProductName,
                    QuantityAvailable: product.Quantity,
                    QuantitySelected: Number(numberRef.current.value),
                    Price: product.Price,
                    ImageS3: product.ImageS3
                });
            }
            localStorage.setItem('cart', JSON.stringify(cart));
        } else {
            localStorage.setItem(
                'cart',
                JSON.stringify([
                    {
                        ASIN: product.ASIN,
                        ProductID: product.ProductID,
                        ProductName: product.ProductName,
                        QuantityAvailable: product.Quantity,
                        QuantitySelected: Number(numberRef.current.value),
                        Price: product.Price,
                        ImageS3: product.ImageS3
                    }
                ])
            );
        }
        if (cart) {
            setCartNotificationCount(cart.length);
        } else {
            setCartNotificationCount(1);
        }
        setViewProduct(false);
    };

    return (
        <>
            <div className="relative max-w-screen-xl px-4 py-10 mx-auto">
                <div
                    className="absolute right-0 -top-2 cursor-pointer"
                    onClick={() => {
                        setViewProduct(false);
                        setCurrentProduct(null);
                    }}>
                    <IconX />
                </div>
                <div className="relative grid items-start grid-cols-1 gap-8 md:grid-cols-2">
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
                        <div className="aspect-w-1 aspect-h-1">
                            <img
                                src={
                                    product.ImageURL
                                        ? product.ImageURL
                                        : product.ImageS3
                                        ? `https://${bucket}.s3.${region}.amazonaws.com/public/${product.ImageS3}`
                                        : NoImage
                                }
                                className="w-full h-full object-center object-contain"
                            />
                        </div>
                    </div>

                    <div className="sticky top-0 h-full mx-6">
                        <div className="flex justify-between">
                            <div className="max-w-[35ch]">
                                <h1 className="text-xl font-bold">
                                    {product.ProductName}
                                </h1>
                            </div>
                            <a href={`https://amazon.com/dp/${product.ASIN}`}>
                                <span className="text-lg underline hover:decoration-2 underline-offset-1">
                                    {product.ASIN}
                                </span>
                            </a>
                        </div>
                        <div className="relative mt-4">
                            <div className="prose max-w-none text-xl group-open:hidden">
                                <p>
                                    {'$' +
                                        product.Price.toLocaleString({
                                            maximumFractionDigits: 2
                                        })}
                                </p>
                            </div>
                        </div>
                        <div className="relative mt-4">
                            <div className="prose max-w-none group-open:hidden">
                                <p>
                                    {product.Description ||
                                        'No description available.'}
                                </p>
                            </div>
                        </div>
                        <div className="absolute bottom-0">
                            <form className="mt-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="custom-number-input col-span-1 w-40 text-lg flex items-center">
                                        <div className="flex col-span-1 flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                            <button
                                                data-action="decrement"
                                                type="button"
                                                className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    decrementInput();
                                                }}>
                                                <span className="m-auto text-2xl font-thin">
                                                    −
                                                </span>
                                            </button>
                                            <input
                                                type="number"
                                                ref={numberRef}
                                                className="hide-num-arrows-chrome hide-num-arrows-moz outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                name="custom-input-number"
                                                min={1}
                                                max={product.Quantity}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    if (e.target.value < 1) {
                                                        e.target.value = 1;
                                                    }
                                                    if (
                                                        e.target.value >
                                                        product.Quantity
                                                    ) {
                                                        e.target.value =
                                                            product.Quantity;
                                                    }
                                                    setProductCount(
                                                        e.target.value
                                                    );
                                                }}
                                                defaultValue={1}></input>
                                            <button
                                                data-action="increment"
                                                type="button"
                                                className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
                                                onClick={incrementInput}>
                                                <span className="m-auto text-2xl font-thin">
                                                    +
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-span-1 font-semibold text-gray-500 text-lg self-center">
                                        {product.Quantity.toLocaleString({
                                            maximumFractionDigits: 2
                                        }) + ' available'}
                                    </div>
                                    <button
                                        className="w-40 col-span-1 inline-flex gap-2 cursor-pointer items-center justify-center h-12 px-4 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200"
                                        aria-label="Add to cart"
                                        title="Add to cart"
                                        type="button"
                                        onClick={handleSubmit}>
                                        <IconShoppingCart />
                                        Add to cart
                                    </button>
                                    <div className="col-span-1 font-semibold text-gray-500 text-lg self-center">
                                        $
                                        {(productCount * product.Price)
                                            .toFixed(2)
                                            .toLocaleString({
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}{' '}
                                        total
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

ProductModal.propTypes = {
    product: PropTypes.shape({
        ProductID: PropTypes.string,
        ASIN: PropTypes.string,
        Description: PropTypes.string,
        ImageS3: PropTypes.string,
        ImageURL: PropTypes.string,
        ProductName: PropTypes.string,
        Price: PropTypes.any,
        Quantity: PropTypes.any
    }),
    setCartNotificationCount: PropTypes.func,
    setCurrentProduct: PropTypes.func,
    setViewProduct: PropTypes.func,
    viewProduct: PropTypes.bool
};
