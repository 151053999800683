/* eslint-disable max-len */
import { FadeIn, FadeInStagger } from './FadeIn';
import React, { useId } from 'react';
import clsx from 'clsx';
import { Container } from './Container';
import imageWarehouseMachine from '../../images/homepage/warehouse-machine.webp';
import PropTypes from 'prop-types';

const shapes = [
    {
        width: 655,
        height: 680,
        path: 'M537.827 9.245A11.5 11.5 0 0 1 549.104 0h63.366c7.257 0 12.7 6.64 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 586.87 151h-28.275a15.999 15.999 0 0 0-15.689 12.862l-59.4 297c-1.98 9.901 5.592 19.138 15.689 19.138h17.275l.127.001c.85.009 1.701.074 2.549.009 11.329-.874 21.411-7.529 24.88-25.981.002-.012.016-.016.023-.007.008.009.022.005.024-.006l24.754-123.771A11.5 11.5 0 0 1 580.104 321h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 617.87 472H559c-22.866 0-28.984 7.98-31.989 25.931-.004.026-.037.035-.052.014-.015-.02-.048-.013-.053.012l-24.759 123.798A11.5 11.5 0 0 1 490.87 631h-29.132a14.953 14.953 0 0 0-14.664 12.021c-4.3 21.502-23.18 36.979-45.107 36.979H83.502c-29.028 0-50.8-26.557-45.107-55.021l102.4-512C145.096 91.477 163.975 76 185.902 76h318.465c10.136 0 21.179-5.35 23.167-15.288l10.293-51.467Zm-512 160A11.5 11.5 0 0 1 37.104 160h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 74.87 311H11.504c-7.257 0-12.7-6.639-11.277-13.755l25.6-128Z'
    },
    {
        width: 719,
        height: 680,
        path: 'M89.827 9.245A11.5 11.5 0 0 1 101.104 0h63.366c7.257 0 12.7 6.64 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 138.87 151H75.504c-7.257 0-12.7-6.639-11.277-13.755l25.6-128Zm-64 321A11.5 11.5 0 0 1 37.104 321h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 74.87 472H11.504c-7.257 0-12.7-6.639-11.277-13.755l25.6-128ZM526.795 470a15.999 15.999 0 0 0-15.689 12.862l-32.032 160.159c-4.3 21.502-23.18 36.979-45.107 36.979H115.502c-29.028 0-50.8-26.557-45.107-55.021l102.4-512C177.096 91.477 195.975 76 217.902 76h318.465c29.028 0 50.8 26.557 45.107 55.021l-33.768 168.841c-1.98 9.901 5.592 19.138 15.689 19.138h17.075l.127.001c.85.009 1.701.074 2.549.009 11.329-.874 21.411-7.529 24.88-25.981.002-.012.016-.016.023-.007.008.009.022.005.024-.006l24.754-123.771A11.5 11.5 0 0 1 644.104 160h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 681.87 311H623c-22.866 0-28.984 7.98-31.989 25.931-.004.026-.037.035-.052.014-.015-.02-.048-.013-.053.012l-24.759 123.798A11.5 11.5 0 0 1 554.87 470h-28.075Z'
    },
    {
        width: 719,
        height: 680,
        path: 'M632.827 9.245A11.5 11.5 0 0 1 644.104 0h63.366c7.257 0 12.7 6.64 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 681.87 151h-28.275a15.999 15.999 0 0 0-15.689 12.862l-95.832 479.159c-4.3 21.502-23.18 36.979-45.107 36.979H178.502c-29.028 0-50.8-26.557-45.107-55.021l102.4-512C240.096 91.477 258.975 76 280.902 76h318.465c10.136 0 21.179-5.35 23.167-15.288l10.293-51.467Zm0 479A11.5 11.5 0 0 1 644.104 479h63.366c7.257 0 12.7 6.639 11.277 13.755l-25.6 128A11.5 11.5 0 0 1 681.87 630h-63.366c-7.257 0-12.7-6.639-11.277-13.755l25.6-128ZM37.104 159a11.5 11.5 0 0 0-11.277 9.245l-25.6 128C-1.196 303.361 4.247 310 11.504 310H74.87a11.5 11.5 0 0 0 11.277-9.245l24.76-123.798a.03.03 0 0 1 .052-.012c.015.021.048.012.052-.014C114.016 158.98 120.134 151 143 151h58.87a11.5 11.5 0 0 0 11.277-9.245l25.6-128C240.17 6.64 234.727 0 227.47 0h-63.366a11.5 11.5 0 0 0-11.277 9.245l-24.754 123.771c-.002.011-.016.015-.024.006-.007-.009-.021-.005-.023.007-3.469 18.452-13.551 25.107-24.88 25.981-.848.065-1.699 0-2.549-.009l-.127-.001H37.104Z'
    },
    {
        width: 655,
        height: 680,
        path: 'M511.86,42.85c-19.8,99-46.04,242.01-65.84,341.01l-25.6,128-24.76,123.8c-4.3,21.5-23.18,36.98-45.11,36.98H46.02c-29.03,0-50.8-26.56-45.11-55.02L103.31,105.62C122.51,26,119.51,0,161.51,0h315.04c37.87,0,39.56,21.21,35.31,42.85Z'
    }
];

function StylizedImage({ shape = 0, className, ...props }) {
    const id = useId();
    const { width, height, path } = shapes[shape];

    return (
        <div
            className={clsx(
                className,
                'relative flex aspect-[719/680] w-full'
            )}>
            <svg
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                className="h-full">
                <g clipPath={`url(#${id}-clip)`} className="group">
                    <g className="origin-center scale-100 transition duration-500 motion-safe:group-hover:scale-105">
                        <foreignObject width={width} height={height}>
                            <img
                                alt=""
                                className="w-full bg-neutral-100 object-cover"
                                style={{ aspectRatio: `${width} / ${height}` }}
                                {...props}
                            />
                        </foreignObject>
                    </g>
                    <use
                        href={`#${id}-shape`}
                        strokeWidth="2"
                        className="stroke-neutral-900/10"
                    />
                </g>
                <defs>
                    <clipPath id={`${id}-clip`}>
                        <path
                            id={`${id}-shape`}
                            d={path}
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
}

StylizedImage.propTypes = {
    className: PropTypes.any,
    shape: PropTypes.number
};

function Section({ title, image, children }) {
    return (
        <Container className="my-16 lg:my-28">
            <div className="lg:flex lg:items-center lg:justify-center lg:gap-x-8 lg:group-even/section:justify-start xl:gap-x-20">
                <div className="flex justify-center">
                    <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
                        <StylizedImage
                            {...image}
                            sizes="(min-width: 1024px) 41rem, 31rem"
                            className="justify-center lg:justify-start lg:group-even/section:justify-start"
                        />
                    </FadeIn>
                </div>
                <div className="mt-12 lg:mt-0 lg:w-[22rem] xl:w-[32rem] lg:flex-none lg:group-even/section:order-first">
                    <div>
                        <h2 className="mt-2 font-display text-3xl font-medium tracking-tight text-theme-color-400 sm:text-4xl">
                            {title}
                        </h2>
                        <div className="mt-6">{children}</div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

Section.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        shape: PropTypes.number.isRequired
    }).isRequired,
    children: PropTypes.node.isRequired
};

function Border({
    as,
    className,
    position = 'top',
    invert = false,
    ...props
}) {
    const Component = as ?? 'div';

    return (
        <Component
            className={clsx(
                className,
                'relative before:absolute after:absolute',
                invert
                    ? 'before:bg-white after:bg-white/10'
                    : 'before:bg-theme-color-400 after:bg-theme-color-400/10',
                position === 'top' &&
                    'before:left-0 before:top-0 before:h-px before:w-6 after:left-8 after:right-0 after:top-0 after:h-px',
                position === 'left' &&
                    'before:left-0 before:top-0 before:h-6 before:w-px after:bottom-0 after:left-0 after:top-8 after:w-px'
            )}
            {...props}
        />
    );
}

Border.propTypes = {
    as: PropTypes.string,
    className: PropTypes.any,
    invert: PropTypes.bool,
    position: PropTypes.string
};

function List({ children, className }) {
    return (
        <FadeInStagger>
            <ul
                role="list"
                className={clsx('text-base text-neutral-900', className)}>
                {children}
            </ul>
        </FadeInStagger>
    );
}

List.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

function ListItem({ children, title }) {
    return (
        <li className="group mt-10 first:mt-0">
            <FadeIn>
                <Border className="pt-10 group-first:pt-0 group-first:before:hidden group-first:after:hidden">
                    {title && (
                        <strong className="font-semibold text-xl text-theme-color-400">{`${title}. `}</strong>
                    )}
                    {children}
                </Border>
            </FadeIn>
        </li>
    );
}

ListItem.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default function Benefits() {
    return (
        <div id="benefits">
            <Section
                title="Discover our end-to-end FBA prep and shipping services."
                image={{ src: imageWarehouseMachine, shape: 2 }}>
                <div className="space-y-6 text-base text-neutral-600">
                    <p>
                        My AMZ Prep streamlines your FBA business with expert
                        services that enhance efficiency and sales, managing
                        quality control and logistics so you can focus on
                        business growth.
                    </p>
                </div>
                <List className="mt-8">
                    <ListItem title="Boost Sales & Efficiency">
                        We provide expert FBA prep services that save you time
                        and energy, enabling you to concentrate on expanding
                        your business and boosting sales.
                    </ListItem>
                    <ListItem title="Eliminate Errors & Delays">
                        We meticulously control and inspect quality to guarantee
                        your products meet Amazon&apos;s strict standards,
                        minimizing the risk of expensive mistakes and delays.
                    </ListItem>
                    <ListItem title="Streamline Operations">
                        We offer an all-in-one solution that streamlines your
                        FBA workflow from inspection to shipping, delivering a
                        seamless experience that conserves your time and
                        resources.
                    </ListItem>
                </List>
            </Section>
        </div>
    );
}
