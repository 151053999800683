import { API, Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import AddonListItem from './addon-list-item';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

function AddonForm({
    addons,
    editAddons,
    setEditAddons,
    orderAddons,
    productID,
    rows,
    setAddonEditForm,
    setAddonForm,
    setCurrentAddon,
    setOrderAddons,
    setRows,
    setSelectedRows
}) {
    const { order, products, setOrderInfo } = useContext(orderContext);
    const [loading, setLoading] = useState(false);

    const handleAddAddon = async () => {
        setLoading(true);
        const newProduct = products.find(
            product => product.ProductID === productID
        );
        newProduct.addons = orderAddons;
        let newProducts = products.filter(
            product => product.ProductID !== productID
        );
        newProducts = [newProduct, ...newProducts];

        const newOrder = order;
        newOrder.nodes = newProducts;
        delete newOrder.ancestors;
        delete newOrder.parentNode;
        delete newOrder.treeXLevel;
        delete newOrder.treeYLevel;

        setSelectedRows([newOrder]);

        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                order: newOrder
            }
        };
        API.post('myamzprepfixapi', '/orders/addons', requestInfo)
            .then(() => {
                const tempArr = rows.filter(
                    row => row.OrderID !== order.OrderID
                );
                setRows([newOrder, ...tempArr]);
                toast.success('Addons updated successfully');
                setAddonForm(false);
                setLoading(false);
                setEditAddons(false);
            })
            .catch(err => {
                console.log(err);
                toast.error('There was an error adding addons to this order.');
                setLoading(false);
                setEditAddons(false);
            });
        setOrderAddons([]);
    };

    const AddonsList = () =>
        addons &&
        addons.map(addon => (
            <AddonListItem
                addon={addon.AddonID}
                key={addon.AddonID}
                name={addon.Name}
                addons={addons}
                orderAddons={orderAddons}
                setOrderAddons={setOrderAddons}
                editAddons={editAddons}
                setCurrentAddon={setCurrentAddon}
                setAddonEditForm={setAddonEditForm}
            />
        ));

    return (
        <div>
            <div className="overflow-y-auto overflow-x-hidden max-h-[500px]">
                <AddonsList />
            </div>
            <div className="flex">
                <button
                    onClick={() => {
                        setAddonForm(false);
                        setOrderInfo(false);
                        setEditAddons(false);
                        setOrderAddons([]);
                    }}
                    type="button"
                    className="py-2 mr-4 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={() => {
                        handleAddAddon();
                    }}
                    className="w-full px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                    text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                    {loading ? (
                        <div className="flex justify-center">
                            <div className="loading-spinner" id="spinner"></div>
                        </div>
                    ) : (
                        'Save'
                    )}
                </button>
            </div>
        </div>
    );
}

AddonForm.propTypes = {
    addons: PropTypes.array,
    editAddons: PropTypes.bool,
    orderAddons: PropTypes.array,
    productID: PropTypes.string,
    rows: PropTypes.array,
    setAddonEditForm: PropTypes.func,
    setAddonForm: PropTypes.func,
    setCurrentAddon: PropTypes.func,
    setEditAddons: PropTypes.func,
    setOrderAddons: PropTypes.func,
    setRows: PropTypes.func,
    setSelectedRows: PropTypes.func
};

export default AddonForm;
