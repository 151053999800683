import { API, Auth } from 'aws-amplify';
import {
    IconAlertTriangle,
    IconArrowMerge,
    IconFileText,
    IconPlus,
    IconRefresh,
    IconSearch,
    IconX
} from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import AddonEditForm from '../components/addon-edit-form';
import AddonForm from '../components/addon-form';
import Banner from '../components/banner';
import DataTable from '../components/table';
import getAddonFromID from '../utils/getAddonFromID';
import Notes from '../components/notes';
import { orderContext } from '../contexts/order-context';
import OrderForm from '../components/order-form';
import OrderPage from '../components/order-page';
import QuickAddForm from '../components/quick-add-form';
import { toast } from 'react-toastify';
import { useUser } from '../contexts/user-context';

const getBannerText = bannerType => {
    switch (bannerType) {
        case 'error':
            return 'You have an order that needs attention. Please review the order notes for more details.';
        default:
            return '';
    }
};

function Shipments() {
    const [addonForm, setAddonForm] = useState(false);
    const [addonEditForm, setAddonEditForm] = useState(false);
    const [addons, setAddons] = useState([]);
    const [bannerType, setBannerType] = useState('');
    const [currentAddon, setCurrentAddon] = useState(false);
    const [createOrder, setCreateOrder] = useState(false);
    const [editAddons, setEditAddons] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notesForm, setNotesForm] = useState(false);
    const [orderAddons, setOrderAddons] = useState([]);
    const [orderLoading, setOrderLoading] = useState(false);
    const [productID, setProductID] = useState(false);
    const [productNotes, setProductNotes] = useState(false);
    const [quickAddForm, setQuickAddForm] = useState(false);
    const [rows, setRows] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [showBanner, setShowBanner] = useState(false);
    const [showOrderPage, setShowOrderPage] = useState(false);
    const [showMergeButton, setShowMergeButton] = useState(false);
    const { order, setProducts } = useContext(orderContext);
    const { userGroup, userTier } = useUser();

    const handleCloseCreateOrder = () => setCreateOrder(false);
    const handleCreateOrder = () => {
        setProducts([]);
        setCreateOrder(true);
    };

    const callApi = async () => {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();

        // const username = user.signInUserSession.idToken.payload['cognito:username'];
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };

        API.get('myamzprepfixapi', '/orders', requestInfo)
            .then(response => {
                response.Items.forEach(item => {
                    item.id = item.OrderID;
                    if (
                        !user.signInUserSession.idToken.payload[
                            'cognito:groups'
                        ][0].includes('admin')
                    ) {
                        if (item.Status === 'Attention Required') {
                            setShowBanner(true);
                            setBannerType('error');
                        }
                    }
                    if (item.nodes && item.nodes.length > 0) {
                        item.nodes.forEach(node => {
                            node.id = node.ProductID;
                        });
                    }
                });
                setRows(response.Items);
                console.log(response);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    const refreshTable = () => {
        callApi();
    };

    const handleGetAddons = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };
        API.get('myamzprepfixapi', '/addons', requestInfo).then(response => {
            console.log(response);
            setAddons(response.Items);
        });
    };

    const handleExportSelected = () => {
        setLoading(true);
        const selectedOrders = selectedRows || [];
        try {
            let csvContent = 'data:text/csv;charset=utf-8,';
            csvContent +=
                'Distributor,OrderID,Status,ProductName,StoreName,CreatedAt,ASIN,Quantity,QuantityReceived,MultiPack\n';
            selectedOrders.sort(
                (a, b) =>
                    new Date(b.nodes[0].CreatedAt) -
                    new Date(a.nodes[0].CreatedAt)
            );

            selectedOrders.forEach(selectedOrder => {
                selectedOrder.Distributor = selectedOrder.Distributor.replace(
                    /,/g,
                    ''
                );
                selectedOrder.Distributor = selectedOrder.Distributor.replace(
                    /#/g,
                    ''
                );
                selectedOrder.StoreName = selectedOrder.StoreName.replace(
                    /,/g,
                    ''
                );
                selectedOrder.StoreName = selectedOrder.StoreName.replace(
                    /#/g,
                    ''
                );
                selectedOrder.nodes.forEach(node => {
                    node.Status = selectedOrder.Status;
                    node.MultiPack = node.MultiPack ? node.MultiPack : '';
                    node.ProductName = node.ProductName.replace(/,/g, '');
                    node.ProductName = node.ProductName.replace(/#/g, '');
                    csvContent += `${selectedOrder.Distributor},${node.OrderID},${node.Status},${node.ProductName},${selectedOrder.StoreName},${node.CreatedAt},${node.ASIN},${node.Quantity},${node.QuantityReceived},${node.MultiPack}\n`;
                });
            });

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'orders.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleMergeOrder = async () => {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        let requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                orderList: selectedRows || []
            }
        };

        API.post('myamzprepfixapi', '/orders/merge', requestInfo)
            .then(response => {
                toast('Orders merged!', {
                    type: 'success',
                    position: 'top-right'
                });
                console.log(response.body);
            })
            .catch(error => {
                toast('There was an error merging the orders.', {
                    type: 'error',
                    position: 'top-right'
                });
                console.log(error.response);
                setLoading(false);
            })
            .finally(() => {
                requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    }
                };
                API.get('myamzprepfixapi', '/orders', requestInfo)
                    .then(response => {
                        response.Items.forEach(item => {
                            item.id = item.OrderID;
                            if (
                                !user.signInUserSession.idToken.payload[
                                    'cognito:groups'
                                ][0].includes('admin')
                            ) {
                                if (item.Status === 'Attention Required') {
                                    setShowBanner(true);
                                    setBannerType('error');
                                }
                            }
                            if (item.nodes && item.nodes.length > 0) {
                                item.nodes.forEach(node => {
                                    node.id = node.ProductID;
                                });
                            }
                        });
                        setRows(response.Items);
                        setSelectedRows([]);
                        setShowMergeButton(false);
                        console.log(response);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false);
                    });
            });
    };
    const handleSearch = event => {
        setSearch(event.target.value);
    };

    const isUpdate = () => {
        if (order && order.OrderID && order.OrderID !== '') {
            return true;
        }
        return false;
    };

    const getModalTitle = () => {
        if (notesForm) {
            return 'Edit Notes';
        }
        return isUpdate() ? 'Update Order' : 'New Order';
    };

    useEffect(() => {
        callApi();
        handleGetAddons();
        setSelectedRows([]);
        setShowMergeButton(false);
    }, []);

    return (
        <div className="w-full px-4 lg:p-0">
            <div className="mt-1 lg:mt-10 mb-4 lg:mb-6 lg:ml-9">
                <h1 className="text-3xl font-bold text-gray-900">
                    Your Orders
                </h1>
                <p className="mt-1.5 text-base sm:text-sm text-gray-500">
                    View and manage your orders.
                </p>
            </div>

            {addonEditForm && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto w-[400px]">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        {currentAddon
                                            ? 'Update Addon'
                                            : 'New Addon'}
                                    </h3>
                                </div>
                                {/* body */}
                                <div className="relative py-6 flex-auto">
                                    <AddonEditForm
                                        setAddonForm={setAddonEditForm}
                                        addon={currentAddon}
                                        setCurrentAddon={setCurrentAddon}
                                        addons={addons}
                                        setAddons={setAddons}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}

            <div className="relative mx-auto flex flex-col lg:flex-row lg:w-[95.5%]">
                <div className="flex flex-row">
                    <button
                        type="button"
                        onClick={handleCreateOrder}
                        className="w-full lg:w-44 h-[42px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                        <IconPlus className="mr-1 -ml-1" />
                        New order
                    </button>
                    <button
                        onClick={refreshTable}
                        className="w-16 h-[42px] lg:mr-4 lg:pl-2 lg:pr-1 lg:px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-0">
                        <IconRefresh size={22} className=" -ml-1" />
                    </button>
                </div>

                {showMergeButton && (
                    <button
                        type="button"
                        onClick={handleMergeOrder}
                        className="w-full lg:w-48 h-[42px] mr-4 lg:pl-2 pl-2 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-2 md:mt-0 min-w-fit">
                        <IconArrowMerge size={22} className="mr-1 -ml-1" />
                        Merge orders
                    </button>
                )}

                {selectedRows.length > 0 && (
                    <button
                        type="button"
                        onClick={handleExportSelected}
                        className="w-full lg:w-48 h-[42px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-2 md:mt-0 min-w-fit">
                        <IconFileText size={22} className="mr-1" />
                        Export orders
                    </button>
                )}

                <div className="relative w-full mt-4 lg:mt-0">
                    <IconSearch
                        color={'#737373'}
                        className="absolute mt-2 left-2"
                    />
                    <input
                        type="text"
                        id="rounded-email"
                        value={search}
                        onChange={handleSearch}
                        className="w-full rounded-lg border-transparent flex-1 appearance-none border border-gray-300 py-2 px-10 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-200 focus:border-transparent"
                        placeholder="Search"
                    />
                </div>
            </div>
            {showBanner && (
                <Banner
                    type={bannerType}
                    text={getBannerText(bannerType)}
                    icon={<IconAlertTriangle color={'white'} />}
                    setShowBanner={setShowBanner}
                />
            )}
            <div>
                {loading ? (
                    <SkeletonTheme baseColor="#d1e1fa" highlightColor="#4B84FF">
                        <div className="lg:mx-8 mt-4">
                            <Skeleton />
                        </div>
                    </SkeletonTheme>
                ) : showOrderPage ? (
                    <OrderPage
                        addons={addons}
                        orderAddons={orderAddons}
                        productID={productID}
                        rows={rows}
                        selectedRows={selectedRows}
                        setAddonForm={setAddonForm}
                        setOrderAddons={setOrderAddons}
                        setProductID={setProductID}
                        setProductNotes={setProductNotes}
                        setQuickAddForm={setQuickAddForm}
                        setRows={setRows}
                        setSelectedRows={setSelectedRows}
                        setShowOrderPage={setShowOrderPage}
                    />
                ) : (
                    <DataTable
                        nodes={rows}
                        orderAddons={orderAddons}
                        productID={productID}
                        productNotes={productNotes}
                        search={search}
                        selectedRows={selectedRows}
                        setAddonForm={setAddonForm}
                        setCreateOrder={setCreateOrder}
                        setOrderAddons={setOrderAddons}
                        setOrderLoading={setOrderLoading}
                        setProductID={setProductID}
                        setProductNotes={setProductNotes}
                        setNotesForm={setNotesForm}
                        setQuickAddForm={setQuickAddForm}
                        setSelectedRows={setSelectedRows}
                        setShowMergeButton={setShowMergeButton}
                        setShowOrderPage={setShowOrderPage}
                    />
                )}
            </div>
            <div>
                {createOrder ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none max-h-[90vh]">
                                    {/* header */}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
                                        <h3 className="text-xl font-semibold">
                                            {getModalTitle()}
                                        </h3>
                                    </div>
                                    {/* body */}
                                    <div className="relative pt-4 pb-6 px-6 flex-auto ">
                                        <OrderForm
                                            closeModal={handleCloseCreateOrder}
                                            isUpdate={isUpdate}
                                            notesForm={notesForm}
                                            orderLoading={orderLoading}
                                            productID={productID}
                                            productNotes={productNotes}
                                            refreshTable={refreshTable}
                                            rows={rows}
                                            setNotesForm={setNotesForm}
                                            setProductID={setProductID}
                                            setProductNotes={setProductNotes}
                                            setProducts={setProducts}
                                            setRows={setRows}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
                    </>
                ) : null}
                {quickAddForm ? (
                    <QuickAddForm
                        productID={productID}
                        type={quickAddForm}
                        setQuickAddForm={setQuickAddForm}
                        rows={rows}
                        setRows={setRows}
                        setSelectedRows={setSelectedRows}
                        handleClose={() => {
                            setQuickAddForm(false);
                        }}
                    />
                ) : null}
                {notesForm ? (
                    <Notes
                        notesForm={notesForm}
                        productID={productID}
                        productNotes={productNotes}
                        refreshTable={refreshTable}
                        rows={rows}
                        setNotesForm={setNotesForm}
                        setProductID={setProductID}
                        setProductNotes={setProductNotes}
                        setProducts={setProducts}
                        setRows={setRows}
                    />
                ) : null}
                {addonForm && userGroup.includes('admin') ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto w-[450px]">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-3xl bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            Add-ons
                                        </h3>
                                        <div>
                                            <button
                                                className="hover:underline text-sm mr-3"
                                                onClick={() => {
                                                    setAddonEditForm(true);
                                                }}>
                                                Create add-on
                                            </button>
                                            <button
                                                className="hover:underline text-sm"
                                                onClick={() => {
                                                    setEditAddons(!editAddons);
                                                }}>
                                                {editAddons
                                                    ? 'Done editing'
                                                    : 'Edit add-ons'}
                                            </button>
                                        </div>
                                    </div>
                                    {/* body */}
                                    <div className="relative pt-4 pb-6 px-4 flex-auto">
                                        <AddonForm
                                            addons={addons}
                                            editAddons={editAddons}
                                            orderAddons={orderAddons}
                                            productID={productID}
                                            rows={rows}
                                            setAddonEditForm={setAddonEditForm}
                                            setAddonForm={setAddonForm}
                                            setCurrentAddon={setCurrentAddon}
                                            setEditAddons={setEditAddons}
                                            setOrderAddons={setOrderAddons}
                                            setRows={setRows}
                                            setSelectedRows={setSelectedRows}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
                    </>
                ) : null}
                {addonForm && !userGroup.includes('admin') ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative my-6 mx-auto w-[400px]">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-3xl bg-white outline-none focus:outline-none">
                                    <div className="absolute top-5 right-2">
                                        <button
                                            className="pointer"
                                            onClick={() => {
                                                setAddonForm(false);
                                            }}>
                                            <IconX />
                                        </button>
                                    </div>
                                    {/* header */}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            Add-ons for this order:
                                        </h3>
                                    </div>
                                    {/* body */}
                                    <div className="relative pt-4 pb-6 px-6 flex-auto">
                                        {orderAddons.length > 0 ? (
                                            orderAddons.map(addon => (
                                                <div
                                                    className="flex justify-between"
                                                    key={addon}>
                                                    <p>
                                                        {
                                                            getAddonFromID(
                                                                addon,
                                                                addons
                                                            ).Name
                                                        }
                                                    </p>
                                                    <p>
                                                        {`
                                                        ${getAddonFromID(
                                                            addon,
                                                            addons
                                                        )?.Price?.[
                                                            `tier${userTier}`
                                                        ].toLocaleString(
                                                            'en-US',
                                                            {
                                                                style: 'currency',
                                                                currency: 'USD'
                                                            }
                                                        )} each
                                                    `}
                                                    </p>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                No add-ons applied to this
                                                order.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default Shipments;
