import { IconPlus } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

const AddNoteForm = ({ handleCreateNote, notesRef, text }) => {
    console.log('Adding note...');
    return (
        <div className="flex flex-col w-[460px] px-3 py-1">
            <textarea
                ref={notesRef}
                className="border border-gray-300 rounded-md p-2 mb-2"
                rows="5"
                placeholder="Add a note"
                defaultValue={text || ''}
            />
            <button
                type="button"
                onClick={handleCreateNote}
                className="w-full h-[38px] pl-1 pr-2 px-4 flex justify-center items-center bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 focus:ring-offset-blue-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                <IconPlus className="mr-1 -ml-1" />
                {text ? 'Save note' : 'Add note'}
            </button>
        </div>
    );
};

AddNoteForm.propTypes = {
    handleCreateNote: PropTypes.func.isRequired,
    notesRef: PropTypes.any.isRequired,
    text: PropTypes.string
};

export default AddNoteForm;
