import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React from 'react';

export const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [userGroup, setUserGroup] = React.useState(null);
    const [userID, setUserID] = React.useState(null);
    const [userTier, setUserTier] = React.useState('initial');
    const [userValidW9, setUserValidW9] = React.useState('initial');
    const [userValidResale, setUserValidResale] = React.useState('initial');

    React.useEffect(() => {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(authUser => {
                setUser(authUser.signInUserSession.idToken.payload);
                // setUserGroup(
                //     authUser.signInUserSession.idToken.payload[
                //         'cognito:groups'
                //     ][0]
                // );
                if (
                    authUser.signInUserSession.idToken.payload[
                        'cognito:groups'
                    ].includes('superadmin')
                ) {
                    setUserGroup('superadmin');
                } else {
                    setUserGroup(
                        authUser.signInUserSession.idToken.payload[
                            'cognito:groups'
                        ][0]
                    );
                }
                setUserID(authUser.signInUserSession.idToken.payload.sub);
                setUserTier(
                    authUser.attributes['custom:stripeSubscriptionId']
                        ? 'tier1'
                        : 'tier0'
                );
                setUserValidW9(authUser.attributes['custom:validW9']);
                setUserValidResale(authUser.attributes['custom:validResale']);
            })
            .catch(err => {
                setUser(null);
                console.log(err);
            });
    }, []);

    const values = React.useMemo(
        () => ({
            user,
            userGroup,
            userID,
            userTier,
            userValidResale,
            userValidW9
        }),
        [user]
    );

    return (
        <UserContext.Provider value={values}>{children}</UserContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.any
};

export const useUser = () => {
    const context = React.useContext(UserContext);

    if (context === null) {
        throw new Error(
            '`useUser` hook must be used within a `UserProvider` component'
        );
    }
    return context;
};
