import { API } from 'aws-amplify';

export default async function listUsers(groupName, token) {
    let users = [];
    let response = {};
    const params = {
        queryStringParameters: {
            groupname: groupName,
            limit: 60
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    };

    response = await API.get('AdminQueries', '/listUsersInGroup', params);
    users = [...users, ...response.Users];
    while (response.NextToken) {
        params.queryStringParameters.token = response.NextToken;
        response = await API.get('AdminQueries', '/listUsersInGroup', params);
        users = [...users, ...response.Users];
    }
    return users;
}
