import { IconCirclePlus, IconCircleX, IconPencil } from '@tabler/icons-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import getAddonFromID from '../utils/getAddonFromID';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

function AddonListItem({
    addons,
    editAddons,
    addon,
    name,
    orderAddons,
    setAddonEditForm,
    setCurrentAddon,
    setOrderAddons
}) {
    const [isAdded, setIsAdded] = useState(false);
    const numberRef = useRef(null);
    const { order } = useContext(orderContext);

    useEffect(() => {
        if (orderAddons.length > 0) {
            if (JSON.stringify(orderAddons).includes(addon)) {
                setIsAdded(true);
            }
        }

        if (isAdded) {
            numberRef.current.disabled = true;
        }
    }, [orderAddons, isAdded]);

    const handleAdd = addedAddon => {
        if (numberRef.current.value <= 0) {
            toast.error('Invalid quantity');
            return false;
        }
        const newOrderAddons = orderAddons.filter(
            item => item.id !== addedAddon.id
        );
        newOrderAddons.push({
            id: addedAddon,
            quantity: numberRef.current.value
        });
        setOrderAddons(newOrderAddons);
        console.log(newOrderAddons);
        setIsAdded(true);
    };

    const handleEdit = editedAddon => {
        if (!orderAddons.includes(editedAddon)) {
            setCurrentAddon(getAddonFromID(editedAddon, addons));
        }
        setAddonEditForm(true);
    };

    const handleRemove = removedAddon => {
        const newOrderAddons = orderAddons.filter(
            item => item.id !== removedAddon
        );
        setOrderAddons(newOrderAddons);
        setIsAdded(false);
    };

    const getAddonQuantity = () =>
        orderAddons.find(item => item.id === addon)?.quantity || 1;

    const notAddedClass = 'w-10 h-full bg-gray-100';

    const disabledClass =
        'pointer-events-none cursor-not-allowed w-10 h-full bg-gray-100';

    return (
        <div className="flex flex-row justify-end items-center mx-2 h-full">
            <div
                className="my-2 bg-gray-100 py-2 px-2 rounded flex justify-between mr-2 w-full"
                key={addon}>
                <span className="">{name}</span>
                <div className="flex">
                    {!editAddons && !order.AddonsPaid && (
                        <>
                            {!isAdded && (
                                <button
                                    onClick={() => {
                                        handleAdd(addon);
                                    }}
                                    className="pointer">
                                    <IconCirclePlus
                                        size={28}
                                        color={'#22c55e'}
                                    />
                                </button>
                            )}
                            {isAdded && (
                                <button
                                    onClick={() => {
                                        handleRemove(addon);
                                    }}
                                    className="pointer">
                                    <IconCircleX size={28} color={'#dc2626'} />
                                </button>
                            )}
                        </>
                    )}
                    {editAddons && (
                        <button
                            onClick={() => {
                                handleEdit(addon);
                            }}
                            className="pointer">
                            <IconPencil size={28} color={'black'} />
                        </button>
                    )}
                </div>
            </div>
            <div className={isAdded ? disabledClass : notAddedClass}>
                <input
                    type="number"
                    title="Number of addons applied per unit"
                    ref={numberRef}
                    className="w-11 h-11 rounded hide-num-arrows-chrome hide-num-arrows-moz outline-none focus:outline-none text-center border-gray-300 bg-gray-100 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700"
                    name="custom-input-number"
                    min={1}
                    max={1000000}
                    defaultValue={getAddonQuantity()}></input>
            </div>
        </div>
    );
}

AddonListItem.propTypes = {
    addon: PropTypes.any,
    addons: PropTypes.any,
    editAddons: PropTypes.bool,
    name: PropTypes.string.isRequired,
    orderAddons: PropTypes.array.isRequired,
    setAddonEditForm: PropTypes.func,
    setCurrentAddon: PropTypes.func,
    setOrderAddons: PropTypes.func.isRequired
};

export default AddonListItem;
