import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const RowSkeleton = () => (
    <ul className="w-full max-h-[68vh] flex flex-col divide divide-y">
        <li className="flex flex-row m-2">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 lg:mr-16">
                    <div className="font-semibold text-lg">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                </div>
                <div className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end">
                    <Skeleton circle width={20} height={20} />
                </div>
            </div>
        </li>
        <li className="flex flex-row m-2">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 lg:mr-16">
                    <div className="font-semibold text-lg">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                </div>
                <div className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end">
                    <Skeleton circle width={20} height={20} />
                </div>
            </div>
        </li>
        <li className="flex flex-row m-2">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 lg:mr-16">
                    <div className="font-semibold text-lg">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                </div>
                <div className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end">
                    <Skeleton circle width={20} height={20} />
                </div>
            </div>
        </li>
        <li className="flex flex-row m-2">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 lg:mr-16">
                    <div className="font-semibold text-lg">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                </div>
                <div className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end">
                    <Skeleton circle width={20} height={20} />
                </div>
            </div>
        </li>
        <li className="flex flex-row m-2">
            <div className="select-none cursor-pointer flex flex-1 items-center p-4">
                <div className="flex-1 pl-1 lg:mr-16">
                    <div className="font-semibold text-lg">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                    <div className="text-gray-600">
                        <Skeleton />
                    </div>
                </div>
                <div className="lg:w-24 ml-4 lg:ml-0 text-right flex justify-end">
                    <Skeleton circle width={20} height={20} />
                </div>
            </div>
        </li>
    </ul>
);
export default RowSkeleton;
