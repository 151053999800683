/**
 * Return a comma separated list of product names from an order with a max length of 50 characters
 */
export default function getProudctNames(order) {
    let productNames = '';
    order.nodes.forEach(node => {
        productNames += node.ProductName + ', ';
    });

    productNames = productNames.slice(0, -2);

    if (productNames.length > 50) {
        productNames = productNames.substring(0, 50) + '...';
    }

    return productNames;
}
