const favoriteAddons = [
    {
        Price: {
            tier1: 0.7,
            tier3: 0.66,
            tier2: 0.68
        },
        AddonID: 'kjfbTchBLOBZZfKbYOqTig',
        Description: '(Includes Shrink wrap/Poly Bag)',
        CreatedAt: '2022-10-10T03:42:23.320Z',
        Name: 'Bundle (Includes Shrink wrap/Poly Bag)'
    },
    {
        Price: {
            tier1: 0.4,
            tier3: 0.36,
            tier2: 0.38
        },
        AddonID: 'PVfsWL4r4_23LrnMMlQoyg',
        Description: '1 Unit',
        CreatedAt: '2022-10-10T03:41:59.105Z',
        Name: 'Shrink wrap/poly bag'
    },
    {
        Price: {
            tier1: 0.48,
            tier3: 0.45,
            tier2: 0.46
        },
        AddonID: 'DOXFPSh_NcQw-sKksVWBMA',
        Description: '',
        Name: 'OA/RA Drop Off',
        CreatedAt: '2024-06-06T19:59:26.098Z'
    },
    {
        Price: {
            tier1: 0.27,
            tier3: 0.27,
            tier2: 0.27
        },
        AddonID: 'sblPl8_My66as4itLShwNg',
        Description: 'Sticker Removal (1 Sticker)',
        Name: 'Sticker Removal (1 Sticker)',
        CreatedAt: '2024-04-09T15:32:23.413Z'
    },
    {
        Price: {
            tier1: 1,
            tier3: 1,
            tier2: 1
        },
        AddonID: 'Z5s7lw7asjEHuWwEk3zrlQ',
        Description: '',
        Name: 'Shoes',
        CreatedAt: '2024-06-06T20:00:16.934Z'
    },
    {
        Price: {
            tier1: 1.5,
            tier3: 1.45,
            tier2: 1.47
        },
        AddonID: '9YY0omKlIJJ_EyDlbAulwA',
        Description: '',
        Name: 'Oversized Item (16+ Inches any Side)',
        CreatedAt: '2024-06-06T20:00:00.813Z'
    }
];

export default favoriteAddons;
