import formatDate from '../utils/formatDate';
import getAddonFromID from '../utils/getAddonFromID';
import getColorFromID from '../utils/getColorFromID';
import getNumericID from '../utils/getReadableID';
import getProudctNames from '../utils/getProductNames';
import PaymentsBadge from './payments-badge';
import PropTypes from 'prop-types';
import React from 'react';
import truncateString from '../utils/truncateString';

function PaymentCardPaid({ order, addons, receipts }) {
    const receipt = receipts.find(item => item.OrderID === order.id);

    const getAddonPrice = item => item.Subtotal / 100 / item.Quantity;

    const AddonsList = () =>
        receipt &&
        receipt.order &&
        receipt.order.map((item, index) => (
            <div
                key={item.AddonID}
                className={
                    index % 2 === 0
                        ? 'flex justify-between items-center bg-gray-200 py-2'
                        : 'flex justify-between items-center py-2'
                }>
                <div>
                    <span className="font-bold">
                        {item.Quantity > 1 ? `(${item.Quantity})` : ''}
                    </span>
                    <span> {getAddonFromID(item.AddonID, addons)?.Name}</span>
                </div>
                <span className="ml-2 min-w-max">
                    {`${item.Quantity} @ ${getAddonPrice(item).toFixed(2)} ea`}
                </span>
            </div>
        ));

    return (
        <>
            {receipt ? (
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr
                        className="border-2 rounded-lg mx-[10%]"
                        style={{ borderColor: getColorFromID(order.id) }}
                    />
                    {/* Header with order number, date, and status badge */}
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <div>
                            <span className="text-sm text-gray-500">
                                {formatDate(order.CreatedAt)}
                            </span>
                        </div>
                        <div>
                            <span className="text-sm font-medium text-gray-900">
                                {`Order #${getNumericID(order.id)}`}
                            </span>
                        </div>

                        <div>
                            <PaymentsBadge
                                status={
                                    order.AddonsPaid
                                        ? 'Order paid'
                                        : 'Payment due'
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <div>
                            <span className="text-sm mr-3">
                                <span className="font-bold">Distributor: </span>
                                {truncateString(order.Distributor, 21)}
                            </span>
                            <span className="text-sm">
                                <span className="font-bold">Store: </span>
                                {truncateString(order.StoreName, 21)}
                            </span>
                        </div>
                        <div>
                            <span className="text-sm">
                                <span className="font-bold">Products: </span>
                                {getProudctNames(order)}
                            </span>
                        </div>
                    </div>
                    <div className="bg-gray-100 m-2 space-y-2 rounded">
                        <span className="font-bold">ADD-ONS:</span>
                        <div className="flex flex-col">
                            <AddonsList />
                            <div className="text-center text font-medium">
                                <span>
                                    {`Total: ${(
                                        receipt?.Total / 100
                                    ).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    })}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

PaymentCardPaid.propTypes = {
    addons: PropTypes.array,
    order: PropTypes.object.isRequired,
    receipts: PropTypes.shape({
        find: PropTypes.func
    }),
    unitsCache: PropTypes.shape({
        get: PropTypes.func,
        has: PropTypes.func,
        set: PropTypes.func
    }),
    userUnits: PropTypes.number,
    users: PropTypes.array
};

export default PaymentCardPaid;
