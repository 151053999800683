import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = createContext({
    chatNotificationCount: 0,
    storeNotificationCount: 0,
    setChatNotificationCount: () => {},
    setStoreNotificationCount: () => {}
});

export const NotificationProvider = ({ children }) => {
    const [chatNotificationCount, setChatNotificationCount] = React.useState(0);
    const [storeNotificationCount, setStoreNotificationCount] = React.useState(
        0
    );

    return (
        <NotificationContext.Provider
            value={{
                chatNotificationCount,
                setChatNotificationCount,
                storeNotificationCount,
                setStoreNotificationCount
            }}>
            {children}
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.any
};
