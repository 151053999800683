import ChatWidget from '../components/chat-widget';
import GlobalBanner from '../components/global-banner';
import Nav from '../components/nav';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';

// Nav and other components shared by all pages are rendered here
function Layout() {
    const [isChatOpen, setChatOpen] = React.useState(false);

    return (
        <div className="relative w-full">
            <div className="lg:flex">
                <Nav />
                <div className="w-full">
                    <GlobalBanner />
                    <Outlet context={[isChatOpen, setChatOpen]} />
                </div>
            </div>
            <ToastContainer />
            <ChatWidget isChatOpen={isChatOpen} setChatOpen={setChatOpen} />
        </div>
    );
}

export default Layout;
