import { API, Auth } from 'aws-amplify';
import {
    IconArrowLeft,
    IconBasket,
    IconBoxPadding,
    IconNewSection,
    IconNote,
    IconPackages,
    IconPlus,
    IconRuler2,
    IconShoe,
    IconShoppingBag,
    IconSticker
} from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import favoriteAddons from '../utils/favoriteAddons';
import isItemOversized from '../utils/isItemOversized';
import LeftoversModal from './leftoversModal';
import NotesList from './NotesList';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import StatusBadge from './status-badge';
import { useUser } from '../contexts/user-context';

function OrderPage({
    rows,
    selectedRows,
    setAddonForm,
    setOrderAddons,
    setProductID,
    setProductNotes,
    setQuickAddForm,
    setRows,
    setSelectedRows,
    setShowOrderPage
}) {
    const [loading, setLoading] = useState(false);
    const [products, setOrderPageProducts] = useState([]);
    const [productsApiInfo, setProductsApiInfo] = useState([]);
    const [showLeftoversModal, setShowLeftoversModal] = useState(false);
    const { setOrderInfo, setProducts } = useContext(orderContext);
    const { userGroup } = useUser();

    const isOversized = product => {
        const apiDimensions = product?.dimensions;
        console.log('product', product);
        if (
            !apiDimensions ||
            !apiDimensions.weight ||
            !apiDimensions.length ||
            !apiDimensions.width ||
            !apiDimensions.height
        ) {
            console.error('Missing dimensions for oversized item check.');
            return false;
        }
        const dimensions = {
            width: apiDimensions.width.value,
            height: apiDimensions.height.value,
            length: apiDimensions.length.value
        };
        const weight = apiDimensions?.weight?.value;
        return isItemOversized(dimensions, weight);
    };

    // Function to chunk array
    const chunkArray = (array, chunkSize) => {
        let index = 0;
        const arrayLength = array.length;
        const tempArray = [];

        for (index = 0; index < arrayLength; index += chunkSize) {
            const chunk = array.slice(index, index + chunkSize);
            tempArray.push(chunk);
        }

        return tempArray;
    };

    const handleQuickAdd = product => {
        setProducts(products);
        setOrderInfo(selectedRows[0]);
        setProductID(product.ProductID);
        setProductNotes(product.Notes);
        setQuickAddForm('quantityReceived');
    };

    const handleAddons = product => {
        setProducts(products);
        setOrderInfo(selectedRows[0]);
        setProductID(product.ProductID);
        setProductNotes(product.Notes);
        if (product.addons && product.addons.length > 0) {
            setOrderAddons(product.addons);
        }
        setAddonForm(true);
    };

    const withRetryHandling = (
        callback,
        {
            baseDelay = 3000, // Set base delay to 1 second
            numberOfTries = 3 // Number of tries before giving up
        } = {}
    ) => {
        const retry = async (attempt = 1) => {
            try {
                return await callback();
            } catch (error) {
                if (attempt >= numberOfTries) {
                    console.warn('Failed after retries', error);
                    return callback; // Return the callback instead of throwing error
                }

                const delay = baseDelay * attempt;
                console.warn('Retry because of', error);
                await new Promise(resolve => setTimeout(resolve, delay));

                return retry(attempt + 1000);
            }
        };
        return retry();
    };

    const processBatch = async (batch, testRequestInfo) => {
        const productPromises = batch.map(product =>
            withRetryHandling(() =>
                API.get(
                    'myamzprepfixapi',
                    '/sellingPartnerAPIHandler-dev/' +
                        product.ASIN.split(' ').join(''),
                    testRequestInfo
                )
                    .then(response => {
                        console.log(response);
                        setOrderPageProducts(prevProducts => {
                            const index = prevProducts.findIndex(
                                p => p.id === product.id
                            );
                            if (index === -1) {
                                return [
                                    ...prevProducts,
                                    {
                                        apiInfo: response?.summaries[0],
                                        ...product
                                    }
                                ];
                            } else {
                                const newProducts = [...prevProducts];
                                newProducts[index] = {
                                    apiInfo: response?.summaries[0],
                                    ...product
                                };
                                return newProducts;
                            }
                        });

                        if (response) {
                            return {
                                apiInfo: response?.summaries[0],
                                image: response?.images[0]?.images[0]?.link,
                                dimensions: response?.dimensions[0]?.package,
                                ...product
                            };
                        } else {
                            console.log("Couldn't fetch product info!");
                            return { ...product };
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching product info', error);
                        setOrderPageProducts(prevProducts => {
                            const index = prevProducts.findIndex(
                                p => p.id === product.id
                            );
                            if (index === -1) {
                                return [...prevProducts, { ...product }];
                            } else {
                                const newProducts = [...prevProducts];
                                newProducts[index] = { ...product };
                                return newProducts;
                            }
                        });
                        return { ...product };
                    })
            )
        );

        const failedRequests = [];
        const results = await Promise.allSettled(productPromises);
        results.forEach((result, index) => {
            if (result.status === 'rejected') {
                failedRequests.push(() =>
                    withRetryHandling(() => productPromises[index]())
                );
            }
        });

        return {
            successful: results.filter(result => result.status === 'fulfilled'),
            failed: failedRequests
        };
    };

    const QuickAddonButton = ({ product, addon }) => {
        const [isAdded, setIsAdded] = useState(
            product?.addons?.some(item => item.id === addon.AddonID)
        );
        const getIcon = id => {
            switch (id) {
                // Shoes
                case 'Z5s7lw7asjEHuWwEk3zrlQ':
                    return <IconShoe />;
                // OA/RA Drop Off
                case 'DOXFPSh_NcQw-sKksVWBMA':
                    return <IconShoppingBag />;
                // Shrink wrap/poly bag
                case 'PVfsWL4r4_23LrnMMlQoyg':
                    return <IconBoxPadding />;
                // Oversized Item (16+ Inches any Side)
                case '9YY0omKlIJJ_EyDlbAulwA':
                    return <IconRuler2 />;
                // Sticker removal (1 Sticker)
                case 'sblPl8_My66as4itLShwNg':
                    return <IconSticker />;
                // Bundle
                case 'kjfbTchBLOBZZfKbYOqTig':
                    return <IconPackages />;
                default:
                    return <IconNewSection />;
            }
        };

        const postAddon = async newOrder => {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                },
                body: {
                    order: newOrder
                }
            };
            API.post('myamzprepfixapi', '/orders/addons', requestInfo)
                .then(() => {
                    const tempArr = rows.filter(
                        row => row.OrderID !== newOrder.OrderID
                    );
                    setRows([newOrder, ...tempArr]);
                    setAddonForm(false);
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setLoading(false);
                    setOrderAddons([]);
                });
        };

        const handleAddAddon = async addedAddon => {
            const newOrderAddons =
                product?.addons?.filter(
                    item => item.id !== addedAddon.AddonID
                ) || [];
            newOrderAddons.push({
                id: addedAddon.AddonID,
                quantity: 1
            });
            setLoading(true);

            product.addons = newOrderAddons;
            let newProducts = products.filter(
                newProd => newProd.ProductID !== product.ProductID
            );
            newProducts = [product, ...newProducts];

            const newOrder = selectedRows[0];
            newOrder.nodes = newProducts;
            delete newOrder.ancestors;
            delete newOrder.parentNode;
            delete newOrder.treeXLevel;
            delete newOrder.treeYLevel;

            setSelectedRows([newOrder]);
            await postAddon(newOrder);
            setIsAdded(true);
        };

        const handleRemoveAddon = async removedAddon => {
            const newOrderAddons =
                product?.addons?.filter(
                    item => item.id !== removedAddon.AddonID
                ) || [];
            setLoading(true);

            product.addons = newOrderAddons;
            let newProducts = products.filter(
                newProd => newProd.ProductID !== product.ProductID
            );
            newProducts = [product, ...newProducts];

            const newOrder = selectedRows[0];
            newOrder.nodes = newProducts;
            delete newOrder.ancestors;
            delete newOrder.parentNode;
            delete newOrder.treeXLevel;
            delete newOrder.treeYLevel;

            setSelectedRows([newOrder]);
            await postAddon(newOrder);
            setIsAdded(false);
        };

        const addedColor =
            'bg-green-400 hover:bg-red-500 focus:ring-red-400 focus:ring-offset-red-200';
        const notAddedColor =
            'bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200';
        const buttonStyles = `w-full h-[42px] px-2 px-4 flex justify-center items-center ${
            isAdded ? addedColor : notAddedColor
        } text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg `;
        return (
            <button
                type="button"
                title={addon.Name}
                className={buttonStyles}
                onClick={() => {
                    isAdded ? handleRemoveAddon(addon) : handleAddAddon(addon);
                }}>
                {getIcon(addon.AddonID)}
            </button>
        );
    };

    const getProductInfo = async () => {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const testRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };

        const chunkedArray = chunkArray(selectedRows[0].nodes, 2);

        let productsArr = [];
        let failedRequests = [];

        for (const chunk of chunkedArray) {
            const { successful, failed } = await processBatch(
                chunk,
                testRequestInfo
            );
            const values = successful.map(s => s.value);
            productsArr = [...productsArr, ...values];
            failedRequests = [...failedRequests, ...failed];
        }

        // Process failed requests sequentially
        for (const failedRequest of failedRequests) {
            await new Promise(resolve => setTimeout(resolve, 3000)); // Delay between each request
            try {
                const response = await failedRequest();
                productsArr.push(response);
            } catch (error) {
                console.error('Failed request', error);
            }
        }

        productsArr = productsArr
            .filter(p => p.ASIN)
            .sort((a, b) => a.ASIN.localeCompare(b.ASIN));
        setProductsApiInfo(
            productsArr.map(p => ({ ASIN: p.ASIN, ...p.apiInfo }))
        );
        setOrderPageProducts(productsArr);
        setLoading(false);
    };

    useEffect(() => {
        if (productsApiInfo.length <= 0) {
            getProductInfo();
        } else {
            const updatedProducts = selectedRows[0].nodes.map(product => {
                console.log('product', product);
                const apiInfo = productsApiInfo.find(
                    p => p.ASIN === product.ASIN
                );
                return {
                    ...product,
                    apiInfo
                };
            });
            setOrderPageProducts(updatedProducts);
        }
    }, [selectedRows]);
    const handleClose = () => {
        setShowOrderPage(false);
    };
    return (
        <div className="mx-9 mt-4">
            <div className="flex">
                <button
                    onClick={handleClose}
                    className="mr-3 bg-gray-500 text-white py-1 px-2 rounded-lg hover:bg-gray-400 transition ease-in duration-200">
                    <IconArrowLeft />
                </button>
                {userGroup.includes('admin') && (
                    <button
                        type="button"
                        onClick={() => setShowLeftoversModal(true)}
                        className="w-full lg:w-[222px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-2 md:mt-0 min-w-fit">
                        <IconBasket size={22} className="mr-1" />
                        Add leftover items
                    </button>
                )}
                <div className="text-base flex items-center bg-white rounded shadow py-2 px-4 w-full space-x-2">
                    <StatusBadge
                        status={selectedRows[0].Status}
                        orderID={selectedRows[0].OrderID}
                        storeID={selectedRows[0].UserID}
                        units={selectedRows[0]}
                    />
                    <span className="font-medium">Store Name: </span>
                    <span> {selectedRows[0].StoreName}</span>

                    <span className="font-medium">Distributor: </span>
                    <span> {selectedRows[0].Distributor}</span>
                    <span className="font-medium">Date: </span>
                    <span> {selectedRows[0].CreatedAt.split('T')[0]}</span>
                </div>
            </div>
            {showLeftoversModal && (
                <LeftoversModal
                    setOpen={setShowLeftoversModal}
                    selectedRows={selectedRows}
                />
            )}
            <div className="grid grid-cols-2 gap-4 mt-4 xl:grid-cols-3 xwide:grid-cols-4">
                {loading ? (
                    Array.from(
                        { length: products?.length || 0 },
                        (_, index) => (
                            <Skeleton
                                key={index}
                                className="min-w-[261px]"
                                height={300}
                            />
                        )
                    )
                ) : (
                    <>
                        {products &&
                            products
                                .sort((a, b) => a.ASIN.localeCompare(b.ASIN))
                                .map(product => (
                                    <div
                                        key={product.id}
                                        className="bg-white rounded-lg shadow-md px-4 pb-4 py-6 relative h-fit min-w-[261px]">
                                        <div className="absolute right-0 top-0 rounded-tr-lg text-black px-2 py-1 font-sm">
                                            {product.QuantityReceived
                                                ? `${product.QuantityReceived} / `
                                                : ''}
                                            {product.Quantity}
                                        </div>
                                        <div className="absolute left-1 top-1">
                                            <div className="relative flex items-center">
                                                <button
                                                    className="hover:cursor-pointer peer"
                                                    onClick={() => {
                                                        console.log(product);
                                                    }}>
                                                    {product.Notes &&
                                                        product.Notes.length >
                                                            0 && <IconNote />}
                                                </button>
                                                {product.Notes &&
                                                    product.Notes.length >
                                                        0 && (
                                                        <div className="peer-hover:block hover:block p-2 bg-white z-50 hidden absolute shadow-lg rounded min-w-[420px]">
                                                            <NotesList
                                                                notes={
                                                                    product.Notes
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                {isOversized(product) && (
                                                    <div
                                                        title="Oversized item"
                                                        className="block px-1 py-1 text-orange-400">
                                                        <IconRuler2 />
                                                    </div>
                                                )}
                                                <span>
                                                    {typeof product.MultiPack ===
                                                        'number' &&
                                                        product.MultiPack > 1 &&
                                                        `${product.MultiPack} unit multipack`}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-full h-[100px] flex justify-center mb-2">
                                            {product?.ASIN && (
                                                <a
                                                    href={`https://www.amazon.com/dp/${product.ASIN.split(
                                                        ' '
                                                    ).join('')}/`}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    <img
                                                        className="h-[100px] object-cover"
                                                        src={product.image}
                                                        alt={
                                                            product.ProductName
                                                        }></img>
                                                </a>
                                            )}
                                        </div>
                                        <div className="w-full">
                                            <p className="truncate w-full">
                                                {product?.apiInfo?.itemName ||
                                                    product.ProductName}
                                            </p>
                                        </div>
                                        <div className="flex flex-row justify-between mt-0">
                                            <span className="basis-1/2">
                                                ASIN: {product.ASIN}
                                            </span>
                                            <span className="truncate basis-1/2">
                                                Name: {product.ProductName}
                                            </span>
                                        </div>
                                        <div className="flex mt-3">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleAddons(product)
                                                }
                                                className="w-full h-[42px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                             text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                                <IconNewSection className="mr-1 -ml-1" />
                                                Add addons
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleQuickAdd(product)
                                                }
                                                className="w-full h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                             text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                                <IconPlus className="mr-1 -ml-1" />
                                                Add units
                                            </button>
                                        </div>
                                        <div className="flex flex-row justify-between mt-2 w-full gap-2">
                                            {favoriteAddons.map(addon => (
                                                <QuickAddonButton
                                                    key={addon.AddonID}
                                                    product={product}
                                                    addon={addon}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                    </>
                )}
            </div>
        </div>
    );
}

OrderPage.propTypes = {
    addon: PropTypes.any,
    addons: PropTypes.array,
    orderAddons: PropTypes.array,
    product: PropTypes.any,
    productID: PropTypes.any,
    rows: PropTypes.array,
    selectedRows: PropTypes.array,
    setAddonForm: PropTypes.func,
    setOrderAddons: PropTypes.func,
    setProductID: PropTypes.func,
    setProductNotes: PropTypes.func,
    setQuickAddForm: PropTypes.func,
    setRows: PropTypes.func,
    setSelectedRows: PropTypes.func,
    setShowOrderPage: PropTypes.func
};

export default OrderPage;
