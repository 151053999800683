import {
    IconClipboardText,
    IconPackage,
    IconRefresh,
    IconTruckDelivery,
    IconZoomInArea
} from '@tabler/icons-react';
import { Container } from './Container';
import React from 'react';

export function Services() {
    return (
        <div
            id="services"
            className="md:rounded-4xl md:bg-theme-color-400 py-20 sm:mt-32 sm:py-32 lg:mt-24">
            <Container>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                    <div className="border-2 border-white rounded-3xl p-6 text-white relative shadow-lg bg-theme-color-400 hover:bg-theme-color-700">
                        <div className="bg-white bg-opacity-30 rounded-full w-40 h-40 flex items-center justify-center mx-auto mb-6 shadow-lg">
                            <IconZoomInArea
                                size={100}
                                stroke={1.3}
                                color="white"
                            />
                        </div>
                        <h3 className="text-xl font-semibold mb-4">
                            Inspection & Quality Control
                        </h3>
                        <p>
                            Ensure your products meet Amazon&apos;s standards
                            with our thorough inspection and quality control
                            process.
                        </p>
                    </div>
                    <div className="border-2 border-white rounded-3xl p-6 text-white relative shadow-lg bg-theme-color-400 hover:bg-theme-color-700">
                        <div className="bg-white bg-opacity-30 rounded-full w-40 h-40 flex items-center justify-center mx-auto mb-6 shadow-lg">
                            <IconPackage
                                size={100}
                                stroke={1.3}
                                color="white"
                            />
                        </div>
                        <h3 className="text-xl font-semibold mb-4">
                            Labeling & Packaging
                        </h3>
                        <p>
                            Get your products retail-ready with our professional
                            labeling and packaging services, tailored to
                            Amazon&apos;s requirements.
                        </p>
                    </div>
                    <div className="border-2 border-white rounded-3xl p-6 text-white relative shadow-lg bg-theme-color-400 hover:bg-theme-color-700">
                        <div className="bg-white bg-opacity-30 rounded-full w-40 h-40 flex items-center justify-center mx-auto mb-6 shadow-lg">
                            <IconClipboardText
                                size={100}
                                stroke={1.3}
                                color="white"
                            />
                        </div>
                        <h3 className="text-xl font-semibold mb-4">
                            Inventory Management
                        </h3>
                        <p>
                            Safeguard your inventory with our secure storage
                            solutions and real-time inventory management system.
                        </p>
                    </div>
                    <div className="border-2 border-white rounded-3xl p-6 text-white relative shadow-lg bg-theme-color-400 hover:bg-theme-color-700">
                        <div className="bg-white bg-opacity-30 rounded-full w-40 h-40 flex items-center justify-center mx-auto mb-6 shadow-lg">
                            <IconTruckDelivery
                                size={100}
                                stroke={1.3}
                                color="white"
                            />
                        </div>
                        <h3 className="text-xl font-semibold mb-4">
                            Shipping & Logistics
                        </h3>
                        <p>
                            Expedite your shipping process with our streamlined
                            logistics, ensuring timely delivery to Amazon
                            fulfillment centers.
                        </p>
                    </div>
                    <div className="border-2 border-white rounded-3xl p-6 text-white relative shadow-lg bg-theme-color-400 hover:bg-theme-color-700">
                        <div className="bg-white bg-opacity-30 rounded-full w-40 h-40 flex items-center justify-center mx-auto mb-6 shadow-lg">
                            <IconRefresh
                                size={100}
                                stroke={1.3}
                                color="white"
                            />
                        </div>
                        <h3 className="text-xl font-semibold mb-4">
                            Returns Management
                        </h3>
                        <p>
                            Simplify your returns process with our efficient
                            handling and processing of returned items.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export function ServiceIcons() {
    const services = [
        {
            name: 'Inspection',
            icon: <IconZoomInArea size={48} stroke={1.5} />
        },
        {
            name: 'Packaging',
            icon: <IconPackage size={48} stroke={1.5} />
        },
        {
            name: 'Inventory',
            icon: <IconClipboardText size={48} stroke={1.5} />
        },
        {
            name: 'Shipping',
            icon: <IconTruckDelivery size={48} stroke={1.5} />
        },
        {
            name: 'Returns',
            icon: <IconRefresh size={48} stroke={1.5} />
        }
    ];

    return (
        <div className="hidden mt-6 md:flex flex-col items-center md:flex-row justify-center lg:justify-start gap-2">
            {services.map(service => (
                <div
                    key={service.name}
                    className="flex flex-col items-center p-4">
                    <div className="text-theme-color-400">{service.icon}</div>
                    <h3 className="mt-2 font-semibold text-sm">
                        {service.name}
                    </h3>
                </div>
            ))}
        </div>
    );
}
