// return the charge for the subscription given number of units
export default function getPriceFromUnits(units) {
    const numUnits = Number(units);
    if (numUnits <= 1000) {
        return numUnits * 0.84;
    }
    if (numUnits <= 2000) {
        return numUnits * 0.75;
    }
    if (numUnits > 2000) {
        return numUnits * 0.67;
    }
    return false;
}
