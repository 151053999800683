import { API, Auth } from 'aws-amplify';
import { IconCheck, IconNote, IconTrash, IconX } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import config from '../aws-exports';
import NoImage from '../images/no-image.jpg';
import PropTypes from 'prop-types';
import { useUser } from '../contexts/user-context';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;

function Leftovers() {
    const [unresolvedLeftovers, setUnresolvedLeftovers] = useState([]);
    const [resolvedLeftovers, setResolvedLeftovers] = useState([]);
    const [showResolved, setShowResolved] = useState(false);
    const { userID, userGroup } = useUser();

    const selectedButtonClass =
        'underline text-lg font-semibold underline-offset-1 decoration-2';
    const unselectedButtonClass =
        'text-lg font-semibold underline-offset-1 decoration-2';

    const LefoverItem = ({ item }) => {
        const [showConfirm, setShowConfirm] = useState(false);
        const [showEdit, setShowEdit] = useState(false);
        const quantityRef = useRef(null);
        const nameRef = useRef(null);

        const updateLeftover = async () => {
            const amplifyUser = await Auth.currentAuthenticatedUser();
            const token = amplifyUser.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                },
                body: {
                    UserID: item.UserID,
                    ResolvedAt: item.ResolvedAt,
                    Notes: item.Notes,
                    UPC: item.UPC,
                    Picture: item.Picture,
                    StoreName: item.StoreName,
                    Quantity: quantityRef?.current?.value || item.Quantity,
                    id: item.id,
                    CreatedAt: item.CreatedAt,
                    Name: nameRef?.current?.value || item.Name
                }
            };
            API.put('myamzprepfixapi', '/leftovers', requestInfo).then(
                response => {
                    console.log(response);
                }
            );
            setShowEdit(false);
            setUnresolvedLeftovers(
                unresolvedLeftovers.map(leftover =>
                    leftover.id === item.id
                        ? {
                              ...leftover,
                              Quantity:
                                  quantityRef?.current?.value || item.Quantity,
                              Name: nameRef?.current?.value || item.Name
                          }
                        : leftover
                )
            );
        };

        const deleteLeftover = async leftoverItem => {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                }
            };

            try {
                const isResolved = leftoverItem.ResolvedAt;
                const response = await API.del(
                    'myamzprepfixapi',
                    `/leftovers?id=${leftoverItem.id}&UserID=${leftoverItem.UserID}`,
                    requestInfo
                );
                console.log(response);
                if (isResolved) {
                    setResolvedLeftovers(
                        resolvedLeftovers.filter(
                            leftover => leftover.id !== leftoverItem.id
                        )
                    );
                } else {
                    setUnresolvedLeftovers(
                        unresolvedLeftovers.filter(
                            leftover => leftover.id !== leftoverItem.id
                        )
                    );
                }
            } catch (error) {
                console.error(error);
            }
        };

        const markItemResolved = async () => {
            const amplifyUser = await Auth.currentAuthenticatedUser();
            const token = amplifyUser.signInUserSession.idToken.jwtToken;
            if (userGroup.includes('admin')) {
                try {
                    const requestInfo = {
                        headers: {
                            Authorization: token,
                            Accept: 'application/json, text/plain, */*'
                        },
                        body: {
                            UserID: item.UserID,
                            ResolvedAt: true,
                            Notes: item.Notes,
                            UPC: item.UPC,
                            Picture: item.Picture,
                            StoreName: item.StoreName,
                            Quantity: item.Quantity,
                            id: item.id,
                            CreatedAt: item.CreatedAt,
                            Name: item.Name
                        }
                    };
                    const response = await API.put(
                        'myamzprepfixapi',
                        '/leftovers',
                        requestInfo
                    );
                    console.log(response);
                    setResolvedLeftovers([
                        ...resolvedLeftovers,
                        {
                            ...item,
                            ResolvedAt: new Date().toISOString()
                        }
                    ]);
                    setUnresolvedLeftovers(
                        unresolvedLeftovers.filter(
                            leftover => leftover.id !== item.id
                        )
                    );
                } catch (error) {
                    console.error(error);
                }
            } else {
                const requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    },
                    body: {
                        id: item.id
                    }
                };
                try {
                    const response = API.put(
                        'myamzprepfixapi',
                        `/leftovers/user`,
                        requestInfo
                    );
                    setResolvedLeftovers([...resolvedLeftovers, item]);
                    setUnresolvedLeftovers(
                        unresolvedLeftovers.filter(
                            leftover => leftover.id !== item.id
                        )
                    );
                    console.log(response);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        console.log('ITEM', item);

        return (
            <div className="bg-white w-full lg:w-full lg:max-w-[450px] rounded-lg pb-2 p-4 shadow-sm relative">
                <div className="absolute top-0 left-0 group">
                    <button
                        type="button"
                        title={item.Notes?.length < 0 ? 'No notes' : ''}
                        className="w-[46px] h-[42px] px-2 flex justify-center items-center bg-white text-black transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                        <IconNote />
                    </button>
                    <div className="hidden group-hover:block">
                        {typeof item.Notes === 'string' ? (
                            <div className="bg-gray-100 p-2 my-2 rounded">
                                {item.Notes}
                            </div>
                        ) : (
                            item?.Notes?.map(note => (
                                <div
                                    key={note.id}
                                    className="bg-gray-100 p-2 my-2 rounded">
                                    {note.Note}
                                </div>
                            ))
                        )}
                    </div>
                </div>
                {userGroup.includes('admin') && (
                    <div className="absolute top-0 right-0">
                        <button
                            type="button"
                            onClick={() => {
                                deleteLeftover(item);
                            }}
                            className="w-[46px] h-[42px] px-2 flex justify-center items-center bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200
                     text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            <IconTrash />
                        </button>
                    </div>
                )}

                <a
                    href={
                        item.Picture
                            ? `https://${bucket}.s3.${region}.amazonaws.com/public/${item.Picture}`
                            : NoImage
                    }
                    target="_blank"
                    rel="noreferrer">
                    <img
                        src={
                            item.Picture
                                ? `https://${bucket}.s3.${region}.amazonaws.com/public/${item.Picture}`
                                : NoImage
                        }
                        className="h-[270px] w-full object-cover rounded"
                    />
                </a>
                <div className="mb-2">
                    {!showEdit && (
                        <div className="flex justify-between w-full py-3">
                            <span>{`(${item.Quantity}) ${item.Name}`}</span>
                            <span>{item.UPC}</span>
                        </div>
                    )}
                    {showEdit && (
                        <div className="flex justify-between w-full py-3 gap-2">
                            <input
                                ref={quantityRef}
                                type="number"
                                defaultValue={item.Quantity}
                                className="w-1/4 p-2 border border-gray-300 rounded"
                            />
                            <input
                                ref={nameRef}
                                type="text"
                                defaultValue={item.Name}
                                className="w-3/4 p-2 border border-gray-300 rounded"
                            />
                        </div>
                    )}
                    {!showEdit && (
                        <div className="flex gap-2">
                            {!showConfirm && !item.ResolvedAt && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowConfirm(true);
                                    }}
                                    className="w-full h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                     text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                    Mark as resolved
                                </button>
                            )}
                            {userGroup.includes('admin') &&
                                !item.ResolvedAt &&
                                !showConfirm && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setShowEdit(true);
                                        }}
                                        className="w-full h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                 text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                        Edit item
                                    </button>
                                )}
                        </div>
                    )}
                    {showEdit && (
                        <div className="flex gap-2">
                            {!showConfirm && !item.ResolvedAt && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowEdit(false);
                                    }}
                                    className="w-full h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200
                     text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                    Cancel
                                </button>
                            )}
                            {userGroup.includes('admin') &&
                                !item.ResolvedAt && (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            updateLeftover(item);
                                        }}
                                        className="w-full h-[42px] pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                 text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                        Save item
                                    </button>
                                )}
                        </div>
                    )}

                    {showConfirm && (
                        <div className="flex items-center justify-between bg-gray-200 rounded py-2 px-4">
                            <span className="text-gray-800">
                                Confirm mark item as resolved?
                            </span>
                            <div className="flex space-x-4">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowConfirm(false);
                                    }}
                                    className="w-[46px] h-[42px] px-2 flex justify-center items-center bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200
                     text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                    <IconX />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        markItemResolved();
                                    }}
                                    className="w-[46px] h-[42px] px-2 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                     text-white transition ease-in duration-200 text-center text-base font-semibold  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                    <IconCheck />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    LefoverItem.propTypes = {
        item: PropTypes.shape({
            Name: PropTypes.string,
            UPC: PropTypes.string,
            Picture: PropTypes.string,
            ResolvedAt: PropTypes.string,
            id: PropTypes.string,
            UserID: PropTypes.string,
            Quantity: PropTypes.string,
            Notes: PropTypes.array,
            CreatedAt: PropTypes.string,
            StoreName: PropTypes.string
        }).isRequired
    };

    const setLeftoverArrs = leftovers => {
        const resolved = [];
        const unresolved = [];
        leftovers.forEach(item => {
            if (item.ResolvedAt) {
                resolved.push(item);
            } else {
                unresolved.push(item);
            }
        });
        setResolvedLeftovers(resolved);
        setUnresolvedLeftovers(unresolved);
    };

    const getLeftovers = async () => {
        if (userGroup.includes('admin')) {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                const token = amplifyUser.signInUserSession.idToken.jwtToken;
                const requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    }
                };

                console.log('isadmin');
                const response = await API.get(
                    'myamzprepfixapi',
                    '/leftovers',
                    requestInfo
                );
                console.log(response);
                setLeftoverArrs(response);
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const amplifyUser = await Auth.currentAuthenticatedUser();
                const token = amplifyUser.signInUserSession.idToken.jwtToken;
                const requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    }
                };
                const response = await API.get(
                    'myamzprepfixapi',
                    `/leftovers/user?UserID=${userID}`,
                    requestInfo
                );
                console.log(response);
                setLeftoverArrs(response);
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        // getUserLeftovers();
        getLeftovers();
    }, []);

    return (
        <>
            <div className="flex flex-col justify-between items-start lg:items-center lg:flex-row mt-4 lg:mt-10 mb-6 lg:ml-9 lg:mr-8">
                <div className="flex flex-col">
                    <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                        Leftover items
                    </h1>
                    <p className="mt-1.5 text-base sm:text-sm text-gray-500">
                        Items recieved that were not accounted for in an order
                        will appear here.
                    </p>
                </div>
            </div>
            <div className="flex justify-center mt-4 mb-4">
                <div className="flex mr-16">
                    <button
                        onClick={() => setShowResolved(false)}
                        className={
                            showResolved
                                ? unselectedButtonClass
                                : selectedButtonClass
                        }>
                        Unresolved items
                    </button>
                </div>
                <div className="flex ml-16">
                    <button
                        onClick={() => setShowResolved(true)}
                        className={
                            showResolved
                                ? selectedButtonClass
                                : unselectedButtonClass
                        }>
                        Resolved items
                    </button>
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mx-9">
                {showResolved
                    ? resolvedLeftovers.map(item => (
                          <LefoverItem key={item.id} item={item} />
                      ))
                    : unresolvedLeftovers.map(item => (
                          <LefoverItem key={item.id} item={item} />
                      ))}
            </div>
        </>
    );
}

export default Leftovers;
