import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ChatsSkeleton = () => (
    <>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
        <div className="container flex flex-col w-full items-center justify-center lg:mx-9">
            <ul className="flex flex-col w-full">
                <li className="border-gray-400 flex flex-row mb-2 w-full">
                    <div className="shadow border select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4">
                        <div className="pl-1 min-w-[480px]">
                            <div className="font-medium ">
                                <Skeleton />
                            </div>
                            <div className="text-gray-600  text-sm">
                                <Skeleton />
                            </div>
                        </div>
                        <div className="text-gray-600 text-md flex-1"></div>
                    </div>
                </li>
            </ul>
        </div>
    </>
);

export default ChatsSkeleton;
