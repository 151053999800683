import { Auth, Storage } from 'aws-amplify';
import { IconAlertTriangle, IconBook } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Banner from '../components/banner';

function Taxes() {
    const [w9File, setW9File] = useState('');
    const [w9Error, setW9Error] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [userObj, setUserObj] = useState(null);
    const [validW9, setValidW9] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const user = await Auth.currentAuthenticatedUser();
            setUserObj(user);
            const w9 = user.attributes['custom:validW9'];
            setValidW9(w9);
            if (w9 === 'denied') {
                setShowBanner(true);
            }
        };
        fetchData();
    }, []);

    async function updateUserValidW9() {
        try {
            await Auth.updateUserAttributes(userObj, {
                'custom:validW9': 'pending'
            });
        } catch (error) {
            console.log('error updating user:', error);
        }
        setLoading(false);
    }

    const w9Handler = e => {
        setW9Error(false);
        if (
            e.target.files[0] &&
            e.target.files[0].type === 'application/pdf' &&
            e.target.files[0].size < 4000000
        ) {
            setW9File(e.target.files[0]);
        } else {
            setW9Error(true);
        }
    };

    const handleSubmit = e => {
        setLoading(true);
        e.preventDefault();

        const dateObj = new Date();
        const month = dateObj.getUTCMonth() + 1;
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        const hour = dateObj.getHours();
        const minute = dateObj.getMinutes();
        const newDate =
            month + '-' + day + '-' + year + '_' + hour + '-' + minute;

        Storage.put(`forms/${userObj.username}/w9_${newDate}.pdf`, w9File, {
            contentType: 'application/pdf',
            level: 'public'
        })
            .then(result => {
                console.log(result);
                updateUserValidW9();
                setShowBanner(false);
                setValidW9('pending');
            })
            .catch(err => {
                console.log(err);
                toast.error('File upload failed.', {
                    position: 'top-right',
                    type: 'error'
                });
            });
    };

    return (
        <>
            <ToastContainer />
            {/* .validTaxes !== pending?? */}
            {validW9 !== 'pending' ? (
                <form
                    onSubmit={handleSubmit}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 container bg-white shadow-md lg:w-[30vw] lg:max-w-[500px] md:w-[80vw] md:min-w-[500px] p-[40px] align-middle rounded-md mx-auto">
                    <h1 className="text-2xl font-bold text-center mb-6">
                        Tax Document Upload
                    </h1>
                    <div className="flex flex-col">
                        <div className="text-gray-500 mb-4">
                            Your W-9 form is required to complete your account
                            setup, this document will be reviewed by the
                            MyAMZPrep team before you are able to begin using
                            the service. If you have any questions, contact us
                            at{' '}
                            <a
                                href="mailto:cristian@myamzprep.com"
                                className="text-blue-500 underline">
                                cristian@myamzprep.com
                            </a>
                            .
                        </div>
                        <label
                            className="font-semibold pl-2 mb-2"
                            htmlFor="taxes">
                            W-9
                        </label>
                        <input
                            className="border border-gray-300 rounded-md p-2 mb-4"
                            type="file"
                            name="taxes"
                            id="taxes"
                            required
                            onChange={w9Handler}
                        />
                        {w9Error ? (
                            <>
                                <p className="text-center text-red-500">
                                    File must be a .pdf and smaller than 4MB
                                </p>
                                <button
                                    type="submit"
                                    disabled
                                    className="bg-gray-400 text-white rounded-md p-2 my-2">
                                    Submit tax info
                                </button>
                            </>
                        ) : (
                            <button
                                type="submit"
                                className="p-2 my-2 bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-200 focus:ring-blue-500 text-white rounded-md">
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    'Submit tax info'
                                )}
                            </button>
                        )}
                    </div>
                </form>
            ) : (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 container bg-white shadow-md lg:w-[30vw] lg:max-w-[500px] md:w-[80vw] md:min-w-[500px] p-[40px] align-middle rounded-md mx-auto">
                    <div className="flex flex-col items-center">
                        <IconBook
                            color={'#1965DB'}
                            strokeWidth={1.5}
                            size={64}
                        />
                        <h1 className="text-2xl font-bold text-center mb-6 mt-2">
                            Review in Progress
                        </h1>
                        <p className="text-center">
                            Your W-9 is currently under review. You will be
                            notified when your account is approved. If you have
                            any questions, please contact us at{' '}
                            <a
                                href="mailto:cristian@myamzprep.com"
                                className="text-blue-500 underline">
                                cristian@myamzprep.com
                            </a>
                        </p>
                        <p className="text-center">for more information.</p>
                    </div>
                </div>
            )}
            {showBanner && (
                <Banner
                    type="error"
                    text="Your W-9 has been denied or is expired. Please upload your latest document for review. Contact us at cristian@myamzprep.com for more information."
                    setShowBanner={setShowBanner}
                    icon={<IconAlertTriangle color={'white'} />}
                />
            )}
        </>
    );
}
export default Taxes;
