import { API, Auth, Storage } from 'aws-amplify';
import { BarcodeFormat, BrowserMultiFormatReader } from '@zxing/library';
import { IconNote, IconRefresh } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AddNoteForm from './add-note-form';
import Compressor from 'compressorjs';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';

function LeftoversModal({ setOpen, selectedRows }) {
    const [barcodeError, setBarcodeError] = useState(null);
    const [barcodeResult, setBarcodeResult] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [showAddNoteForm, setShowAddNoteForm] = useState(false);
    const [showLeftoverForm, setShowLeftoverForm] = useState(false);

    const nameRef = useRef(null);
    const notesRef = useRef(null);
    const quantityRef = useRef(null);
    const webcamRef = useRef(null);

    const hints = new Map();
    const formats = [
        BarcodeFormat.EAN_13,
        BarcodeFormat.EAN_8,
        BarcodeFormat.CODE_128,
        BarcodeFormat.CODE_93,
        BarcodeFormat.CODE_39,
        BarcodeFormat.ITF,
        BarcodeFormat.UPC_A,
        BarcodeFormat.UPC_E,
        BarcodeFormat.CODABAR
    ];
    hints.set(BarcodeFormat.class, formats);

    const captureAndScan = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            try {
                const codeReader = new BrowserMultiFormatReader(hints);
                const result = await codeReader.decodeFromImage(
                    // eslint-disable-next-line no-undefined
                    undefined,
                    imageSrc
                );
                setBarcodeResult(result.getText());
                setCapturedImage(imageSrc);
                setShowLeftoverForm(true);
                setBarcodeError(null);
            } catch (err) {
                setBarcodeError('No barcode detected');
                setTimeout(captureAndScan, 150);
            }
        } else {
            setTimeout(captureAndScan, 1000); // Retry after 1 second if webcam isn't visible yet
        }
    }, [webcamRef]);

    const handleManualCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            setCapturedImage(imageSrc);
            setBarcodeResult(null);
            setBarcodeError(null);
            setShowLeftoverForm(true);
        }
    };

    const handleCreateNote = () => {
        setNoteText(notesRef.current.value);
        setShowAddNoteForm(false);
    };

    const createLeftover = async () => {
        setLoading(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const file = capturedImage;
            let key;
            if (file) {
                const [, type, data] = file.match(/data:(.*);base64,(.*)/);
                const extension = type.split('/')[1];
                const filename = `leftover_${Date.now()}`;
                key = `images/${filename}_${Math.random()
                    .toString(36)
                    .substring(7)}.${extension}`;

                // Decode base64 string to binary data
                const byteCharacters = atob(data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                // Create a File object from the binary data
                const fileObj = new File(
                    [byteArray],
                    `${filename}.${extension}`,
                    { type }
                );

                new Compressor(fileObj, {
                    quality: 0.8,
                    maxHeight: 600,
                    maxWidth: 600,
                    convertSize: 1,
                    success(result) {
                        const compressedFile = new File(
                            [result],
                            `${filename}.${extension}`,
                            {
                                type: result.type
                            }
                        );

                        // Upload image to S3
                        try {
                            Storage.put(key, compressedFile, {
                                contentType: compressedFile.type,
                                level: 'public'
                            });
                        } catch (error) {
                            console.log(error);
                            toast.error('Image upload failed');
                            return;
                        }
                    },
                    error(err) {
                        console.log(err.message);
                        toast.error('Image upload failed');
                        return;
                    }
                });
            } else {
                key = '';
            }

            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                },
                body: {
                    UserID: selectedRows[0].UserID,
                    Notes: noteText,
                    UPC: barcodeResult,
                    Picture: key || '',
                    StoreName: selectedRows[0].StoreName,
                    Quantity: quantityRef.current.value || 1,
                    Name: nameRef.current.value || 'Abandoned Item'
                }
            };
            const response = await API.post(
                'myamzprepfixapi',
                '/leftovers',
                requestInfo
            );
            console.log(response);
            setOpen(false);
        } catch (error) {
            toast.error('Error adding item');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (webcamRef.current) {
            captureAndScan();
        }
        console.log('Selected Rows: ', selectedRows);
    }, [captureAndScan, webcamRef]);

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto w-full max-w-4xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-xl font-semibold">
                                Add leftover items
                            </h3>
                        </div>
                        <div className="relative px-6 pt-6 flex-auto">
                            <div className="flex flex-col items-center relative w-full">
                                {capturedImage ? (
                                    <div className="relative w-full max-w-4xl group">
                                        <img
                                            src={capturedImage}
                                            alt="Captured"
                                            className="w-full group-hover:opacity-75 bg-black transition-opacity"
                                        />
                                        <button
                                            className="absolute flex items-center group transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 text-black py-1 px-3 rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity text-lg"
                                            onClick={() => {
                                                setCapturedImage(null);
                                                setBarcodeResult(null);
                                                setBarcodeError(null);
                                                setTimeout(captureAndScan, 300); // Restart scanning
                                            }}>
                                            <IconRefresh className="mr-2" />
                                            Retake Image
                                        </button>
                                    </div>
                                ) : (
                                    <div className="relative w-full max-w-4xl">
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            className="w-full"
                                        />
                                        <button
                                            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 text-black py-2 px-4 rounded-lg shadow-md transition-opacity"
                                            onClick={handleManualCapture}>
                                            Capture manual image
                                        </button>
                                    </div>
                                )}
                                <div className="mt-4">
                                    {!barcodeResult && (
                                        <div className="text-red-500">
                                            {barcodeError}
                                        </div>
                                    )}
                                </div>

                                {showAddNoteForm && (
                                    <AddNoteForm
                                        handleCreateNote={handleCreateNote}
                                        notesRef={notesRef}
                                        text={noteText}
                                    />
                                )}
                                {showLeftoverForm && (
                                    <div className="flex w-full gap-4 items-end">
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor="upc"
                                                className="text-sm font-semibold">
                                                UPC
                                            </label>
                                            <input
                                                type="number"
                                                value={barcodeResult}
                                                onChange={e => {
                                                    setBarcodeResult(
                                                        e.target.value
                                                    );
                                                }}
                                                id="upc"
                                                name="upc"
                                                required
                                                placeholder="UPC"
                                                className="py-2 px-4 border border-slate-200 rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-200 focus:ring-theme-color-200 focus:outline-none"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor="name"
                                                className="text-sm font-semibold">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                ref={nameRef}
                                                placeholder="Item name"
                                                className="py-2 px-4 border border-slate-200 rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-200 focus:ring-theme-color-200 focus:outline-none"
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <label
                                                htmlFor="quantity"
                                                className="text-sm font-semibold">
                                                Quantity
                                            </label>
                                            <div className="col-span-2 lg:col-span-1">
                                                <input
                                                    type="number"
                                                    id="quantity"
                                                    name="quantity"
                                                    ref={quantityRef}
                                                    required
                                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                    placeholder="(#) Quantity"
                                                />
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShowAddNoteForm(true);
                                            }}
                                            className="w-full lg:w-48 h-[42px] mr-4 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-400 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-2 md:mt-0 min-w-fit">
                                            <IconNote
                                                size={22}
                                                className="mr-1"
                                            />
                                            Notes
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center gap-4 justify-end p-6 rounded-b">
                            <button
                                className="py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                type="button"
                                onClick={() => setOpen(false)}>
                                Cancel
                            </button>
                            <button
                                className="py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                type="button"
                                onClick={() => {
                                    createLeftover();
                                }}>
                                {loading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    'Add item'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

LeftoversModal.propTypes = {
    setOpen: PropTypes.func,
    selectedRows: PropTypes.array
};

export default LeftoversModal;
