import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [prepCenter, setPrepCenter] = useState('');
    const [monthlyUnits, setMonthlyUnits] = useState('');
    const [yearsSelling, setYearsSelling] = useState('');
    const [isBot, setIsBot] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleTextareaChange = e => {
        const textarea = e.target;
        setPrepCenter(textarea.value);

        // Reset the height to auto to correctly reduce its size when deleting content
        textarea.style.height = 'auto';
        // Set the height to scrollHeight to expand the textarea
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleSubmit = async e => {
        if (isBot) {
            console.log('Bot detected');
            return;
        }
        setLoading(true);
        e.preventDefault();
        const formData = {
            name,
            email,
            phone: phoneNumber,
            monthlyUnits,
            yearsSelling,
            prepCenter
        };

        try {
            const response = await fetch(
                'https://54zc8d28bh.execute-api.us-east-1.amazonaws.com/default',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                }
            );

            // Handle response via UI
            toast.success('Your message has been sent!');
            console.log(response);
            setLoading(false);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <div className="flex-1 mt-12 lg:mt-0 max-w-lg mx-auto w-full bg-theme-color-400 p-10 rounded-3xl text-white shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label
                        htmlFor="name"
                        className="block text-md font-medium pl-1 pb-1">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        placeholder="Your name"
                        onChange={e => setName(e.target.value)}
                        required
                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                    />
                </div>
                <div className="flex space-x-4">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-medium pl-1 pb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            placeholder="Your email address"
                            onChange={e => setEmail(e.target.value)}
                            required
                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="message"
                            className="block text-md font-medium pl-1 pb-1">
                            Phone Number
                        </label>
                        <input
                            type="tel"
                            name="phone"
                            id="phone"
                            value={phoneNumber}
                            placeholder="Your phone number"
                            onChange={e => setPhoneNumber(e.target.value)}
                            required
                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                        />
                    </div>
                </div>
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label
                            htmlFor="monthly-units"
                            className="block text-md font-medium pl-1 pb-1">
                            Monthly units sold
                        </label>
                        <input
                            type="number"
                            name="monthly-units"
                            id="monthly-units"
                            value={monthlyUnits}
                            placeholder="Monthly units"
                            onChange={e => setMonthlyUnits(e.target.value)}
                            required
                            min="0"
                            className="rounded-lg border-transparent flex-1 appearance-none border
                            border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400
                            shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400
                            focus:border-transparent"
                        />
                    </div>
                    <div className="flex-1">
                        <label
                            htmlFor="years-selling"
                            className="block text-md font-medium pl-1 pb-1">
                            Years selling online
                        </label>
                        <input
                            type="number"
                            name="years-selling"
                            id="years-selling"
                            value={yearsSelling}
                            placeholder="Years selling"
                            onChange={e => setYearsSelling(e.target.value)}
                            required
                            min="0"
                            className="rounded-lg border-transparent flex-1 appearance-none border
                            border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400
                            shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400
                            focus:border-transparent"
                        />
                    </div>
                </div>
                <div>
                    <label
                        htmlFor="prep-center"
                        className="block text-md font-medium pl-1 pb-1">
                        Why are you looking for a prep center?
                    </label>
                    <textarea
                        name="prepCenter"
                        id="prep-center"
                        value={prepCenter}
                        onChange={handleTextareaChange}
                        placeholder="Tell us what you're looking for"
                        required
                        className="resize-none rounded-lg border-transparent flex-1 appearance-none border
                        border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400
                        shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400
                        focus:border-transparent"
                        rows="1"
                        maxLength={225}
                    />
                </div>
                {/* Hidden field, sets isBot to true if filled out.*/}
                <div
                    style={{ position: 'absolute', left: '-5000px' }}
                    aria-hidden="true"
                    aria-autocomplete="none">
                    <input
                        type="text"
                        name="kjhfeshiuesfifuesh89283"
                        tabIndex="-1"
                        value=""
                        onChange={() => {
                            setIsBot(true);
                        }}
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="align-center mt-4 py-2 px-4 bg-orange-500 hover:bg-orange-400 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in duration-200 text-center text-xl font-semibold shadow-md
                        focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                        {loading ? 'Sending...' : 'Get a Free Quote!'}
                    </button>
                </div>
            </form>
        </div>
    );
}
