import { API, Auth } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import AccountSkeleton from '../components/skeletons/account-skeleton';
import getPriceFromUnits from '../utils/getPriceFromUnits';
import getTierFromUnits from '../utils/getTierFromUnits';
import PaymentUpdate from '../components/payment-update';
import { toast } from 'react-toastify';
import { useUser } from '../contexts/user-context';

function Account() {
    // const [newPassword, setNewPassword] = useState('');
    // const [confirmCode, setConfirmCode] = useState('');

    const { user, userGroup } = useUser();
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [displayEmail, setDisplayEmail] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [noPaymentMethod, setNoPaymentMethod] = useState(false);
    const [updatePaymentModal, setUpdatePaymentModal] = useState(false);
    const [unitCount, setUnitCount] = useState(0);
    const [userTier, setUserTier] = useState(0);
    const [verifyModal, setVerifyModal] = useState(false);
    const [subInfo, setSubInfo] = useState({});
    const emailInput = useRef(null);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            if (userGroup.includes('client')) {
                const authUser = await Auth.currentAuthenticatedUser();
                const token = authUser.signInUserSession.idToken.jwtToken;

                const requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    }
                };

                API.get('MyAMZPrepStoreAPI', '/store/subscription', requestInfo)
                    .then(res => {
                        // console.log(res);
                        res.subscription.cancel_at_period_end = false;
                        setSubInfo(res.subscription);
                        setUnitCount(res.usageRecords.data[0].total_usage);
                        setUserTier(
                            getTierFromUnits(
                                res.usageRecords.data[0].total_usage
                            )
                        );
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log(err);
                        toast.error('No subscription found.');
                        setLoading(false);
                    });

                // get stripe payment method status from amplify api
                API.get(
                    'MyAMZPrepStoreAPI',
                    '/store/customer',
                    requestInfo
                ).then(response => {
                    if (
                        response &&
                        response.invoice_settings &&
                        !response.invoice_settings.default_payment_method
                    ) {
                        setNoPaymentMethod(true);
                    }
                });
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleCancelSubscription = async () => {
        const cancelUser = await Auth.currentAuthenticatedUser();
        const token = cancelUser.signInUserSession.idToken.jwtToken;
        setSubLoading(true);
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, /'
            }
        };
        API.patch('MyAMZPrepStoreAPI', '/store/subscription', requestInfo)
            .then(response => {
                console.log('response:', response);
                setSubLoading(false);
                toast.success('Your subscription has been cancelled.');
            })
            .catch(error => {
                console.log('error:', error);
                setSubLoading(false);
                toast.error('There was an error cancelling your subscription.');
            });
        setVerifyModal(false);
    };

    const handleResetPassword = () => {
        Auth.forgotPassword(user.email);
        setResetPassword(true);
    };

    const getCurrentEmail = () => {
        if (displayEmail) {
            return displayEmail;
        } else {
            return user ? user.email : '';
        }
    };

    const handleEmailSubmit = e => {
        e.preventDefault();
        setEmailModal(true);
    };

    async function handleResetEmail() {
        setSubLoading(true);
        const authUser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(authUser, {
            email: emailInput.current.value
        })
            .then(res => {
                toast('Your email has been updated!', {
                    type: 'success',
                    position: 'top-right'
                });
                setSubLoading(false);
                setEmailModal(false);
                console.log(res);
                Auth.signOut();
            })
            .catch(err => {
                toast('There was an error updating your email.', {
                    type: 'error',
                    position: 'top-right'
                });
                setSubLoading(false);
                setEmailModal(false);
                console.log(err);
            });
        setDisplayEmail(emailInput.current.value);
    }

    const validatePassword = value => {
        let error = '';
        if (!value) {
            error = 'Password cannot be empty.';
        } else if (value.length < 8) {
            error = 'Password must be at least 8 characters long.';
        }
        console.log(error);
        return error;
    };

    const validateConfirmPassword = (pass, value) => {
        let error = '';
        if (pass && value) {
            if (pass !== value) {
                error = 'Password not matched';
            }
        }
        return error;
    };

    function submitPassword(values, actions) {
        setSubLoading(true);
        Auth.forgotPasswordSubmit(
            user.email,
            values.confirmCode,
            values.password
        )
            .then(res => {
                toast('Your password has been updated!', {
                    type: 'success',
                    position: 'top-right'
                });
                setSubLoading(false);
                actions.resetForm();
                setResetPassword(false);
                console.log(res);
            })
            .catch(err => {
                toast('There was an error updating your password.', {
                    type: 'error',
                    position: 'top-right'
                });
                setSubLoading(false);
                actions.resetForm();
                setResetPassword(false);
                console.log(err);
            });
    }

    return (
        <div className="w-full px-4 lg:px-0">
            <div className="mt-1 lg:mt-10 mb-6 lg:ml-9">
                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                    Your Account
                </h1>
                <p className="mt-1.5 text-sm text-gray-500">
                    View and edit your account information.
                </p>
            </div>
            {loading ? (
                <AccountSkeleton />
            ) : (
                <section className="bg-gray-100 bg-opacity-50">
                    <div className="container max-w-2xl mx-auto shadow-md md:w-3/4">
                        <div className="p-4 bg-gray-100 border-t-2 border-indigo-400 rounded-lg bg-opacity-5">
                            <div className="w-full">
                                <div className="flex flex-col">
                                    <h2 className="text-gray-600 font-bold">
                                        {user ? user.name : ''}
                                    </h2>
                                    <p className="text-gray-600">
                                        {getCurrentEmail()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="lg:space-y-6 bg-white">
                            <form
                                onSubmit={handleEmailSubmit}
                                className="w-full px-4 pt-4 space-y-4 text-gray-500">
                                <div className="flex flex-col lg:flex-row lg:space-x-4 items-center">
                                    <input
                                        ref={emailInput}
                                        type="email"
                                        id="user-info-email"
                                        required
                                        className="h-10 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-200 focus:border-transparent"
                                        placeholder="New Email"
                                    />
                                    <button
                                        type="submit"
                                        className="h-10 lg:py-2 mt-6 lg:mt-2 lg:mt-0 lg:px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full lg:w-1/4 lg:min-w-[156px] transition ease-in
                                               duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                        Change email
                                    </button>
                                </div>
                            </form>
                            <div className="w-full px-4 text-gray-500 text-center">
                                {!resetPassword && (
                                    <button
                                        type="button"
                                        onClick={handleResetPassword}
                                        className="py-2 px-4 bg-pink-600 my-2 lg:my-0 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                        Reset password
                                    </button>
                                )}
                                {resetPassword && (
                                    <>
                                        <p>
                                            Enter the code sent to your email
                                            along with your new password.
                                        </p>
                                        <br />
                                        <Formik
                                            initialValues={{
                                                confirmCode: '',
                                                password: '',
                                                confirmPassword: ''
                                            }}
                                            onSubmit={(values, actions) => {
                                                submitPassword(values, actions);
                                            }}>
                                            {({
                                                values,
                                                errors,
                                                handleSubmit
                                            }) => (
                                                <Form className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                                                    <Field
                                                        className="col-span-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                        type="text"
                                                        name="confirmCode"
                                                        placeholder="Confirmation Code"
                                                    />
                                                    <Field
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                        type="password"
                                                        name="password"
                                                        placeholder="New Password"
                                                        validate={
                                                            validatePassword
                                                        }
                                                    />
                                                    <Field
                                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                        type="password"
                                                        name="confirmPassword"
                                                        placeholder="Confirm New Password"
                                                        validate={value =>
                                                            validateConfirmPassword(
                                                                values.password,
                                                                value
                                                            )
                                                        }
                                                    />
                                                    {errors.password && (
                                                        <div className="text-red-500 col-span-2">
                                                            {errors.password}
                                                        </div>
                                                    )}
                                                    {errors.confirmPassword &&
                                                        !errors.password && (
                                                            <div className="text-red-500 col-span-2">
                                                                {
                                                                    errors.confirmPassword
                                                                }
                                                            </div>
                                                        )}
                                                    <button
                                                        className="col-span-2 font-semibold place-self-center h-10 py-2 px-4 bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white w-1/4 transition
                                                        ease-in duration-200 text-center text-base font-semiboldshadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                                                        type="submit"
                                                        name="submit"
                                                        onClick={handleSubmit}>
                                                        {subLoading ? (
                                                            <div className="flex items-center justify-center">
                                                                <div className="loading-spinner"></div>
                                                            </div>
                                                        ) : (
                                                            'Set password'
                                                        )}
                                                    </button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            </div>
                            {userGroup.includes('client') ? (
                                <>
                                    <div className="w-full px-4 pt-4 border-t">
                                        <h3 className="text-lg font-semibold text-gray-600">
                                            Subscription: {'Tier ' + userTier}
                                        </h3>
                                        <p className="text-gray-400 font-normal">
                                            {unitCount + ' units this period'}
                                        </p>
                                        {subInfo &&
                                        subInfo.status === 'active' ? (
                                            <>
                                                {subInfo.cancel_at_period_end ? (
                                                    <span className="text-red-500 font-semibold">
                                                        {'Your subscription will end and you will be charged ' +
                                                            getPriceFromUnits(
                                                                unitCount
                                                            ).toLocaleString(
                                                                'en-US',
                                                                {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'USD'
                                                                }
                                                            ) +
                                                            ' on ' +
                                                            new Date(
                                                                subInfo.current_period_end *
                                                                    1000
                                                            ).toLocaleDateString() +
                                                            '.'}
                                                    </span>
                                                ) : (
                                                    <span className="text-gray-600 font-semibold">
                                                        {'Your subscription will renew and you will be charged ' +
                                                            getPriceFromUnits(
                                                                unitCount
                                                            ).toLocaleString(
                                                                'en-US',
                                                                {
                                                                    style: 'currency',
                                                                    currency:
                                                                        'USD'
                                                                }
                                                            ) +
                                                            ' on ' +
                                                            new Date(
                                                                subInfo.current_period_end *
                                                                    1000
                                                            ).toLocaleDateString() +
                                                            '.'}
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            'You are not subscribed.'
                                        )}
                                    </div>
                                    <div className="flex flex-auto px-4 text-gray-500 text-center justify-center gap-4">
                                        {subInfo &&
                                        subInfo.cancel_at_period_end ? (
                                            <button
                                                type="button"
                                                // navigate to the pricing page
                                                onClick={() => {
                                                    window.location.href =
                                                        '/pricing';
                                                }}
                                                className="h-10 py-2 mt-2 lg:mt-0 lg:px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in
                                                    duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                                Renew subscription
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setUpdatePaymentModal(true);
                                                }}
                                                className="h-10 py-2 mt-2 lg:mt-0 lg:px-4 bg-blue-600 hover:bg-blue-700 focus:ring-offset-blue-500 focus:ring-blue-200 text-white w-full transition ease-in
                                                    duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                                {updateLoading ? (
                                                    <div className="flex items-center justify-center">
                                                        <div className="loading-spinner"></div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {noPaymentMethod
                                                            ? 'Add payment method'
                                                            : 'Update payment method'}
                                                    </>
                                                )}
                                            </button>
                                        )}
                                    </div>
                                    {subInfo.cancel_at_period_end ||
                                    subInfo.status !== 'active' ? (
                                        <div className="pb-1"></div>
                                    ) : (
                                        <div className="flex flex-auto px-4 pb-4 text-gray-500 text-center justify-center">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setVerifyModal(true);
                                                }}
                                                className="col-span-2 h-10 mx-auto w-max flex place-self-center items-center px-6 py-1 transition ease-in duration-200 rounded-full text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white border-2 border-red-500 focus:outline-none">
                                                {subLoading ? (
                                                    <div className="flex items-center justify-center">
                                                        <div className="loading-spinner"></div>
                                                    </div>
                                                ) : (
                                                    'Cancel subscription'
                                                )}
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="w-full pb-1"></div>
                            )}
                        </div>
                    </div>
                </section>
            )}
            {emailModal && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-96 my-6 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Confirm email
                                    </h3>
                                </div>
                                {/* body */}
                                <div className="flex items-center justify-end p-4 rounded-b">
                                    <p>
                                        Are you sure you want to change your
                                        email to{' '}
                                        <span className="font-semibold underline">
                                            {emailInput.current.value}
                                        </span>
                                        ? You will be signed out. If you have
                                        entered the wrong email you could be
                                        locked out of your account.
                                    </p>
                                </div>
                                {/* footer */}
                                <div className="flex items-center gap-4 justify-end p-4 rounded-b">
                                    <button
                                        className="py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                        type="button"
                                        onClick={() => {
                                            setEmailModal(false);
                                        }}>
                                        Cancel
                                    </button>
                                    <button
                                        className="py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in
                                                duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                        type="button"
                                        onClick={() => {
                                            handleResetEmail();
                                        }}>
                                        {subLoading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="loading-spinner"></div>
                                            </div>
                                        ) : (
                                            'Confirm email'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
            {verifyModal && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-96 my-6 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold">
                                        Cancel Subscription
                                    </h3>
                                </div>
                                {/* body */}
                                <div className="flex items-center justify-end p-4 rounded-b">
                                    <p>
                                        Are you sure you want to cancel your
                                        subscription? You will lose access to
                                        your account.
                                    </p>
                                </div>
                                {/* footer */}
                                <div className="flex items-center gap-4 justify-end p-4 rounded-b">
                                    <button
                                        className="py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                        type="button"
                                        onClick={() => {
                                            setVerifyModal(false);
                                        }}>
                                        Exit
                                    </button>
                                    <button
                                        className="py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in
                                                duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                        type="button"
                                        onClick={() => {
                                            handleCancelSubscription();
                                        }}>
                                        {subLoading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="loading-spinner"></div>
                                            </div>
                                        ) : (
                                            'Confirm'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
            {updatePaymentModal && (
                <div className="relative">
                    <PaymentUpdate
                        setUpdatePaymentModal={setUpdatePaymentModal}
                        setUpdateLoading={setUpdateLoading}
                    />
                </div>
            )}
        </div>
    );
}

export default Account;
