import { API, Auth } from 'aws-amplify';
import { IconAlertTriangle, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/user-context';

function GlobalBanner() {
    const navigate = useNavigate();
    const { userGroup } = useUser();
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (userGroup.includes('client')) {
                const authUser = await Auth.currentAuthenticatedUser();
                const token = authUser.signInUserSession.idToken.jwtToken;

                const requestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    }
                };
                // get stripe payment method status from amplify api
                API.get('MyAMZPrepStoreAPI', '/store/customer', requestInfo)
                    .then(response => {
                        if (
                            response &&
                            response.invoice_settings &&
                            !response.invoice_settings.default_payment_method
                        ) {
                            setShowBanner(true);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {showBanner && (
                <div className="w-full mx-auto mb-9 h-10">
                    <div className="py-1 px-2 rounded-b-lg bg-red-600 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                            <div
                                className="w-0 flex-1 flex items-center cursor-pointer hover:text-underline "
                                onClick={() => navigate('/account')}>
                                <span className="flex p-2 rounded-lg">
                                    <IconAlertTriangle color={'white'} />
                                </span>
                                <p className="text-underline ml-3 font-medium text-white truncate">
                                    <span className="hover:underline hidden md:inline">
                                        You do not currently have a payment
                                        method on file. Please add a payment
                                        method or your subscription could be
                                        cancelled.
                                    </span>
                                </p>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                <button
                                    type="button"
                                    className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={() => setShowBanner(false)}>
                                    <span className="sr-only">Dismiss</span>
                                    <IconX color={'white'} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default GlobalBanner;
