import PropTypes from 'prop-types';
import React from 'react';

const getStyles = status => {
    switch (status) {
        case 'Order paid':
            return 'ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800';
        case 'Payment due':
            return 'ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-700';
        case 'Overdue':
            return 'ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800';
        default:
            return '';
    }
};

function PaymentsBadge({ status }) {
    return (
        <div className="flex flex-wrap items-center gap-4">
            <div className={`${getStyles(status)}`}>{status}</div>
        </div>
    );
}

PaymentsBadge.propTypes = {
    status: PropTypes.string.isRequired
};

export default PaymentsBadge;
