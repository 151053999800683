import { IconX } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

const getBgStyle = type => {
    switch (type) {
        case 'error':
            return 'py-1 px-2 rounded-lg bg-red-600 shadow-lg sm:p-3';
        default:
            return 'py-1 px-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3';
    }
};

function Banner({ icon, type, text, setShowBanner }) {
    return (
        <div className="w-full mx-auto my-4 px-2 sm:px-6 lg:px-8 h-10">
            <div className={getBgStyle(type)}>
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg">{icon}</span>
                        <p className="ml-3 font-medium text-white truncate">
                            <span className="hidden md:inline">{text}</span>
                        </p>
                    </div>
                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                        <button
                            type="button"
                            className="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setShowBanner(false)}>
                            <span className="sr-only">Dismiss</span>
                            <IconX color={'white'} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

Banner.propTypes = {
    icon: PropTypes.element,
    setShowBanner: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string
};

export default Banner;
