function isItemOversized(dimensions, weight) {
    if (!dimensions || !weight) {
        console.error('Missing dimensions or weight for oversized item check.');
        return false;
    }
    // Destructure dimensions object into length, width, height
    const { length, width, height } = dimensions;

    // Convert weight to pounds for consistent comparison (the weight parameter is assumed to be in pounds)
    const weightInPounds = weight;

    // Ensure correct order of longest, median, shortest side
    const sortedDimensions = [length, width, height].sort((a, b) => b - a);
    const [longest, median, shortest] = sortedDimensions;

    // Check for Small standard-size (not oversized)
    if (
        weightInPounds <= 1 &&
        longest <= 15 &&
        median <= 12 &&
        shortest <= 0.75
    ) {
        return false; // Not oversized because it fits within the Small standard-size tier
    }

    // Check for Large standard-size (not oversized)
    if (
        weightInPounds <= 20 &&
        longest <= 18 &&
        median <= 14 &&
        shortest <= 8
    ) {
        return false; // Not oversized
    }

    // All other conditions are considered oversized
    return true;
}

export default isItemOversized;
