// return the correct tier based on the number of units passed
export default function getTierFromUnits(units) {
    if (units <= 2000) {
        return 1;
    }
    if (units <= 5000) {
        return 2;
    } else {
        return 3;
    }
}
