import { API, Auth } from 'aws-amplify';
import {
    Body,
    Cell,
    Header,
    HeaderCell,
    HeaderRow,
    Row,
    Table
} from '@table-library/react-table-library/table';
import {
    CellSelect,
    SelectClickTypes,
    useRowSelect
} from '@table-library/react-table-library/select';
import {
    CellTree,
    TreeExpandClickTypes,
    useTree
} from '@table-library/react-table-library/tree';
import {
    HeaderCellSort,
    useSort
} from '@table-library/react-table-library/sort';
import {
    IconChevronLeft,
    IconChevronRight,
    IconChevronsLeft,
    IconChevronsRight,
    IconNewSection,
    IconNote,
    IconPencil,
    IconPlus
} from '@tabler/icons-react';
import React, { useContext, useRef } from 'react';
import formatDate from '../utils/formatDate';
import NotesList from './NotesList';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import StatusBadge from './status-badge';
import { usePagination } from '@table-library/react-table-library/pagination';

import { useTheme } from '@table-library/react-table-library/theme';

function DataTable({
    nodes,
    search,
    selectedRows,
    setAddonForm,
    setCreateOrder,
    setNotesForm,
    setOrderAddons,
    setOrderLoading,
    setProductID,
    setProductNotes,
    setQuickAddForm,
    setSelectedRows,
    setShowMergeButton,
    setShowOrderPage
}) {
    let data = { nodes };

    const [showAll, setShowAll] = React.useState(false);
    const [isHidden, setHidden] = React.useState(true);
    const paginationInput = useRef(null);
    const [toggleTree, setToggleTree] = React.useState(false);
    const { setOrderInfo, setProducts } = useContext(orderContext);

    const onSelectChange = (action, state) => {
        if (action.type === 'ADD_BY_ID_RECURSIVELY') {
            // adds selected rows state
            state.ids.forEach(id => {
                const searchRes = data.nodes.find(item => item.id === id);
                if (searchRes) {
                    setSelectedRows([...selectedRows, searchRes]);
                }
            });

            if (selectedRows.length > 0) {
                setShowMergeButton(true);
            }
        } else if (action.type === 'REMOVE_BY_ID_RECURSIVELY') {
            if (selectedRows.length <= 2) {
                setShowMergeButton(false);
            }

            let tempArr = selectedRows;

            action.payload.ids.forEach(id => {
                tempArr = tempArr.filter(item => item.id !== id);
            });

            setSelectedRows(tempArr);
        } else if (action.type === 'ADD_BY_ID_EXCLUSIVELY') {
            const searchRes = data.nodes.find(item => item.id === state.id);
            if (!searchRes) {
                // Search products of orders
                data.nodes.forEach(order => {
                    if (order.nodes) {
                        const productSearchRes = order.nodes.find(
                            product => product.id === state.id
                        );
                        if (productSearchRes) {
                            setSelectedRows([order]);
                            setShowOrderPage(true);
                        }
                    }
                });
                return;
            }
            setSelectedRows([searchRes]);
            setShowOrderPage(true);
        }
    };

    const select = useRowSelect(
        data,
        {
            onChange: onSelectChange
        },
        {
            clickType: SelectClickTypes.RowClick
        }
    );

    const onTreeChange = (action, state) => {
        console.log(action, state);
    };

    const tree = useTree(
        data,
        {
            onChange: onTreeChange
        },
        {
            clickType: TreeExpandClickTypes.ButtonClick
        }
    );

    const searchTable = searchData => {
        if (search === '') {
            // Hides products when not searching
            if (toggleTree) {
                setToggleTree(false);
                tree.fns.onToggleAll();
            }
            return searchData.nodes.filter(item =>
                !isHidden ? true : item.Status !== 'Picked Up By Carrier'
            );
        }

        // Expands products during search
        if (!toggleTree) {
            setToggleTree(true);
            tree.fns.onToggleAll();
        }

        // Searches products
        const arr = searchData.nodes
            .map(item => ({
                ...item,
                nodes: item?.nodes?.filter(
                    node =>
                        node.ProductName.toLowerCase().includes(
                            search.toLowerCase()
                        ) ||
                        node.ASIN.toLowerCase().includes(search.toLowerCase())
                )
            }))
            .filter(item => item?.nodes?.length > 0);

        // Searches orders
        const newArr = searchData.nodes.filter(
            item =>
                item.Distributor.toLowerCase().includes(search.toLowerCase()) ||
                item.StoreName.toLowerCase().includes(search.toLowerCase())
        );

        const ids = [];

        // Combines the two arrays and remove duplicates
        const retArr = [...arr, ...newArr].filter(item => {
            const isDupe = ids.includes(item.id);

            if (!isDupe) {
                ids.push(item.id);
                return true;
            }

            return false;
        });

        // Filters out inactive orders
        const filteredArr = retArr.filter(item =>
            !isHidden ? true : item.Status !== 'Picked Up By Carrier'
        );

        return filteredArr;
    };

    data = {
        nodes: searchTable(data)
    };
    const editOrder = async item => {
        const user = await Auth.currentAuthenticatedUser();

        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            }
        };

        if (item.nodes && item.nodes.length > 0) {
            const orderID = item.id;
            setOrderLoading(true);
            await API.get('myamzprepfixapi', `/order/${orderID}`, requestInfo)
                .then(res => {
                    setProducts(res.nodes);
                    setOrderInfo(res);
                    setOrderLoading(false);
                })
                .catch(err => console.log(err));

            // This is for filling the order info with the frontend data before getting the backend data.
            // Could use for the loading state. If not the fields will be empty.
            nodes.forEach(dataItem => {
                if (dataItem.id === item.id) {
                    setProducts(dataItem.nodes);
                    setOrderInfo(item);
                }
            });
        } else if (item.parentNode) {
            await API.get(
                'myamzprepfixapi',
                `/order/${item.parentNode.id}`,
                requestInfo
            )
                .then(res => {
                    const newRes = res;
                    newRes.parentNode = item.parentNode.parentNode;
                    setOrderInfo(res);
                    setProductID(item.ProductID);
                    setProducts(res.nodes);
                    res.nodes.forEach(product => {
                        if (product.ProductID === item.ProductID) {
                            console.log(product.Notes);
                            setProductNotes(product.Notes);
                        }
                    });
                })
                .catch(err => console.log(err));

            // This is for filling notes with the frontend data before getting the backend data.
            // Could use for the loading state. If not the fields will be empty.
            setProducts(item.parentNode.nodes);
            setOrderInfo(item.parentNode);
            setProductID(item.id);
            setProductNotes(item.Notes);
        }
    };

    const handleQuickAdd = (item, type) => {
        setProducts(item.parentNode.nodes);
        setOrderInfo(item.parentNode);
        setProductID(item.ProductID);
        setProductNotes(item.Notes);
        setQuickAddForm(type);
    };

    const handleAddons = item => {
        setProducts(item.parentNode.nodes);
        setOrderInfo(item.parentNode);
        setProductID(item.ProductID);
        setProductNotes(item.Notes);
        if (item.addons && item.addons.length > 0) {
            setOrderAddons(item.addons);
        }
        setAddonForm(true);
    };

    const onSortChange = (action, state) => {
        console.log(action, state);
    };

    /* const notesNotEmpty = item => {
        if (item.parentNode) {
        }

        if (item.Notes && item.Notes !== '') {
            return true;
        }
        return false;
    }; */

    const badgeSortOrder = {
        'Attention Required': 1,
        'Batch Asgmt Needed': 2,
        Inbound: 3,
        'Partially Received': 4,
        Received: 5,
        'Quality Control Check': 6,
        Prepping: 7,
        'Awaiting Carrier Pickup': 8,
        'Picked Up By Carrier': 9
    };

    const sort = useSort(
        data,
        {
            state: {
                sortKey: 'Date',
                reverse: false
            },
            onChange: onSortChange
        },
        {
            sortFns: {
                Date: array =>
                    array.sort((a, b) => {
                        if (a.CreatedAt && b.CreatedAt) {
                            return a.CreatedAt.localeCompare(b.CreatedAt);
                        } else {
                            return -1;
                        }
                    }),
                StoreName: array =>
                    array.sort((a, b) => {
                        if (a.StoreName && b.StoreName) {
                            return a.StoreName.localeCompare(b.StoreName);
                        } else {
                            return -1;
                        }
                    }),
                Distributor: array =>
                    array.sort((a, b) => {
                        if (a.Distributor && b.Distributor) {
                            return a.Distributor.localeCompare(b.Distributor);
                        } else {
                            return -1;
                        }
                    }),
                Quantity: array =>
                    array.sort((a, b) => {
                        if (a.Quantity && b.Quantity) {
                            return a.Quantity - b.Quantity;
                        } else {
                            return -1;
                        }
                    }),
                QuantityReceived: array =>
                    array.sort((a, b) => {
                        if (a.QuantityReceived && b.QuantityReceived) {
                            return a.QuantityReceived - b.QuantityReceived;
                        } else {
                            return -1;
                        }
                    }),
                COG: array =>
                    array.sort((a, b) => {
                        if (a.COG && b.COG) {
                            return a.COG - b.COG;
                        } else {
                            return -1;
                        }
                    }),
                Status: array =>
                    array.sort(
                        (a, b) =>
                            badgeSortOrder[a.Status] - badgeSortOrder[b.Status]
                    )
            }
        }
    );

    const theme = useTheme({
        Table: `
            --data-table-library_grid-template-columns:  minmax(18px, .07fr) minmax(110px, .9fr) minmax(70px, .33fr) minmax(80px, .43fr) minmax(110px, 1.6fr) minmax(100px, 1fr) minmax(46px, .25fr) minmax(67px, .33fr)
                                                         minmax(60px, .35fr) minmax(50px, .28fr) minmax(200px, .85fr) minmax(32px, .1fr);
        `,
        Row: `
        &:hover .td {
            background-color: #f5f5f5;
        }
        `
    });

    const pageSize = 14;

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: pageSize
        }
    });

    const handleUserInputPagination = e => {
        if (e.key === 'Enter') {
            if (pagination.state.getTotalPages(data.nodes) >= e.target.value) {
                pagination.fns.onSetPage(e.target.value - 1);
            }
        }
    };

    return (
        <>
            <br />
            <Table
                className="lg:mx-8 shadow rounded-t-lg"
                data={data}
                select={select}
                sort={sort}
                tree={tree}
                theme={theme}
                pagination={pagination}
                layout={{ custom: true, horizontalScroll: true }}>
                {tableList => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell />
                                <HeaderCellSort
                                    resize
                                    sortKey="StoreName"
                                    className="pl-2 py-4">
                                    Store Name
                                </HeaderCellSort>
                                <HeaderCellSort sortKey="Date" resize>
                                    Date
                                </HeaderCellSort>
                                <HeaderCell resize>ASIN</HeaderCell>
                                <HeaderCell resize>Product Name</HeaderCell>
                                <HeaderCellSort resize sortKey="Distributor">
                                    Distributor
                                </HeaderCellSort>
                                <HeaderCellSort resize sortKey="Quantity">
                                    #
                                </HeaderCellSort>
                                <HeaderCellSort
                                    resize
                                    sortKey="QuantityReceived">
                                    # Rec
                                </HeaderCellSort>
                                <HeaderCellSort resize sortKey="COG">
                                    COG
                                </HeaderCellSort>
                                <HeaderCell>Notes</HeaderCell>
                                <HeaderCellSort sortKey="Status">
                                    Status
                                </HeaderCellSort>
                                <HeaderCell></HeaderCell>
                            </HeaderRow>
                        </Header>
                        <Body>
                            {tableList.map(item => (
                                <Row key={item.id} item={item}>
                                    <CellSelect
                                        className="border-t pl-1"
                                        item={item}
                                    />
                                    <CellTree
                                        item={item}
                                        className="border-t pl-2">
                                        {item.ancestors.length === 1 &&
                                            item.StoreName}
                                    </CellTree>
                                    <Cell className="border-t">
                                        {formatDate(item.CreatedAt)}
                                    </Cell>
                                    <Cell className="border-t">
                                        <a
                                            href={`https://amazon.com/dp/${item.ASIN.split(
                                                ' '
                                            ).join('')}`}
                                            target="_blank"
                                            rel="noreferrer">
                                            <span className="underline hover:decoration-2 underline-offset-1">
                                                {item.ASIN}
                                            </span>
                                        </a>
                                    </Cell>
                                    <Cell className="border-t text-[15px]">
                                        {item.ProductName}
                                    </Cell>
                                    <Cell className="border-t">
                                        {item.ancestors.length === 1 &&
                                            item.Distributor}
                                    </Cell>
                                    <Cell
                                        className="border-t cursor-pointer"
                                        onClick={() => {
                                            handleQuickAdd(item, 'quantity');
                                        }}>
                                        {item.Quantity}
                                    </Cell>
                                    <Cell
                                        className="border-t cursor-pointer"
                                        onClick={() => {
                                            handleQuickAdd(
                                                item,
                                                'quantityReceived'
                                            );
                                        }}>
                                        {item.QuantityReceived}
                                    </Cell>
                                    <Cell className="border-t">
                                        {item.COG$ === ''
                                            ? ''
                                            : item.COG$
                                            ? parseFloat(
                                                  item.COG$
                                              ).toLocaleString('en-US', {
                                                  style: 'currency',
                                                  currency: 'USD'
                                              })
                                            : ''}
                                    </Cell>
                                    <Cell className="border-t">
                                        <div className="relative flex">
                                            <button
                                                className="hover:cursor-pointer peer"
                                                onClick={() => {
                                                    editOrder(item);
                                                    setNotesForm(true);
                                                    console.log(item);
                                                }}>
                                                {item.Notes &&
                                                item.Notes.length > 0 ? (
                                                    <IconNote />
                                                ) : (
                                                    <IconPlus />
                                                )}
                                            </button>
                                            {item.Notes &&
                                                item.Notes.length > 0 && (
                                                    <div className="peer-hover:block hover:block p-2 bg-white z-50 hidden fixed shadow-lg rounded -translate-x-[100%] -translate-y-[50%]">
                                                        <NotesList
                                                            notes={item.Notes}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </Cell>
                                    <Cell className="border-t">
                                        {item.ancestors.length === 1 && (
                                            <StatusBadge
                                                status={item.Status}
                                                orderID={item.OrderID}
                                                storeID={item.UserID}
                                                units={item}
                                            />
                                        )}
                                    </Cell>
                                    <Cell className="border-t">
                                        <button
                                            className="hover:cursor-pointer"
                                            onClick={() => {
                                                handleAddons(item);
                                            }}>
                                            {item.ancestors.length !== 1 && (
                                                <IconNewSection />
                                            )}
                                        </button>
                                        <button
                                            onClick={() => {
                                                editOrder(item);
                                                setCreateOrder(true);
                                            }}>
                                            {item.ancestors.length === 1 && (
                                                <IconPencil />
                                            )}
                                        </button>
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
            <hr className="lg:mx-8" />
            <div className="lg:mx-8 px-5 relative bg-white py-3 flex flex-col xs:flex-row items-center xs:justify-between shadow rounded-b-lg">
                <span
                    className="absolute cursor-pointer left-1 top-0 text-md underline"
                    onClick={() => {
                        if (!showAll) {
                            pagination.fns.onSetSize(nodes.length);
                        } else {
                            pagination.fns.onSetSize(pageSize);
                        }
                        setShowAll(!showAll);
                    }}>
                    {nodes.length > pageSize
                        ? showAll
                            ? 'Show less'
                            : 'Show all'
                        : ''}
                </span>
                <div className="absolute right-1 top-0 flex items-center">
                    <input
                        type="checkbox"
                        className="mr-2"
                        onClick={() => {
                            setHidden(!isHidden);
                        }}
                    />
                    <span>Show Picked Up</span>
                </div>
                <div className="flex items-center mt-6 lg:mt-0">
                    <button
                        type="button"
                        disabled={pagination.state.page === 0}
                        onClick={() => {
                            paginationInput.current.value = null;
                            pagination.fns.onSetPage(0);
                        }}
                        className="w-full p-3 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400">
                        <IconChevronsLeft size={16} stroke={2.5} />
                    </button>
                    <button
                        type="button"
                        disabled={pagination.state.page === 0}
                        onClick={() => {
                            paginationInput.current.value = null;
                            pagination.fns.onSetPage(pagination.state.page - 1);
                        }}
                        className="w-full p-3 border-t border-r border-b text-base text-gray-600 bg-white hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400">
                        <IconChevronLeft size={16} stroke={2.5} />
                    </button>
                    <button
                        type="button"
                        className="w-full py-2 border-t border-b border-r text-base text-gray-600 bg-white hover:bg-gray-100">
                        <input
                            className="w-[40px] text-center placeholder:text-gray-600"
                            ref={paginationInput}
                            onKeyDown={handleUserInputPagination}
                            placeholder={pagination.state.page + 1}></input>
                    </button>
                    <button
                        type="button"
                        disabled={
                            pagination.state.page + 1 ===
                            pagination.state.getTotalPages(data.nodes)
                        }
                        onClick={() => {
                            paginationInput.current.value = null;
                            pagination.fns.onSetPage(pagination.state.page + 1);
                        }}
                        className="w-full p-3 border-t border-b border-r text-base  text-gray-600 bg-white hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400">
                        <IconChevronRight size={16} stroke={2.5} />
                    </button>
                    <button
                        type="button"
                        disabled={
                            pagination.state.page + 1 ===
                            pagination.state.getTotalPages(data.nodes)
                        }
                        onClick={() => {
                            paginationInput.current.value = null;
                            pagination.fns.onSetPage(
                                pagination.state.getTotalPages(data.nodes) - 1
                            );
                        }}
                        className="w-full p-3 border-t border-b border-r text-base  rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 disabled:bg-gray-100 disabled:text-gray-400">
                        <IconChevronsRight size={16} stroke={2.5} />
                    </button>
                </div>
            </div>
        </>
    );
}

DataTable.propTypes = {
    nodes: PropTypes.array,
    search: PropTypes.string,
    selectedRows: PropTypes.array,
    setAddonForm: PropTypes.func,
    setCreateOrder: PropTypes.func,
    setNotesForm: PropTypes.func,
    setOrderAddons: PropTypes.func,
    setOrderLoading: PropTypes.func,
    setProductID: PropTypes.func,
    setProductNotes: PropTypes.func,
    setQuickAddForm: PropTypes.func,
    setSelectedRows: PropTypes.func,
    setShowMergeButton: PropTypes.func,
    setShowOrderPage: PropTypes.func
};

export default DataTable;
