import PropTypes from 'prop-types';
import React from 'react';
import UserBadge from './user-badge';

export default function NotesList({ notes }) {
    if (!notes || notes.length === 0) {
        return <p className="text-center py-2">This item has no notes.</p>;
    }

    return (
        <div>
            {Array.isArray(notes) &&
                notes.map((note, index) => (
                    <div key={index}>
                        <div className="px-5">
                            <div className="mt-1 mb-2">
                                <span className="mr-1">
                                    <UserBadge
                                        userType={
                                            note.isAdmin ? 'Admin' : 'Client'
                                        }
                                    />
                                </span>
                                <span className="text-sm mr-1">
                                    {note.userName}
                                </span>

                                <span className="text-sm text-gray-500">
                                    {new Date(note.timestamp).toLocaleString()}
                                </span>
                            </div>
                            <div className="mb-1">
                                <p className="max-w-3xl block whitespace-pre-wrap">
                                    {note.note}
                                </p>
                            </div>
                        </div>
                        {index !== notes.length - 1 && <hr />}
                    </div>
                ))}
        </div>
    );
}

NotesList.propTypes = {
    notes: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func
    })
};
