import { API, Auth } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import {
    IconPencil,
    IconPlus,
    IconTableImport,
    IconTrash,
    IconUpload,
    IconUser
} from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import createExcelTemplate from '../utils/createExcelTemplate';
import importOrdersExcel from '../utils/importOrdersExcel';
import { orderContext } from '../contexts/order-context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useUser } from '../contexts/user-context';

function OrderForm({
    closeModal,
    customerID,
    customerName = null,
    isUpdate, // state for if the form is update or create
    notesForm, // state for displaying the notes form
    orderLoading,
    productID, // stores the id of a product for the notes form
    productNotes, // stores the text from a product's notes field
    refreshTable, // refreshes the table after an order is updated
    rows,
    setNotesForm,
    setProductID,
    setRows
}) {
    // Form states
    const [showProductForm, setShowProductForm] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [buttonType, setButtonType] = useState('order');
    const [pLoading, setPLoading] = useState(false);
    const [dLoading, setDLoading] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    const { order, setOrderInfo, products, setProducts } =
        useContext(orderContext);
    const { user, userGroup, userID } = useUser();

    useEffect(() => {
        if (customerID === null) {
            return;
        }
    }, [customerID]);

    const deleteOrder = async () => {
        setDLoading(true);
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                orderID: order.OrderID,
                userID: order.UserID
            }
        };
        API.del('myamzprepfixapi', '/orders', requestInfo)
            .then(response => {
                console.log(response);
                toast.success('Order deleted');
                setRows(rows.filter(row => row.OrderID !== order.OrderID));
                setDLoading(false);
                setOrderInfo(false);
                setProducts([]);
                closeModal();
            })
            .catch(error => {
                console.log(error);
                setDLoading(false);
                setOrderInfo(false);
                setProducts([]);
                closeModal();
                toast.error('There was an error deleting your order.');
            });
    };

    const clearProductForm = () => {
        setOrderInfo({
            ...order,
            ProductName: '',
            ASIN: '',
            MultiPack: '',
            Quantity: '',
            QuantityReceived: '',
            COG$: ''
        });
    };

    const addUpdateProduct = product => {
        // If ASIN is blank, then this is a new product
        if (order.ASIN === '') {
            clearProductForm();

            // add a random id to the product
            const id = Math.random().toString(36).substring(7);
            product.ProductID = id;
            setProducts([product, ...products]);
        } else {
            if (product.ProductID === '') {
                product.ProductID = Math.random().toString(36).substring(7);
            }

            // Remove product with same id from products array
            const newProducts = products.filter(
                item => item.ProductID !== product.ProductID
            );

            // Add product to products array
            setProducts([product, ...newProducts]);

            clearProductForm();
        }
    };

    const createUpdateOrder = async (distributor, storeName, notes) => {
        setPLoading(true);
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;

        const reqStoreName = userGroup.includes('admin')
            ? storeName
            : user.name;

        // Chunks an array into specified size
        function chunkArray(array, size) {
            const result = [];
            for (let i = 0; i < array.length; i += size) {
                result.push(array.slice(i, i + size));
            }
            return result;
        }

        // Change this size value to modify the number of products allowed
        const chunkedProducts = chunkArray(products, 20);

        const createOrderForProducts = async (
            productsChunk,
            distributorSuffix
        ) => {
            const orderReq = {
                ASIN: '',
                COG$: '',
                Distributor:
                    distributor +
                    (distributorSuffix ? ` #${distributorSuffix}` : ''),
                ProductName: '',
                Quantity: '',
                QuantityReceived: '',
                MultiPack: '',
                Status: 'Inbound',
                StoreName: reqStoreName || '',
                Notes: notes || '',
                nodes: productsChunk.map(product => ({
                    ...product,
                    Distributor:
                        distributor +
                        (distributorSuffix ? ` #${distributorSuffix}` : ''),
                    CreatedAt: new Date().toISOString(),
                    OrderID: '',
                    UserID: ''
                }))
            };

            const updateOrderReq = {
                CreatedAt: order ? order.CreatedAt : new Date().toISOString(),
                OrderID: order ? order.OrderID : '',
                UserID: order ? order.UserID : '',
                UserFullName: order ? order.UserFullName : '',
                ASIN: '',
                COG$: '',
                Distributor:
                    distributor +
                    (distributorSuffix ? ` #${distributorSuffix}` : ''),
                Notes: order ? (notes === '' ? order.Notes : notes) : '',
                ProductName: '',
                Quantity: '',
                QuantityReceived: '',
                MultiPack: '',
                Status: order ? order.Status : 'Inbound',
                StoreName: order
                    ? reqStoreName === ''
                        ? order.StoreName
                        : reqStoreName
                    : '',
                nodes: productsChunk.map(product => ({
                    ...product,
                    Distributor:
                        distributor +
                        (distributorSuffix ? ` #${distributorSuffix}` : ''),
                    CreatedAt: new Date().toISOString(),
                    OrderID: '',
                    UserID: ''
                }))
            };

            const addRequestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                },
                body: {
                    order: orderReq,
                    username: customerID || userID,
                    fullname: customerName ? customerName : user.name
                }
            };

            if (isUpdate && isUpdate() && distributorSuffix === 1) {
                const updateRequestInfo = {
                    headers: {
                        Authorization: token,
                        Accept: 'application/json, text/plain, */*'
                    },
                    body: {
                        order: updateOrderReq,
                        username: customerID || userID,
                        fullname: customerName ? customerName : user.name
                    }
                };
                await API.patch(
                    'myamzprepfixapi',
                    '/orders',
                    updateRequestInfo
                ).then(res => {
                    toast('Order updated successfully!', {
                        type: 'success',
                        position: 'top-right'
                    });
                    console.log(res);
                });
            } else {
                try {
                    const res = await API.post(
                        'myamzprepfixapi',
                        '/orders',
                        addRequestInfo
                    );

                    if (chunkedProducts.length === 1) {
                        toast('Order created successfully!', {
                            type: 'success',
                            position: 'top-right'
                        });
                    }

                    orderReq.OrderID = res.OrderID;
                    orderReq.UserID = res.UserID;
                    orderReq.CreatedAt = new Date().toISOString();

                    orderReq.nodes.forEach(node => {
                        node.CreatedAt = new Date().toISOString();
                        node.OrderID = res.OrderID;
                        node.UserID = res.UserID;
                    });

                    if (rows) {
                        setRows([orderReq, ...rows]);
                    }
                    console.log(res);
                } catch (err) {
                    console.log(err);
                }
            }
        };

        if (chunkedProducts.length > 0) {
            const promises = chunkedProducts.map((productsChunk, index) => {
                const distributorSuffix =
                    chunkedProducts.length > 1 ? index + 1 : '';
                return createOrderForProducts(productsChunk, distributorSuffix);
            });

            await Promise.all(promises);
            if (chunkedProducts.length > 1) {
                toast('Orders created successfully!', {
                    type: 'success',
                    position: 'top-right',
                    length: 8000
                });
            }
        }

        setPLoading(false);
        setOrderInfo(false);
        closeModal();
        refreshTable();
        setProducts([]);
    };

    const deleteProduct = id => {
        const newProducts = products.filter(item => item.ProductID !== id);
        setProducts(newProducts);
    };

    const getProductNotesFromId = id => {
        const product = products.find(item => item.ProductID === id);
        if (product && product.Notes) {
            return product.Notes;
        } else {
            return '';
        }
    };

    const handleDownloadTemplate = () => {
        const csv = createExcelTemplate();
        // download the csv file
        const element = document.createElement('a');
        const file = new Blob([csv], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = 'template.csv';
        document.body.appendChild(element); // Required for this to work in FireFox apparently
        element.click();
    };

    const handleImportOrdersExcel = () => {
        // allow user to upload excel file
        let file = document.createElement('input');
        file.type = 'file';
        file.accept = '.xlsx, .xls, .csv';
        file.click();
        file.onchange = e => {
            file = e.target.files[0];
            if (!file) {
                return;
            }
            importOrdersExcel(file, setProducts);
            setOrderInfo({
                ...order
            });
        };
    };

    // Returns a map of products state in jsx
    const ProductList = () =>
        products &&
        products.map(product => (
            <div
                className="hover-parent bg-gray-200 rounded relative text-center p-1 mb-1 "
                key={product.ProductID}>
                <p>{product.ProductName}</p>
                <div className="absolute top-1 right-0">
                    <div className="hidden bg-gray-200">
                        <button
                            type="button"
                            onClick={() => {
                                setShowProductForm(true);
                                setOrderInfo({
                                    ...order,
                                    ProductName: product.ProductName,
                                    ASIN: product.ASIN,
                                    Quantity: product.Quantity,
                                    QuantityReceived: product.QuantityReceived,
                                    MultiPack: product.MultiPack,
                                    COG$: product.COG$,
                                    ProductID: product.ProductID,
                                    id: product.ProductID
                                });
                            }}>
                            <IconPencil className="mr-1" />
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                deleteProduct(product.ProductID);
                            }}>
                            <IconTrash />
                        </button>
                    </div>
                </div>
            </div>
        ));

    if (notesForm) {
        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        productName: order?.ProductName || '',
                        ASIN: order?.ASIN || '',
                        distributor: order?.Distributor || '',
                        createdAt: order?.CreatedAt || new Date().toISOString(),
                        quantity: order?.Quantity || '',
                        quantityReceived: order?.QuantityReceived || '',
                        multiPack: order?.MultiPack || '',
                        cog: order?.COG$ || '',
                        storeName: order?.StoreName || '',
                        notes: productID ? productNotes : order?.Notes || '',
                        ProductID: order?.ProductID || '',
                        OrderID: order?.OrderID || '',
                        id: order?.id || ''
                    }}
                    onSubmit={async values => {
                        const userReq = await Auth.currentAuthenticatedUser();
                        const token =
                            userReq.signInUserSession.idToken.jwtToken;
                        setPLoading(true);
                        let newProducts;

                        if (!order) {
                            console.error(
                                'Cannot update order without an order'
                            );
                            return;
                        }
                        if (productID) {
                            newProducts = products.map(product => {
                                if (product.ProductID === productID) {
                                    return {
                                        ...product,
                                        Notes: values.notes
                                    };
                                }
                                return product;
                            });
                            setProducts(newProducts);
                            setProductID(false);
                        }

                        const updateOrderReq = {
                            CreatedAt: order.CreatedAt,
                            OrderID: order.OrderID,
                            UserID: order.UserID,
                            UserFullName: order.UserFullName,
                            ASIN: '',
                            COG$: '',
                            Distributor: order.Distributor,
                            Notes: order
                                ? productID
                                    ? order.Notes
                                    : values.notes
                                : '',
                            ProductName: '',
                            Quantity: '',
                            QuantityReceived: '',
                            MultiPack: '',
                            Status: order.Status,
                            StoreName: order.StoreName,
                            nodes: productID ? newProducts : products
                        };

                        const updateRequestInfo = {
                            headers: {
                                Authorization: token,
                                Accept: 'application/json, text/plain, */*'
                            },
                            body: {
                                order: updateOrderReq
                            }
                        };

                        API.patch(
                            'myamzprepfixapi',
                            '/orders',
                            updateRequestInfo
                        )
                            .then(res => {
                                toast('Order updated successfully!', {
                                    type: 'success',
                                    position: 'top-right'
                                });

                                const tempArr = rows.filter(
                                    row => row.OrderID !== order.OrderID
                                );
                                setRows([updateOrderReq, ...tempArr]);
                                setPLoading(false);
                                closeModal();
                                setNotesForm(false);
                                console.log(res);
                            })
                            .catch(err => {
                                setPLoading(false);
                                closeModal();
                                setNotesForm(false);
                                console.log(err);
                            });
                        clearProductForm();
                        setOrderInfo(false);
                        setProducts([]);
                    }}>
                    <Form className="flex w-full max-w-sm space-x-3">
                        <div className="grid w-[432px] max-w-xl grid-cols-2 gap-4 m-auto">
                            <div className="col-span-2">
                                <Field
                                    disabled={orderLoading}
                                    as="textarea"
                                    htmlFor="name"
                                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    id="notes"
                                    placeholder="Enter your notes here."
                                    name="notes"
                                    rows="5"
                                    cols="40"></Field>
                            </div>
                            <button
                                onClick={() => {
                                    closeModal();
                                    clearProductForm();
                                    setProductID(false);
                                    setNotesForm(false);
                                    setOrderInfo(false);
                                    setProducts([]);
                                }}
                                type="button"
                                className="mt-2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="mt-2 py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white
                                           w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                {pLoading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : (
                                    'Save Notes'
                                )}
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        );
    } else {
        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        productName: order?.ProductName || '',
                        ASIN: order?.ASIN || '',
                        distributor: order?.Distributor || '',
                        createdAt: order?.CreatedAt || new Date().toISOString(),
                        quantity: order?.Quantity || '',
                        quantityReceived: order?.QuantityReceived || '',
                        multiPack: order?.MultiPack || '',
                        cog: order?.COG$ || '',
                        storeName: order?.StoreName || '',
                        notes: order?.Notes || '',
                        ProductID: order?.ProductID || '',
                        OrderID: order?.OrderID || '',
                        id: order?.id || ''
                    }}
                    onSubmit={values => {
                        if (addProduct) {
                            setOrderInfo({
                                ...order,
                                Distributor: values.distributor,
                                StoreName: values.storeName,
                                OrderID: values.OrderID || '',
                                Notes: values.notes
                            });

                            // clearProductForm();
                            setShowProductForm(true);
                            setAddProduct(false);
                            return;
                        }
                        if (buttonType === 'order') {
                            if (products.length < 1) {
                                toast('Please add at least one product.', {
                                    type: 'error',
                                    position: 'top-right'
                                });
                                return;
                            }

                            const updateNotes = order.notes || values.notes;

                            createUpdateOrder(
                                values.distributor,
                                values.storeName,
                                updateNotes
                            );
                        } else {
                            addUpdateProduct({
                                ProductName: values.productName,
                                Distributor: values.distributor,
                                ASIN: values.ASIN,
                                Quantity: values.quantity,
                                CreatedAt: values.createdAt,
                                COG$: values.cog,
                                QuantityReceived: values.quantityReceived,
                                MultiPack: values.multiPack,
                                status: 'Inbound',
                                ProductID: values.ProductID,
                                StoreName: values.storeName,
                                Status: order?.Status || 'Inbound',
                                id: values.id,
                                Notes:
                                    getProductNotesFromId(values.ProductID) ||
                                    ''
                            });

                            values.productName = '';
                            values.ASIN = '';
                            values.quantity = '';
                            values.cog = '';
                            values.quantityReceived = '';
                            setShowProductForm(false);
                            setOrderInfo({
                                ...order,
                                Distributor: values.distributor,
                                StoreName: values.storeName,
                                OrderID: values.OrderID,
                                Notes: order.Notes
                            });
                            clearProductForm();
                        }
                    }}>
                    <Form className="flex w-full max-w-sm space-x-3">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto w-[432px]">
                            {userGroup.includes('admin') &&
                                order &&
                                order.UserFullName && (
                                    <div className="col-span-2">
                                        <div className="flex pb-1">
                                            <IconUser color={'#737373'} />
                                            <span className="text-gray-500 ml-1">
                                                {order.UserFullName}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            {userGroup.includes('admin') && (
                                <div className="col-span-2">
                                    <div className="relative">
                                        <Field
                                            disabled={orderLoading}
                                            type="text"
                                            id="storeName"
                                            name="storeName"
                                            required
                                            className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                            placeholder="Store Name"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-span-2 ">
                                <Field
                                    disabled={orderLoading}
                                    type="text"
                                    id="distributor"
                                    name="distributor"
                                    required
                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    placeholder="Distributor"
                                />
                            </div>
                            <div className="col-span-2">
                                <hr className="w-full" />
                            </div>
                            <div
                                className="col-span-2 justify-center items-center
                            ">
                                {!showProductForm && (
                                    <>
                                        <button
                                            disabled={orderLoading}
                                            type="submit"
                                            onClick={() => {
                                                // clearProductForm();
                                                setAddProduct(true);
                                            }}
                                            title="Add Product"
                                            className="flex items-center p-1 mx-auto -my-2 transition ease-in duration-200 uppercase rounded-full hover:bg-green-500 text-green-500 hover:text-white border-2 border-green-500 focus:outline-none">
                                            <IconPlus size={20} />
                                        </button>
                                        <div className="flex items-center justify-center -ml-20 mt-4">
                                            <button
                                                disabled={orderLoading}
                                                type="button"
                                                id="import-products-button"
                                                onClick={() => {
                                                    handleImportOrdersExcel();
                                                }}
                                                title="Import Products"
                                                className="mt-2 mr-2 lg:w-44 h-[42px] ml-20 pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white
                                     transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                                <IconUpload className="mr-1" />
                                                Import .xlsx / .csv
                                            </button>
                                            <button
                                                type="button"
                                                id="download-template-button"
                                                onClick={() => {
                                                    handleDownloadTemplate();
                                                }}
                                                title="Download a template"
                                                className="mt-2 lg:w-10 h-[42px] pl-2 pr-2 px-4 flex justify-center items-center bg-gray-400 hover:bg-gray-700 focus:ring-gray-200 focus:ring-offset-gray-200 text-white transition ease-in duration-200
                                     text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                                <IconTableImport className="w-6 h-6" />
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* PRODUCT FORM */}
                            {showProductForm && (
                                <>
                                    <div className="col-span-2">
                                        <div className="relative">
                                            <Field
                                                type="text"
                                                id="productName"
                                                name="productName"
                                                required
                                                className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                placeholder="Product Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-2">
                                        <div className=" relative ">
                                            <Field
                                                type="text"
                                                id="ASIN"
                                                name="ASIN"
                                                required
                                                className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                placeholder="ASIN"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-2 lg:col-span-1">
                                        <Field
                                            type="number"
                                            id="quantity"
                                            name="quantity"
                                            required
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                            placeholder="(#) Quantity"
                                        />
                                    </div>
                                    <div className="col-span-2 lg:col-span-1">
                                        <Field
                                            type="number"
                                            id="cog"
                                            name="cog"
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                            placeholder="($) Cost of Goods"
                                        />
                                    </div>
                                    <div className="col-span-2 lg:col-span-1">
                                        <Field
                                            type="number"
                                            id="multiPack"
                                            name="multiPack"
                                            className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                            placeholder="(#) Multi pack"
                                        />
                                    </div>
                                    {userGroup?.includes('admin') ? (
                                        <div className="col-span-2 lg:col-span-1">
                                            <Field
                                                type="number"
                                                id="quantityReceived"
                                                name="quantityReceived"
                                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                                placeholder="(#) Q. Received"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-span-1"></div>
                                    )}
                                    <div className="col-span-1">
                                        <button
                                            onClick={() => {
                                                setShowProductForm(false);
                                                clearProductForm();
                                            }}
                                            type="button"
                                            className="py-2 px-4 lg:w-40 h-[42px] mx-auto flex justify-center items-center bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center
                                                    text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col-span-1">
                                        {(!order.ASIN || order.ASIN === '') && (
                                            <button
                                                disabled={orderLoading}
                                                type="submit"
                                                onClick={() => {
                                                    setButtonType('product');
                                                }}
                                                className="w-full lg:w-40 h-[42px] mx-auto pl-1 pr-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-green-400 focus:ring-offset-green-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                                <IconPlus />
                                                Add product
                                            </button>
                                        )}
                                        {order.ASIN && order.ASIN !== '' && (
                                            <button
                                                type="submit"
                                                onClick={() => {
                                                    setButtonType('product');
                                                }}
                                                className="w-full lg:w-40 h-[42px] mx-auto pl-1 pr-2 px-4 flex justify-center items-center bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200
                            text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                                                Update product
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                            {/* PRODUCT LIST */}
                            <div className="col-span-2 overflow-y-scroll max-h-[160px]">
                                <ProductList />
                            </div>
                            <div className="col-span-2">
                                <hr className="w-full" />
                            </div>
                            <button
                                onClick={() => {
                                    closeModal();
                                    clearProductForm();
                                    setOrderInfo(false);
                                    setProducts([]);
                                }}
                                type="button"
                                className="mt-2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                                Cancel
                            </button>
                            <button
                                disabled={orderLoading}
                                type="submit"
                                onClick={() => {
                                    setButtonType('order');
                                }}
                                className="mt-2 py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white
                                           w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                {pLoading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : isUpdate && isUpdate() ? (
                                    'Update order'
                                ) : (
                                    'Create new order'
                                )}
                            </button>
                            {isUpdate &&
                                userGroup.includes('admin') &&
                                isUpdate() && (
                                    <button
                                        disabled={orderLoading}
                                        type="button"
                                        onClick={() => {
                                            setVerifyModal(true);
                                        }}
                                        className="col-span-2 w-max flex place-self-center items-center px-6 py-1 transition ease-in duration-200 rounded-full text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white border-2 border-red-500 focus:outline-none">
                                        Delete order
                                    </button>
                                )}
                        </div>
                    </Form>
                </Formik>
                <div>
                    {verifyModal ? (
                        <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                    {/* content */}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        {/* header */}
                                        <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                            <h3 className="text-xl font-semibold">
                                                Delete {order.StoreName} order
                                            </h3>
                                            <p className="text-gray-400 text-xs">
                                                {order.productName}
                                            </p>
                                        </div>
                                        {/* body */}
                                        <div className="flex items-center justify-end p-4 rounded-b">
                                            <p>
                                                Are you sure you would like to
                                                permanently delete this order?
                                                This action cannot be undone.
                                            </p>
                                        </div>
                                        {/* footer */}
                                        <div className="flex items-center justify-end p-4 gap-4 rounded-b">
                                            <button
                                                type="button"
                                                className="w-1/2 px-4 py-2 text-base border rounded-lg text-grey-500 bg-white hover:bg-gray-200 "
                                                onClick={() => {
                                                    setVerifyModal(false);
                                                }}>
                                                {' '}
                                                Cancel
                                            </button>
                                            <button
                                                className="w-1/2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                                type="button"
                                                onClick={deleteOrder}>
                                                {dLoading ? (
                                                    <div className="flex items-center justify-center">
                                                        <div className="loading-spinner"></div>
                                                    </div>
                                                ) : (
                                                    'Delete Order'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }
}

OrderForm.propTypes = {
    closeModal: PropTypes.func,
    customerID: PropTypes.string,
    customerName: PropTypes.string,
    isUpdate: PropTypes.func,
    notesForm: PropTypes.bool,
    orderLoading: PropTypes.bool,
    order: PropTypes.any,
    productID: PropTypes.any,
    productNotes: PropTypes.any,
    products: PropTypes.array,
    refreshTable: PropTypes.func,
    rows: PropTypes.array,
    setNotesForm: PropTypes.func,
    setOrderInfo: PropTypes.func,
    setProductID: PropTypes.func,
    setProductNotes: PropTypes.func,
    setProducts: PropTypes.any,
    setRows: PropTypes.func
};

OrderForm.defaultProps = {
    isUpdate: () => false
};

export default OrderForm;
