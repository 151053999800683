import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AccountSkeleton = () => (
    <>
        <section className="bg-gray-100 bg-opacity-50">
            <div className="container max-w-2xl mx-auto shadow-md md:w-3/4">
                <div className="p-4 bg-gray-100 border-t-2 border-indigo-400 rounded-lg bg-opacity-5">
                    <div className="w-full">
                        <div className="flex flex-col">
                            <h2 className="text-gray-600 font-bold">
                                <Skeleton />
                            </h2>
                            <p className="text-gray-600">
                                <Skeleton />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="space-y-6 bg-white">
                    <form className="w-full px-4 pt-4 space-y-4 text-gray-500">
                        <div className="flex flex-col lg:flex-row lg:space-x-4 items-center">
                            <div className="flex-1">
                                <input
                                    type="text"
                                    className="w-full px-4 py-2 border rounded-lg text-gray-600 focus:outline-none focus:border-indigo-400"
                                    placeholder=""
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="w-full px-4 text-gray-500 text-center">
                        <button
                            type="button"
                            className="h-9 py-2 px-4 bg-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                            disabled={true}></button>
                    </div>
                    <div className="w-full px-4 pt-4 border-t">
                        <h3 className="text-lg font-semibold text-gray-600">
                            <Skeleton />
                        </h3>
                        <p className="text-gray-500">
                            <Skeleton />
                        </p>
                        <p className="text-gray-500">
                            <Skeleton />
                        </p>
                    </div>
                    <div className="flex flex-auto px-4 pb-8 text-gray-500 text-center justify-center gap-4">
                        <button
                            type="button"
                            disabled={true}
                            className="h-10 py-2 mt-2 lg:mt-0 lg:px-4 bg-gray-200 text-white w-full transition ease-in
                                    duration-200 text-center text-base font-semibold shadow-md rounded-lg"></button>
                    </div>
                </div>
            </div>
        </section>
    </>
);

export default AccountSkeleton;
