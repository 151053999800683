import { API, Auth } from 'aws-amplify';
import getUnitsFromOrder from '../utils/getUnitsFromOrder';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import useComponentVisible from '../hooks/use-component-visible';
import { useUser } from '../contexts/user-context';

const getStyles = status => {
    switch (status) {
        case 'Inbound':
            return 'px-4 py-1 text-small rounded-full text-blue-600 bg-blue-200 hover:bg-blue-300';
        case 'Partially Received':
            return 'px-4 py-1 text-small rounded-full text-orange-600 bg-orange-200 hover:bg-orange-300';
        case 'Received':
            return 'px-4 py-1 text-small rounded-full text-purple-600 bg-purple-200 hover:bg-purple-300';
        case 'Quality Control Check':
            return 'px-4 py-1 text-small rounded-full text-yellow-600 bg-yellow-200 hover:bg-yellow-300';
        case 'Prepping':
            return 'px-4 py-1 text-small rounded-full text-indigo-600 bg-indigo-200 hover:bg-indigo-300';
        case 'Awaiting Carrier Pickup':
            return 'px-4 py-1 text-small rounded-full text-gray-600 bg-gray-200 hover:bg-gray-300';
        case 'Picked Up By Carrier':
            return 'px-4 py-1 text-small rounded-full text-green-600 bg-green-200 hover:bg-green-300';
        case 'Ordered':
            return 'px-4 py-1 text-small rounded-full text-blue-600 bg-blue-200 hover:bg-blue-300';
        case 'Shipped':
            return 'px-4 py-1 text-small rounded-full text-green-600 bg-green-200 hover:bg-green-300';
        case 'Attention Required':
            return 'px-4 py-1 text-small rounded-full text-red-600 bg-red-200 hover:bg-red-300';
        case 'Batch Asgmt Needed':
            return 'px-4 py-1 text-small rounded-full text-red-600 bg-red-200 hover:bg-red-300';
        default:
            return '';
    }
};

function getUnits(item) {
    let total = 0;
    item.parentNode.nodes.forEach(node => {
        if (item.id === node.id) {
            total = getUnitsFromOrder(node);
        }
    });
    return total;
}

function StatusBadge({ status, orderID, storeID, units }) {
    const [badgeStatus, setBadgeStatus] = React.useState(status);
    const { userGroup } = useUser();
    const { ref, isComponentVisible, setIsComponentVisible } =
        useComponentVisible(false);

    const handleChangeBadge = async changeStatus => {
        if (changeStatus === 'Inbound') {
            return;
        }

        setBadgeStatus(changeStatus);
        setIsComponentVisible(false);
        const totalUnits = getUnits(units);

        if (userGroup.includes('admin')) {
            let user = await Auth.currentSession();
            let token = user.getAccessToken().getJwtToken();

            const adminRequestInfo = {
                queryStringParameters: {
                    username: storeID
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                }
            };
            let userStripeSubscriptionId = '';
            await API.get('AdminQueries', '/getUser', adminRequestInfo)
                .then(response => {
                    for (const attribute of response.UserAttributes) {
                        if (attribute.Name === 'custom:stripeSubscriptionId') {
                            userStripeSubscriptionId = attribute.Value;
                        }
                    }
                })
                .catch(error => {
                    console.log(error.response);
                });
            user = await Auth.currentAuthenticatedUser();
            token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json, text/plain, */*'
                },
                body: {
                    order: {
                        OrderID: orderID,
                        UserID: storeID,
                        Status: changeStatus
                    },
                    oldStatus: status,
                    stripeSubscriptionId: userStripeSubscriptionId,
                    totalUnits: totalUnits
                }
            };
            API.patch('myamzprepfixapi', '/orders', requestInfo)
                .then(res => {
                    console.log(res);
                })
                .catch(err => {
                    toast.error(
                        'There was a problem updating the status of this order.'
                    );
                    console.log(err);
                });
        }
    };

    const showBadges = () => {
        if (userGroup.includes('admin')) {
            setIsComponentVisible(true);
        }
    };

    return (
        <div ref={ref} className="flex flex-wrap items-center gap-4">
            <button
                type="button"
                onClick={() => showBadges()}
                className={`px-4 py-1 text-small rounded-full ${getStyles(
                    badgeStatus
                )}`}>
                {badgeStatus}
            </button>
            {isComponentVisible && (
                <div className="inline-block text-left">
                    <div className="origin-top-left absolute right-0 -mt-6 w-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div
                            className="flex py-1 px-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu">
                            <button
                                type="button"
                                onClick={() => handleChangeBadge('Inbound')}
                                className="w-24 px-4 py-1 text-small rounded-full text-blue-600 bg-blue-200 hover:bg-blue-300">
                                Inbound
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Partially Received')
                                }
                                className="px-4 py-1 text-small rounded-full text-orange-600 bg-orange-200 hover:bg-orange-300">
                                Partially Received
                            </button>
                            <button
                                type="button"
                                onClick={() => handleChangeBadge('Received')}
                                className="w-28 px-4 py-1 text-small rounded-full text-purple-600 bg-purple-200 hover:bg-purple-300">
                                Received
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Quality Control Check')
                                }
                                className="px-4 py-1 text-small rounded-full text-yellow-600 bg-yellow-200 hover:bg-yellow-300">
                                Quality Control Check
                            </button>
                            <button
                                type="button"
                                onClick={() => handleChangeBadge('Prepping')}
                                className="px-4 py-1 text-small rounded-full text-indigo-600 bg-indigo-200 hover:bg-indigo-300">
                                Prepping
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Awaiting Carrier Pickup')
                                }
                                className="px-4 py-1 text-small rounded-full text-gray-600 bg-gray-200 hover:bg-gray-300">
                                Awaiting Carrier Pickup
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Picked Up By Carrier')
                                }
                                className="px-4 py-1 text-small rounded-full text-green-600 bg-green-200 hover:bg-green-300">
                                Picked Up By Carrier
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Attention Required')
                                }
                                className="px-4 py-1 text-small rounded-full text-red-600 bg-red-200 hover:bg-red-300">
                                Attention Required
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    handleChangeBadge('Batch Asgmt Needed')
                                }
                                className="px-4 py-1 text-small rounded-full text-red-600 bg-red-200 hover:bg-red-300">
                                Batch Asgmt Needed
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

StatusBadge.propTypes = {
    orderID: PropTypes.any,
    status: PropTypes.string.isRequired,
    storeID: PropTypes.any,
    units: PropTypes.any
};

export default StatusBadge;
