import { API, Auth } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

function AddonsForm({
    addon,
    addons,
    setAddons,
    setAddonForm,
    setCurrentAddon
}) {
    const [customQuantity, setCustomQuantity] = useState(false);
    const [pLoading, setPLoading] = useState(false);
    const [dLoading, setDLoading] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    const validatePrice = value => {
        let error = '';
        // if the value has more than two decimal places, return an error
        if (value < 0.01) {
            error = 'Price must be above 0.01';
        } else if (
            value.toString().split('.')[1] &&
            value.toString().split('.')[1].length > 2
        ) {
            error = 'Price can only have decimal places';
        }
        return error;
    };

    const validateDescription = value => {
        let error = '';
        if (value.length > 480) {
            error = 'Description must be less than 480 characters';
        }
        return error;
    };

    const handleCreateAddon = async (
        name,
        description,
        price1,
        price2,
        price3
    ) => {
        setPLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const addonReq = {
            Name: name,
            Price: { tier1: price1, tier2: price2, tier3: price3 },
            Description: description
        };
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                addon: addonReq
            }
        };
        API.post('myamzprepfixapi', '/addons', requestInfo)
            .then(res => {
                setPLoading(false);
                addonReq.AddonID = res.AddonID;
                setAddons([...addons, addonReq]);
                setAddonForm(false);
                toast.success('Add-on created successfully');
            })
            .catch(err => {
                toast.error('There was an error creating your add-on');
                console.log(err);
                setPLoading(false);
                setAddonForm(false);
            });
    };

    const handleUpdateAddon = async (
        name,
        description,
        price1,
        price2,
        price3
    ) => {
        setPLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const addonReq = {
            AddonID: addon.AddonID,
            Name: name || addon.Name,
            Price: {
                tier1: price1 || addon?.Price?.tier1,
                tier2: price2 || addon?.Price?.tier2,
                tier3: price3 || addon?.Price?.tier3
            },
            Description: description || addon.Description
        };
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                addon: addonReq
            }
        };
        API.patch('myamzprepfixapi', '/addons', requestInfo)
            .then(() => {
                const updatedAddons = addons.filter(
                    addonItem => addonItem.AddonID !== addon.AddonID
                );
                setPLoading(false);
                setAddonForm(false);
                setCurrentAddon(false);
                setAddons([...updatedAddons, addonReq]);
                toast.success('Add-on updated successfully');
            })
            .catch(err => {
                console.log(err);
                toast.error('There was an error updating your add-on');
                setPLoading(false);
                setAddonForm(false);
                setCurrentAddon(false);
            });
    };

    const handleDeleteAddon = async () => {
        setDLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                addonID: addon.AddonID
            }
        };
        API.del('myamzprepfixapi', '/addons', requestInfo)
            .then(() => {
                toast.success('Add-on deleted successfully');
                setDLoading(false);
                setAddonForm(false);
                setCurrentAddon(false);
                setAddons(
                    addons.filter(
                        addonItem => addonItem.AddonID !== addon.AddonID
                    )
                );
            })
            .catch(err => {
                toast.error('There was an error deleting your add-on.');
                console.log(err);
                setDLoading(false);
                setAddonForm(false);
                setCurrentAddon(false);
            });
    };

    console.log(addon);

    return (
        <>
            <Formik
                initialValues={{
                    name: addon?.Name || '',
                    description: addon?.Description || '',
                    price1: addon?.Price?.tier1 || '',
                    price2: addon?.Price?.tier2 || '',
                    price3: addon?.Price?.tier3 || '',
                    customQuantity: false,
                    customQuantityType: ''
                }}
                onSubmit={values => {
                    addon
                        ? handleUpdateAddon(
                              values.name,
                              values.description,
                              values.price1,
                              values.price2,
                              values.price3
                          )
                        : handleCreateAddon(
                              values.name,
                              values.description,
                              values.price1,
                              values.price2,
                              values.price3
                          );
                }}>
                <Form className="flex w-full">
                    <div className="grid w-full px-8 grid-cols-2 gap-4 m-auto">
                        <div className="col-span-2">
                            <Field
                                type="text"
                                id="name"
                                name="name"
                                required
                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="Addon Name"
                            />
                        </div>
                        <div className="col-span-2">
                            <Field
                                type="text"
                                id="description"
                                name="description"
                                className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="Description"
                                validate={validateDescription}
                            />
                        </div>
                        <div className="col-span-2">
                            <Field
                                type="number"
                                id="price1"
                                name="price1"
                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="($) Tier 1 Price"
                                validate={validatePrice}
                            />
                        </div>
                        <div className="col-span-2">
                            <Field
                                type="number"
                                id="price2"
                                name="price2"
                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="($) Tier 2 Price"
                                validate={validatePrice}
                            />
                        </div>
                        <div className="col-span-2">
                            <Field
                                type="number"
                                id="price3"
                                name="price3"
                                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                placeholder="($) Tier 3 Price"
                                validate={validatePrice}
                            />
                        </div>
                        <div className="col-span-2 ">
                            <label className="flex items-center">
                                <Field
                                    type="checkbox"
                                    id="customQuantity"
                                    name="customQuantity"
                                    className="border-gray-300 text-base mr-2"
                                    onClick={() => {
                                        setCustomQuantity(!customQuantity);
                                    }}
                                />
                                Custom quantity
                            </label>
                        </div>
                        {customQuantity && (
                            <div
                                role="group"
                                className="col-span-2 flex flex-col -mt-3 ml-4">
                                <label title="This will allow you to set add-on quantity to a multiple of units. Ex: if the quantity is 2, and the total units is 10, the total number of add-ons will be 20 for that product.">
                                    <Field
                                        type="radio"
                                        name="picked"
                                        value="One"
                                        className="mr-2"
                                    />
                                    Custom quantity per unit
                                </label>
                                <label title="This will allow you to set add-on quantity to any number for a product.">
                                    <Field
                                        type="radio"
                                        name="picked"
                                        value="Two"
                                        className="mr-2"
                                    />
                                    Custom total quantity
                                </label>
                            </div>
                        )}

                        <button
                            onClick={() => {
                                setAddonForm(false);
                                setCurrentAddon(false);
                            }}
                            type="button"
                            className="mt-2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                            Cancel
                        </button>
                        <button
                            type="submit"
                            name="submit"
                            className="align-center mt-2 py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md
                                    focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                            {pLoading ? (
                                <div className="flex justify-center">
                                    <div className="loading-spinner"></div>
                                </div>
                            ) : addon ? (
                                'Update Add-on'
                            ) : (
                                'Create Addon'
                            )}
                        </button>
                        {addon && (
                            <button
                                type="button"
                                onClick={() => {
                                    setVerifyModal(true);
                                }}
                                className="col-span-2 w-max flex place-self-center items-center px-6 py-1 transition ease-in duration-200 rounded-full text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white border-2 border-red-500 focus:outline-none">
                                Delete Addon
                            </button>
                        )}
                    </div>
                </Form>
            </Formik>
            <div>
                {verifyModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            Delete {addon.Name}
                                        </h3>
                                    </div>
                                    {/* body */}
                                    <div className="flex items-center justify-end p-4 rounded-b">
                                        <p>
                                            Are you sure you would like to
                                            permanently delete this addon? This
                                            action cannot be undone.
                                        </p>
                                    </div>
                                    {/* footer */}
                                    <div className="flex items-center justify-end p-4 gap-4 rounded-b">
                                        <button
                                            type="button"
                                            className="w-1/2 px-4 py-2 text-base border rounded-lg text-grey-500 bg-white hover:bg-gray-200 "
                                            onClick={() => {
                                                setVerifyModal(false);
                                            }}>
                                            {' '}
                                            Cancel
                                        </button>
                                        <button
                                            className="w-1/2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                            type="button"
                                            onClick={() => {
                                                handleDeleteAddon();
                                            }}>
                                            {dLoading ? (
                                                <div className="flex justify-center">
                                                    <div className="loading-spinner"></div>
                                                </div>
                                            ) : (
                                                'Confirm Delete'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </div>
        </>
    );
}

AddonsForm.propTypes = {
    addon: PropTypes.any,
    addons: PropTypes.any,
    setAddonForm: PropTypes.func,
    setAddons: PropTypes.any,
    setCurrentAddon: PropTypes.func
};

export default AddonsForm;
