import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import cartTotal from '../utils/cartTotal';
import CheckoutFormAddons from '../components/checkout-form-addons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function CheckoutAddons() {
    const [clientSecret, setClientSecret] = useState('');
    const [total, setTotal] = useState(0);

    useEffect(() => {
        // Get shopping cart items
        const cartItems = JSON.parse(localStorage.getItem('addonCart'));
        const OrderID = JSON.parse(localStorage.getItem('addonOrder'));
        // If no addons are selected, redirect to payments page
        if (!cartItems || cartItems.length === 0) {
            window.location.href = '/payments';
        }
        if (!OrderID) {
            window.location.href = '/payments';
        }
        setTotal(cartTotal(cartItems));

        const requestAddons = cartItems.map(item => ({
            AddonID: item.AddonID,
            Quantity: item.QuantitySelected
        }));

        // Create PaymentIntent as soon as the page loads
        const createPaymentIntent = async () => {
            const user = await Auth.currentAuthenticatedUser();
            const token = user.signInUserSession.idToken.jwtToken;
            const requestInfo = {
                headers: {
                    Authorization: token,
                    Accept: 'application/json'
                },
                body: {
                    addons: requestAddons,
                    OrderID: OrderID,
                    UserID: user.username
                }
            };
            API.post('MyAMZPrepStoreAPI', '/store/checkout', requestInfo)
                .then(response => {
                    setClientSecret(response.client_secret);
                })
                .catch(error => {
                    // this is to prevent the user from trying to pay for a product that doesn't exist
                    window.location.href = '/payments';
                    console.error(error);
                });
        };
        createPaymentIntent();
    }, []);

    const appearance = {
        theme: 'stripe'
    };
    const options = {
        clientSecret,
        appearance
    };
    const stripePromise = loadStripe(
        'pk_live_51LUcZfBcStMYyygYEmyxpfTW4WazBZbHYD1ljTV2Z0XsmA6EDIE4Ti9Z7Uo4iDeOiBaEBuUrraMzQgPOfgPy5Ko70091aUIwoj'
    );

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutFormAddons cartTotal={total} />
                </Elements>
            )}
        </div>
    );
}

export default CheckoutAddons;
