import formatDate from '../utils/formatDate';
import getColorFromID from '../utils/getColorFromID';
import getNumericID from '../utils/getReadableID';
import getStoreProductFromID from '../utils/getStoreProductFromID';
import PaymentsBadge from './payments-badge';
import PropTypes from 'prop-types';
import React from 'react';
import truncateString from '../utils/truncateString';

function ReceiptCard({ receipt, products, users }) {
    const getName = id => {
        const user = users.find(userIterator => userIterator.id === id);

        if (user) {
            return user.name;
        } else {
            return null;
        }
    };

    const username = getName(receipt.UserID);
    const [missingProducts, setMissingProducts] = React.useState(false);

    const calculateCost = () => {
        let cost = 0;
        receipt.order.forEach(item => {
            const product = getStoreProductFromID(item.ProductID, products);
            if (!product) {
                setMissingProducts(true);
            }
            cost += product?.Price * item.Quantity;
        });
        return cost;
    };

    const ProductList = () =>
        receipt.order.map((item, index) => (
            <div
                key={item.ProductID}
                className={
                    index % 2 === 0
                        ? 'flex justify-between items-center bg-gray-200 py-2'
                        : 'flex justify-between items-center py-2'
                }>
                <div>
                    <span className="font-bold">
                        {item.Quantity > 1 ? `(${item.Quantity})` : ''}
                    </span>
                    <span className="ml-1">
                        {
                            getStoreProductFromID(item.ProductID, products)
                                ?.ProductName
                        }
                    </span>
                </div>
                <span className="ml-2 min-w-max">
                    {`${item.Quantity} @ ${getStoreProductFromID(
                        item.ProductID,
                        products
                    )?.Price?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    })} ea`}
                </span>
            </div>
        ));

    return (
        <>
            {!username || missingProducts ? (
                <></>
            ) : (
                <div className="bg-white w-full lg:w-3/5 lg:min-w-[300px] lg:max-w-[450px] rounded-lg pb-2 mb-12 lg:mb-8">
                    <hr
                        className="border-2 rounded-lg mx-[10%]"
                        style={{ borderColor: getColorFromID(receipt.OrderID) }}
                    />
                    {/* Header with order number, date, and status badge */}
                    <div className="flex justify-between items-center px-4 py-4 border-b border-gray-200">
                        <div>
                            <span className="text-sm text-gray-500">
                                {formatDate(receipt.CreatedAt)}
                            </span>
                        </div>
                        <div>
                            <span className="text-sm font-medium text-gray-900">
                                {`Order #${getNumericID(receipt.OrderID)}`}
                            </span>
                        </div>

                        <div>
                            <PaymentsBadge status={'Order paid'} />
                        </div>
                    </div>
                    <div className="flex flex-col ml-2 mr-2">
                        <div>
                            <span className="mr-3">
                                <span className="font-bold">User: </span>
                                {truncateString(username, 51)}
                            </span>
                        </div>
                    </div>
                    <div className="bg-gray-100 m-2 rounded">
                        <p className="font-bold py-1 ml-1">PRODUCTS:</p>
                        <div className="flex flex-col">
                            <ProductList />
                            <div className="text-center text font-medium">
                                <span>
                                    {`Total: ${calculateCost().toLocaleString(
                                        'en-US',
                                        {
                                            style: 'currency',
                                            currency: 'USD'
                                        }
                                    )}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

ReceiptCard.propTypes = {
    products: PropTypes.any,
    receipt: PropTypes.shape({
        CreatedAt: PropTypes.any,
        OrderID: PropTypes.any,
        UserID: PropTypes.any,
        order: PropTypes.shape({
            forEach: PropTypes.func,
            map: PropTypes.func
        })
    }),
    users: PropTypes.shape({
        find: PropTypes.func
    })
};

export default ReceiptCard;
