import paymentsScreenshot from '../../images/homepage/payments.webp';
import React from 'react';
import storeScreenshot from '../../images/homepage/store.webp';
import warehouse1 from '../../images/homepage/warehouse-1.webp';
import warehouse2 from '../../images/homepage/warehouse-2.webp';
import warehouse3 from '../../images/homepage/warehouse-3.webp';
import warehouse4 from '../../images/homepage/warehouse-4.webp';

export default function Gallery() {
    return (
        <div id="gallery" className="container mx-auto px-5 py-2 md:pt-24">
            <div className="-m-1 flex flex-wrap lg:-m-2">
                <div className="flex w-full lg:w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={warehouse1}
                        />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={warehouse2}
                        />
                    </div>
                    <div className="w-full p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={paymentsScreenshot}
                        />
                    </div>
                </div>
                <div className="flex w-full lg:w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={storeScreenshot}
                        />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={warehouse3}
                        />
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                        <img
                            alt="gallery"
                            className="block h-full w-full rounded-lg object-cover object-center shadow-lg"
                            src={warehouse4}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
