/**
 * Accepts a Date object and returns a string of the date in MM-DD, HH:MM ampm format.
 */

export default function formatDateChat(date) {
    if (!date) {
        return '';
    }
    const dateObj = new Date(date);
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    let hours = dateObj.getHours();
    const minutes =
        (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes();

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours ? hours : 12;

    return `${month}-${day}, ${hours}:${minutes} ${ampm}`;
}
