import { API, Auth, Storage } from 'aws-amplify';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Compressor from 'compressorjs';
import config from '../aws-exports';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config;
function ProductForm({
    product,
    setNewProductForm,
    setCurrentProduct,
    products,
    setProducts
}) {
    const [filePreviewURL, setFilePreviewURL] = useState('');
    const [file, setFile] = useState();
    const [pLoading, setPLoading] = useState(false);
    const [dLoading, setDLoading] = useState(false);
    const hiddenFileInput = useRef(null);
    const [verifyModal, setVerifyModal] = useState(false);

    // If this is an update, set the preview url to the product's url
    useEffect(() => {
        if (product && product.ImageURL) {
            setFilePreviewURL(product.ImageURL);
        } else if (product && product.ImageS3 && !filePreviewURL) {
            setFilePreviewURL(
                `https://${bucket}.s3.${region}.amazonaws.com/public/${product.ImageS3}`
            );
        }
    }, [product]);

    // Reference button click to file upload input
    const handleClick = () => {
        hiddenFileInput.current.children[0].click();
    };

    // Set preview image and file state when file is selected
    function handleChange(e) {
        setFilePreviewURL(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = async values => {
        setPLoading(true);
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;
        const { productName, asin, quantity, price, description } = values;

        let key = '';
        if (file) {
            if (file.name) {
                const extension = file.name.split('.')[1];
                const filename = file.name.split('.')[0];
                key = `images/${filename}_${Math.random()
                    .toString(36)
                    .substring(7)}.${extension}`;

                // Compress image, converting png to jpg and leaving webp as webp
                new Compressor(file, {
                    quality: 0.8,
                    maxHeight: 600,
                    maxWidth: 600,
                    convertSize: 1,
                    success(result) {
                        const compressedFile = new File(
                            [result],
                            `${filename}.${extension}`,
                            {
                                type: result.type
                            }
                        );

                        // Upload image to S3
                        try {
                            Storage.put(key, compressedFile, {
                                contentType: compressedFile.type,
                                level: 'public'
                            });
                        } catch (error) {
                            console.log(error);
                            toast.error('Image upload failed');
                            return;
                        }
                    },
                    error(err) {
                        console.log(err.message);
                        toast.error('Image upload failed');
                        return;
                    }
                });
            } else {
                key = '';
            }
        } else {
            key = '';
        }

        // if product exists, update it. else, create a new product with form info
        const createProductReq = {
            ASIN: asin,
            Description: description,
            ImageS3: key ? key : '',
            ProductName: productName,
            Price: price,
            Quantity: quantity
        };

        const postRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                product: createProductReq
            }
        };

        const updateProductReq = {
            ASIN: asin,
            Description: description,
            ImageS3: key ? key : product ? product.ImageS3 : '',
            isArchived: false,
            ProductID: product ? product.ProductID : '',
            ProductName: productName,
            Price: price,
            Quantity: quantity
        };
        ('product missing required fields. needs strings: ASIN, Description, ImageS3, ProductID, ProductName & numbers: Price, Quantity & bool isArchived');

        const patchRequestInfo = {
            headers: {
                Authorization: token,
                Accept: 'application/json, text/plain, */*'
            },
            body: {
                product: updateProductReq
            }
        };

        // Create new product
        if (!product) {
            API.post('MyAMZPrepStoreAPI', '/store/products', postRequestInfo)
                .then(res => {
                    toast('Product created successfully!', {
                        type: 'success',
                        position: 'top-right'
                    });
                    setNewProductForm(false);
                    setCurrentProduct(null);

                    createProductReq.ProductID = res.ProductID;
                    createProductReq.CreatedAt = new Date().toISOString();
                    createProductReq.ImageURL = filePreviewURL;

                    if (products) {
                        setProducts([createProductReq, ...products]);
                    }
                    console.log(res);
                    setPLoading(false);
                    setNewProductForm(false);
                    setFilePreviewURL('');
                })
                .catch(err => {
                    toast('Failed to create product.', {
                        type: 'error',
                        position: 'top-right'
                    });
                    console.log(err);
                    setPLoading(false);
                    setNewProductForm(false);
                    setFilePreviewURL('');
                });
        }

        // Update existing product
        else {
            // If the image is being updated, delete the old image from S3
            if (key !== '') {
                try {
                    Storage.remove(product.ImageS3);
                    console.log('Old image deleted');
                } catch (error) {
                    console.log(error);
                    toast.error('Image upload failed');
                    return;
                }
            }
            API.patch('MyAMZPrepStoreAPI', '/store/products', patchRequestInfo)
                .then(res => {
                    toast('Product updated successfully!', {
                        type: 'success',
                        position: 'top-right'
                    });
                    setNewProductForm(false);
                    setCurrentProduct(null);

                    updateProductReq.isArchived = false;
                    updateProductReq.ImageURL = filePreviewURL;

                    // find the product where product id matches and update it
                    const updatedProducts = products.map(p => {
                        if (p.ProductID === product.ProductID) {
                            return updateProductReq;
                        } else {
                            return p;
                        }
                    });
                    setProducts(updatedProducts);
                    console.log(res);
                })
                .catch(err => {
                    toast('Failed to update product.', {
                        type: 'error',
                        position: 'top-right'
                    });
                    console.log(err);
                });
        }
        setFile(null);
    };

    const deleteProduct = async () => {
        setDLoading(true);
        const userReq = await Auth.currentAuthenticatedUser();
        const token = userReq.signInUserSession.idToken.jwtToken;
        const deleteRequestInfo = {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: {
                ProductID: product.ProductID
            }
        };

        // API call to delete product
        API.del('MyAMZPrepStoreAPI', '/store/products', deleteRequestInfo)
            .then(() => {
                Storage.remove(product.ImageS3);
                toast.success('Product deleted');
                console.log(
                    'Product deleted with ProductID ' + product.ProductID
                );
                setCurrentProduct(null);
                setNewProductForm(false);
                setDLoading(false);
                setProducts(
                    products.filter(p => p.ProductID !== product.ProductID)
                );
            })
            .catch(err => {
                toast.error('There was an error deleting this product');
                console.log(err);
            });
    };

    const validatePrice = value => {
        let error = '';
        // if the value has more than two decimal places, return an error
        if (value < 0.01) {
            error = 'Price must be above 0.01';
        } else if (
            value.toString().split('.')[1] &&
            value.toString().split('.')[1].length > 2
        ) {
            error = 'Price can only have decimal places';
        }
        return error;
    };

    const validateDescription = value => {
        let error = '';
        if (value.length > 480) {
            error = 'Description must be less than 480 characters';
        }
        return error;
    };

    // Validate image file size and type
    const validateImage = () => {
        let error = '';
        if (file) {
            if (file.size > 5000000) {
                error = 'Image file size is too big';
            }
            if (
                file.type !== 'image/jpg' &&
                file.type !== 'image/jpeg' &&
                file.type !== 'image/png' &&
                file.type !== 'image/webp'
            ) {
                error = 'Image file type is not supported';
            }
        }
        return error;
    };

    return (
        <div>
            <Formik
                initialValues={{
                    image: '',
                    productName: product?.ProductName || '',
                    asin: product?.ASIN || '',
                    quantity: product?.Quantity || '',
                    price: product?.Price || '',
                    description: product?.Description || ''
                }}
                onSubmit={values => {
                    handleSubmit(values);
                }}>
                {({ errors }) => (
                    <Form className="flex w-full max-w-sm space-x-3">
                        <div className="grid max-w-xl grid-cols-2 gap-4 m-auto">
                            {/* Upload image field of types .png and .jpg */}
                            <div ref={hiddenFileInput} className="hidden">
                                <Field
                                    type="file"
                                    id="image"
                                    name="image"
                                    placeholder="Image"
                                    onChange={handleChange}
                                    validate={validateImage}
                                />
                            </div>
                            <div className="col-span-2">
                                <button
                                    onClick={handleClick}
                                    type="button"
                                    className="border-transparent border border-gray-300 mt-2 py-2 px-4 bg-gray-200 hover:bg-gray-400 focus:ring-gray-300 focus:ring-offset-gray-200 text-gray-700 border border-gray-300 w-full transition ease-in duration-200 text-center text-base font-semibold
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                    Upload Image
                                </button>
                            </div>
                            <div className="col-span-2 place-self-center">
                                <img
                                    src={filePreviewURL}
                                    className="max-h-[26vh] min-h-0"
                                />
                            </div>
                            <div className="col-span-2">
                                <div className="relative">
                                    <Field
                                        type="text"
                                        id="productName"
                                        name="productName"
                                        required
                                        className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                        placeholder="Product Name"
                                    />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <div className=" relative ">
                                    <Field
                                        type="text"
                                        id="asin"
                                        name="asin"
                                        required
                                        className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                        placeholder="ASIN"
                                    />
                                </div>
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                <Field
                                    type="number"
                                    id="quantity"
                                    name="quantity"
                                    min={1}
                                    required
                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    placeholder="(#) Quantity"
                                />
                            </div>
                            <div className="col-span-2 lg:col-span-1">
                                <Field
                                    type="number"
                                    id="price"
                                    name="price"
                                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    placeholder="($) Price"
                                    validate={validatePrice}
                                />
                            </div>
                            <div className="col-span-2">
                                <Field
                                    as="textarea"
                                    htmlFor="name"
                                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-theme-color-400 focus:border-transparent"
                                    id="description"
                                    placeholder="Enter a description of the product."
                                    name="description"
                                    rows="5"
                                    cols="40"
                                    validate={validateDescription}
                                />
                            </div>
                            {errors && (
                                <div className="text-red-500 col-span-2">
                                    {errors.image}
                                </div>
                            )}
                            <button
                                onClick={() => {
                                    setNewProductForm(false);
                                    setCurrentProduct(null);
                                }}
                                type="button"
                                className="mt-2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                name="submit"
                                className="align-center mt-2 py-2 px-4 bg-theme-color-400 hover:bg-theme-color-700 focus:ring-theme-color-200 focus:ring-offset-theme-color-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md
                                    focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                                {pLoading ? (
                                    <div className="flex justify-center">
                                        <div className="loading-spinner"></div>
                                    </div>
                                ) : product ? (
                                    'Update Product'
                                ) : (
                                    'Create Product'
                                )}
                            </button>
                            {product && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setVerifyModal(true);
                                    }}
                                    className="col-span-2 w-max flex place-self-center items-center px-6 py-1 transition ease-in duration-200 rounded-full text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white border-2 border-red-500 focus:outline-none">
                                    Delete Product
                                </button>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
            <div>
                {verifyModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-96 my-6 mx-auto max-w-3xl">
                                {/* content */}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/* header */}
                                    <div className="flex flex-col justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            Delete {product.ProductName}
                                        </h3>
                                        <p className="text-gray-400 text-xs">
                                            {product.Quantity.toLocaleString({
                                                maximumFractionDigits: 2
                                            })}
                                        </p>
                                    </div>
                                    {/* body */}
                                    <div className="flex items-center justify-end p-4 rounded-b">
                                        <p>
                                            Are you sure you would like to
                                            permanently delete this product?
                                            This action cannot be undone.
                                        </p>
                                    </div>
                                    {/* footer */}
                                    <div className="flex items-center justify-end p-4 gap-4 rounded-b">
                                        <button
                                            type="button"
                                            className="w-1/2 px-4 py-2 text-base border rounded-lg text-grey-500 bg-white hover:bg-gray-200 "
                                            onClick={() => {
                                                setVerifyModal(false);
                                            }}>
                                            {' '}
                                            Cancel
                                        </button>
                                        <button
                                            className="w-1/2 py-2 px-4 bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                            type="button"
                                            onClick={deleteProduct}>
                                            {dLoading ? (
                                                <div className="flex justify-center">
                                                    <div className="loading-spinner"></div>
                                                </div>
                                            ) : (
                                                'Confirm Delete'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </div>
        </div>
    );
}

ProductForm.propTypes = {
    product: PropTypes.any,
    products: PropTypes.any,
    setProducts: PropTypes.any,
    setCurrentProduct: PropTypes.func,
    setNewProductForm: PropTypes.func
};

export default ProductForm;
