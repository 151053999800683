import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const orderContext = createContext({
    order: false,
    setOrderInfo: () => {},
    products: [],
    setProducts: () => {}
});

export const orderUpdateContext = createContext();

export const OrderProvider = ({ children }) => {
    const [order, setOrderInfo] = React.useState(false);
    const [products, setProducts] = React.useState([]);

    function updateOrderInfo(orderInfo) {
        setOrderInfo(orderInfo);
    }

    function updateProducts(productInfo) {
        setProducts(productInfo);
    }

    return (
        <orderContext.Provider
            value={{ order, setOrderInfo, products, setProducts }}>
            <orderUpdateContext.Provider
                value={{ updateOrderInfo, updateProducts }}>
                {children}
            </orderUpdateContext.Provider>
        </orderContext.Provider>
    );
};

OrderProvider.propTypes = {
    children: PropTypes.any
};
