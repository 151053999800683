import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { IconX } from '@tabler/icons-react';
import Logo from '../images/logo-nobg.png';
import SideNav from './side-nav';
import { useUser } from '../contexts/user-context';

export const Nav = () => {
    const { userGroup } = useUser();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(
        Auth.currentAuthenticatedUser() !== null
    );

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }

        setIsUserAuthenticated(false);
    }

    return (
        <div className="relative">
            {isMenuOpen && (
                <>
                    <div className="absolute left-0 top-0 bg-transparent-800 h-[105vh] w-full z-50 overflow-hidden"></div>
                    <div className="absolute top-0 left-0 w-full z-50 p-4">
                        <div className="p-5 bg-white border rounded shadow-sm">
                            <div className="flex items-center justify-between mb-4">
                                <div>
                                    <a
                                        href="/"
                                        aria-label="Company"
                                        title="Company"
                                        className="inline-flex items-center">
                                        <img src={Logo} alt="logo" />
                                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                                            My AMZ Prep
                                        </span>
                                    </a>
                                </div>
                                <div>
                                    <button
                                        aria-label="Close Menu"
                                        title="Close Menu"
                                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                                        onClick={() => setIsMenuOpen(false)}>
                                        <IconX />
                                    </button>
                                </div>
                            </div>
                            <nav>
                                <ul className="space-y-4">
                                    <li>
                                        <a
                                            href="/orders"
                                            aria-label="Orders"
                                            title="Orders"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                            Orders
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/leftovers"
                                            aria-label="Leftovers"
                                            title="Leftovers"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                            Leftovers
                                        </a>
                                    </li>
                                    {userGroup &&
                                        userGroup.includes('superadmin') && (
                                            <li>
                                                <a
                                                    href="/users"
                                                    aria-label="Our product"
                                                    title="Our product"
                                                    className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                                    Users
                                                </a>
                                            </li>
                                        )}
                                    {userGroup &&
                                        userGroup.includes('admin') && (
                                            <li>
                                                <a
                                                    href="/chats"
                                                    aria-label="Chat"
                                                    title="Chat"
                                                    className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                                    Chat
                                                </a>
                                            </li>
                                        )}
                                    {userGroup &&
                                        (userGroup.includes('superadmin') ||
                                            userGroup.includes('client')) && (
                                            <li>
                                                <a
                                                    href="/payments"
                                                    aria-label="Payments"
                                                    title="Payments"
                                                    className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                                    Payments
                                                </a>
                                            </li>
                                        )}
                                    {userGroup &&
                                        (userGroup.includes('superadmin') ||
                                            userGroup.includes('client')) && (
                                            <li>
                                                <a
                                                    href="/store"
                                                    aria-label="Store"
                                                    title="Store"
                                                    className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                                    Store
                                                </a>
                                            </li>
                                        )}
                                    <li>
                                        <a
                                            href="/account"
                                            aria-label="Account"
                                            title="Account"
                                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-theme-color-400">
                                            Account
                                        </a>
                                    </li>
                                    {isUserAuthenticated && (
                                        <li>
                                            <a
                                                onClick={signOut}
                                                className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-theme-color-400 hover:bg-theme-color-700 focus:shadow-outline focus:outline-none"
                                                aria-label="Sign out"
                                                title="Sign out">
                                                Sign out
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </>
            )}
            <div className="sm:px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:hidden">
                <div className="relative flex items-center justify-between">
                    <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        className="inline-flex items-center">
                        <img src={Logo} alt="logo" />
                        <span className="ml-2 text-lg sm:text-xl font-bold tracking-wide text-gray-800 uppercase">
                            My AMZ Prep
                        </span>
                    </a>

                    <div className="lg:hidden">
                        <button
                            aria-label="Open Menu"
                            title="Open Menu"
                            className="p-2 mr-2 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-theme-color-50 focus:bg-theme-color-50"
                            onClick={() => setIsMenuOpen(true)}>
                            <svg
                                className="w-5 text-gray-600"
                                viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                                />
                                <path
                                    fill="currentColor"
                                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative hidden lg:block">
                <SideNav signOut={signOut} />
            </div>
        </div>
    );
};

export default Nav;
